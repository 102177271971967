<template>
  <div>
    <div class="bottomDiv">
      <el-row style="display: flex;align-items: center">
        <a
            class="systemSetup-item"
            :href="aboutMediaBank"
            alt=""
            target="_blank"
        >{{ $t("app.About_MEDIA_BANK") }}</a>
        <a style="border-left: 1px solid #000000;height: 14px;margin: 0.23rem 0.12rem 0rem 0.12rem;"></a>
        <a
            class="systemSetup-item"
            :href="aboutJTA"
            alt=""
            target="_blank"
            style="margin-left: 0"
        >{{ $t("app.aboutJTA") }}</a>
      </el-row>
      <el-row style="display: flex;align-items: center">
        <router-link class="systemSetup-item" :to="{path: '/BaseTerms'}">{{ $t("app.Privacy")}}</router-link>
        <a style="border-left: 1px solid #000000;height: 14px;margin: 0.23rem 0.12rem 0 0.12rem;"></a>
        <router-link style="margin-left: 0;" systemSetup-item :to="{path: '/BaseTerms'}">{{ $t("app.termsOfUse")}}</router-link>
      </el-row>

      <el-row style="display: flex;align-items: center">
        <router-link :to="{path: '/SpecificCommercialTransactionsActInformation'}">{{ $t("app.SCTAI_title")}}</router-link>
      </el-row>

      <el-row style="text-align: left;margin-top: 0.2rem;margin-left: 0.4rem">
        <van-button round class="btn-black" type="primary" @click="goMerchantEnter">
              <span style="display: flex;">
                <svg style="margin-right: 6px" t="1690185112118" class="icon" viewBox="0 0 1139 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="99156" width="14" height="14">
                  <path d="M153.327169 98.413737h766.635848a43.298993 43.298993 0 0 0 32.802267-14.058114 50.421771 50.421771 0 0 0 13.120907-35.051566 50.421771 50.421771 0 0 0-13.120907-35.239007A43.486434 43.486434 0 0 0 919.963017 0.006935H153.327169a43.486434 43.486434 0 0 0-32.802267 14.058115 50.421771 50.421771 0 0 0-13.120907 35.239007 46.67294 46.67294 0 0 0 45.923174 49.10968zM935.520664 140.588081l1.686973 1.686974z m0 0M269.728358 441.431733c35.61389-10.309284 57.73199 103.280282 122.399318 104.217489s98.969127-33.364592 136.832315-101.593308c23.617632-25.492048 34.676683 20.243685 44.611083 39.737604s41.237136 71.22778 101.968191 68.603599c73.102196-2.999064 81.724506-44.2362 124.273733-112.464917 20.243685-12.183699 28.865995 10.121842 41.424577 38.612955a259.044191 259.044191 0 0 1 205.061031 12.746024 172.446205 172.446205 0 0 0 27.179022-91.096582L978.444773 142.275055H937.207637l7.31022 7.872544-7.31022-7.872544H92.03379L0 399.819713c0 77.038468 59.606406 152.015079 133.270926 152.015079s107.96632-101.405866 136.457432-110.403059z m0 0" fill="#ffffff" p-id="99157"></path>
                  <path d="M812.746463 964.393594a182.193165 182.193165 0 0 0-27.366463-30.178086 258.106983 258.106983 0 0 1-102.343074-289.59716h-11.996258a144.142535 144.142535 0 0 1-133.458367-95.970062 145.642067 145.642067 0 0 1-134.9579 95.782621 144.142535 144.142535 0 0 1-133.458368-95.782621 142.643002 142.643002 0 0 1-176.382477 89.222167V955.958726c0 29.42832 30.740411 65.417093 58.294315 65.417093h695.033184a93.720764 93.720764 0 0 1-6.747895-16.307413c-5.060921-8.62231-14.620439-24.55484-26.616697-40.862253z m0 0" fill="#ffffff" p-id="99158"></path>
                  <path d="M929.147652 510.410215a210.121952 210.121952 0 0 0-115.089098 385.754663 228.491222 228.491222 0 0 1 37.488305 40.299929 568.322711 568.322711 0 0 1 32.239943 50.421771 46.67294 46.67294 0 0 0 90.909141-2.061857 141.14347 141.14347 0 0 1 29.240878-50.421771 315.464091 315.464091 0 0 1 45.735733-41.237136l6.935336-5.060921 5.998129-3.936273h-1.686973l1.874415-1.499532a210.121952 210.121952 0 0 0-133.833251-372.071432z m0 307.778988a97.844477 97.844477 0 1 1 97.844477-97.844478 97.844477 97.844477 0 0 1-97.844477 97.844478z m0 0" fill="#ffffff" p-id="99159">
                  </path>
                </svg>
                {{ $t("app.merchant_enter") }}
              </span>
        </van-button>
      </el-row>
<!--        <a style="display: none;"-->
<!--      class="systemSetup-item"-->
<!--        :href="aboutJED"-->
<!--        alt=""-->
<!--        target="_blank"-->
<!--        >{{ $t("app.aboutJED") }}</a>-->
        <a class="systemsetup-item">{{ $t("app.Contactus") }}:
          <span style="font-weight: normal;display: flex;align-items: flex-end">info@media-bank.info</span></a>
        <div class="contactus_icons">
          <a :href="contactus.youtube" class="a_img"> <img class="img youtubeimg"  :src="youtube"/></a>
          <a :href="contactus.facebook"> <img class="img"  :src="facebook"/></a>
          <a :href="contactus.redbook"> <img class="img"  :src="redbook"/></a>
          <a :href="contactus.tiktok"> <img class="tiktokimg"  :src="tiktok"/></a>
        </div>
      <van-dropdown-menu v-show="isLangShow" active-color="#000000" direction="up">
        <van-dropdown-item
          v-model="value1"
          :options="option1"
          @change="changeLang"
        />
      </van-dropdown-menu>

      <div class="footerDiv">
        <img :src="logo" class="logo" />
        <div class="iaCon">
          <img :src="logo_bak" class="logo_bak" />
        </div>

        <a class="copyright" style="margin-top: 1rem;font-weight: 500;">{{$t("app.copyright")}}</a>
      </div>

      <!--      <div class="welcome" @click="sorry">TO TOP TO TOP</div>-->
    </div>
  </div>
</template>
<script>
import logo_black from "../assets/img/logo_black.png";
import logo_black_bak from "../assets/logo_black_bak.png";
import youtube from "../assets/img/Youtube.png";
import facebook from "../assets/img/Facebook.png";
import redbook from "../assets/img/RedBook.png";
import tiktok from "../assets/img/TikTok.png";
import apis from "@/utils/apis";

let {  getDataCount } = apis;


export default {
  name: "Footer",
  props:{isLangShow:Boolean},
  data() {
    return {
      value1:
        localStorage.getItem("language") == null
          ? "en"
          : localStorage.getItem("language"),
      option1: [
        { text: this.$t("app.en"), value: "en" },
        { text: this.$t("app.jp"), value: "jp" },
        { text: this.$t("app.cn"), value: "cn" },
        { text: this.$t("app.hk"), value: "hk" },
      ],
      aboutMediaBank: "https://media-bank.co",
      aboutJTA: "https://www.mlit.go.jp/kankocho/",
      aboutJED: "https://www.mlit.go.jp/kankocho/about/jed_logo.html",

      contactus:{
        facebook:"https://m.facebook.com/100071255584901/",
        youtube:"https://youtube.com/@mediabank2023",
        redbook:"https://www.xiaohongshu.com/user/profile/63574d6b000000001901cbf4?xhsshare=CopyLink&appuid=55c6bffea75c9559941b64b3&apptime=1679229039",
        tiktok:"https://www.tiktok.com/@mediabankjapan?_t=8k1oMAq5sim&_r=1",

      },

      Privacy: "https://www.media-bank.co/privacy",
      termsOfUse: "https://www.media-bank.co/terms",
      Contactus: "https://www.163.com/",
      logo: logo_black,
      logo_bak: logo_black_bak,
      youtube: youtube,
      facebook: facebook,
      tiktok: tiktok,
      redbook: redbook,
    };
  },
  methods: {
    // switch language
    changeLang(value) {
      // localStorage.setItem("language", value);
      // this.$i18n.locale = value;
      // window.location.reload();
      // window.scrollTo(0, 0);
      let params = {
        type: -1,
        id: this.$route.query.id,
        language: 0,
      };
      switch (this.$route.path) {
        case '/detailsPage': params.type = 1 //店铺
          break;
        case '/essayDetail': params.type = 2 //文章
          break;
        case '/blockDetail': params.type = 3 //新鲜事
          break;
        default: params.type = -1
          break;
      };
      if (params.type != -1) {
        switch (value) {
          case 'cn': params.language = 0 
            break;
          case 'jp': params.language = 1 
            break;
          case 'en': params.language = 2 
            break;
          case 'hk': params.language = 3 
            break;
          default: params.language = 2
            break;
        }
        console.log(params,'params');
        getDataCount({ ...params }, (response) => {
          if (response && response.data > 0) {
            localStorage.setItem("language", value);
            this.$i18n.locale = value;
            this.$forceUpdate();
            this.show = false;
            window.location.reload();
            window.scrollTo(0, 0);
          } else {
            this.$dialog.confirm({
              title: "",
              message: this.$t('app.change_language_null_tips'),
              confirmButtonText: this.$t('app.confirm'),
              cancelButtonText: this.$t('app.cancel')
            }).then(() => {
              localStorage.setItem("language", value);
              const route = localStorage.getItem("beforeRouter");
              this.$router.push(route);
              window.location.reload();
            }).catch(() => {});
          }
        })
      } else {
        localStorage.setItem("language", value);
        this.$i18n.locale = value;
        this.$forceUpdate();
        this.show = false;
        window.location.reload();
        window.scrollTo(0, 0);
      }
    },

    goMerchantEnter() {
      const languageType = localStorage.getItem('language');
      // window.open("http://localhost:8081/register?languageType=" + languageType)
      window.open("http://cms.media-bank.info/register?languageType=" + languageType)
    }
  },
};
</script>

<style lang="less" scoped>
.contactus_icons{
  margin-top: 0.2rem;
  width: 90%;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  .img{
    height: 0.5rem;
    margin-top: auto;
    margin-bottom: auto;
    width: 0.5rem;
  }
  .tiktokimg{
    height: 0.7rem;
    width: 0.7rem;
  }
  .youtubeimg {
    width: 1.22rem;
  }
}
.copyright{
  font-size: 12px;
  width: 90%;
  text-align: center;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  margin-left: auto !important;
  margin-right: auto !important;

}
.bottomDiv {
  background-color: #faf1ed;
  padding: 0.2rem 0;

  a {
    color: black;
    text-align: left;
    margin-left: 0.4rem;
    margin-top: 0.2rem;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    width: fit-content;
  }

  .footerDiv {
    margin-top: .8rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .iaCon {
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-top: 0.4rem;
      justify-content: space-between;
      .logo_bak {
        margin-left: 10px;
        height: .8rem;
      }
    }
    .linkDiv {
        margin-top: 0.4rem;
        a {
          display: table-header-group;
          text-align: left;
          color: black;
          margin-top: 0.2rem;
          font-size: 0.3rem;
          display: -webkit-box;
          display: -webkit-flex;
          display: flex;
          width: -webkit-fit-content;
          width: fit-content
        }
      }
    .logo {
     height: 1rem;
      object-fit: cover;
      margin-left:16px;
    }

    a {
      color: black;
      text-align: left;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      margin-top: 0.1rem;
      font-size: 0.26rem;
      // text-decoration: underline;
      width: fit-content;
    }
  }
}
::v-deep .van-dropdown-menu__bar {
  margin: 0.6rem 0.4rem;
  height: 0.6rem;
  box-shadow: none;
  border: 0.01rem #000 solid;
  border-radius: 0.1rem;

  .van-dropdown-menu__item {
    justify-content: flex-start;
  }

  .van-dropdown-menu__title {
    color: black;
    text-align: left;
    width: 7.1rem;
    padding-left: 0.3rem;

    &::after {
      border-color: transparent transparent black black;
      right: 0.3rem;
    }
  }
}

.btn-black {
  background-color: #000000;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  border: none;
  height: 32px;
  display: inline-block;

  span.van-button__text {
    display: flex;
  }
}
</style>
