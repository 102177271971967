<template>
    <div class="app">
        <div class="navBar">
            <a href="javascript:;" class="icon" @click="onClickLeft">
                <van-icon name="arrow-left" size="22" />
            </a>
            <div class="number">
                Order number : 1010101010
            </div>
            <a href="javascript:;" class="icon">
            </a>
        </div>
        <div class="header">
            <div class="header_connect">
                <div class="top1">
                    <div class="title">Failure ></div>
                    <div class="number">Actual amount paid$ 200.00</div>
                </div>
                <div class="top2">You did not complete the payment within 15 minutes
                    after the order was created, the order has been cancelled, please book again.</div>
                <div class="top3">
                    <div class="top_left">
                        <van-icon name="info-o" />
                    </div>
                    <div class="describe"> Since the change of overseas orders is usually
                        notified by email, please pay attention to relevant
                        emails in the reserved mailbox in time to avoid affecting travel.</div>
                </div>
                <div class="top4">
                    <van-button type="default">Rebook</van-button>
                </div>
            </div>
        </div>
        <div class="header_center">
            <div class="center1">
                Contact information
            </div>
            <div class="center3">Personal information</div>
            <div class="center2">
                <div class="center2_name">Name</div>
                <div>ZHANG</div>
            </div>
            <div class="center2">
                <div class="center2_name">Phone</div>
                <div>86-15115151515</div>
            </div>
            <div class="center2">
                <div class="center2_name">Email</div>
                <div>123456@.COM</div>
            </div>
        </div>
        <div class="header_footer">
            <div class="center1">
                Ticket details
            </div>
            <div class="center3">Zoo ticket Children × 2</div>
            <div class="center2">
                <div class="center2_name">Service time
                </div>
                <div>2023-03-12</div>
            </div>
            <div class="center2">
                <div class="center2_name">Usage method</div>
                <div class="center2_name1">Electronic ticket collection</div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { Icon, Button } from 'vant';

Vue.use(Icon);
Vue.use(Button);
export default {
    data() {
        return {

        }
    },
    methods: {
        onClickLeft() {
            this.$router.back()
        },
    }
}
</script>

<style scoped lang="less">
/deep/.van-icon {
    color: #fff;
}

/deep/.van-icon-info-o {
    color: black;
}

/deep/.van-button--normal {
    padding: 0 22px;
}

.app {
    height: 140vh;
    width: 100%;
    overflow-y: scroll;
    background-color: #f8f8f8;
}

.navBar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    height: 40px;
    width: 100%;
    margin-top: 40px;

    .icon {
        width: 50px;
    }

    .number {
        flex: 1;
        color: #fff;
        font-size: 18px;
        text-align: center;
    }
}

.header {
    position: relative;
    height: 400px;
    width: 100%;
    background-image: linear-gradient(#72D39D, #88D9AC, #f8f8f8);
}

.header_connect {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    top: 70%;
    left: 50%;
    height: 300px;
    width: 80%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 15px;
    padding: 12px;

    .top1 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 18px;
        }

        .number {
            color: #ccc;
            font-size: 13px;
        }
    }

    .top2 {
        text-align: left;
        font-size: 13px;
        color: #ccc;
    }

    .top3 {
        display: flex;

        .top_left {
            width: 25px;
        }

        .describe {
            flex: 1;
            text-align: left;
            font-size: 14px;
        }
    }

    .top4 {
        display: flex;
    }
}

.header_center {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    top: 85%;
    left: 50%;
    height: 200px;
    width: 80%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 15px;
    padding: 12px;

    .center1 {
        display: flex;
        font-size: 20px;
        font-weight: bold;
    }

    .center3 {
        display: flex;
        font-weight: bold;
    }

    .center2 {
        display: flex;

        .center2_name {
            width: 30%;
            text-align: left;
            color: #595757;
        }
    }
}

.header_footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    top: 120%;
    left: 50%;
    height: 200px;
    width: 80%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 15px;
    padding: 12px;

    .center1 {
        display: flex;
        font-size: 20px;
        font-weight: bold;
    }

    .center3 {
        display: flex;
        font-weight: bold;
    }

    .center2 {
        display: flex;

        .center2_name {
            width: 40%;
            font-size: 12px;
            text-align: left;
            color: #595757;
        }

        .center2_name1 {
            flex: 1;
            font-size: 13px;
        }
    }
}</style>