<template>
  <div>
    <Header @goBack="goBack"></Header>
    <LoginLogo></LoginLogo>
    <div>
      <div style="height: 500px;width: 100%">
        <GmapMap
            :center="{lat: addressLat, lng: addressLng}"
            :zoom="13"
            map-type-id="terrain"
            style="width: 100%; height: 100%"
        >
          <gmapMarker
              :position="{lat: addressLat, lng: addressLng}"
              :clickable="true"
              @click="openInfoWindow()">
            <GmapInfoWindow
                :options="infoWindowOptions"
                :opened="infoWindowOptions.openFlg">
              <div @click="goMerchantDetail()">
                <img style="width: 200px;height: 100px" :src="infoWindowOptions.banner">
                <div class="map-merchant-name">{{infoWindowOptions.merchantName}}</div>
              </div>
            </GmapInfoWindow>
          </gmapMarker>
        </GmapMap>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { NavBar, Icon, Form, Field, Button, Toast,Uploader } from "vant";
import Header from "../../components/Login/Header.vue";
import Footer from "../../components/Login/Footer.vue";
import LoginLogo from "../../components/Login/Loginlogo.vue";
import * as VueGoogleMaps from "vue2-google-maps";

let maplang = window.localStorage.getItem('language') || 'ja';
if (maplang == 'jp') {
  maplang = 'ja';
}
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAMvjJZ_HsByGEybbVbPNhsMHTJmnzaHe4',
    libraries: 'places',
    language: maplang,
  }
});

Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Uploader);

export default {
  props: ['preview'],
  components: {
    Header,
    Footer,
    LoginLogo,
  },
  data() {
    return {
      merchantId: this.$route.query.merchantId,
      addressLat: parseFloat(this.$route.query.addressLat),
      addressLng: parseFloat(this.$route.query.addressLng),
      infoWindowOptions: {
        maxWidth: 240,
        openFlg: false,
        banner: this.$route.query.banner,
        merchantName: this.$route.query.merchantName,
      }
    };
  },
  created() {
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goMerchantDetail() {
      const item = {
        type: 5,
        id: this.merchantId,
      }
      this.goShop(item);
    },
    goShop(item) {
      this.$router.push({
        path: "/detailsPage",
        query: {
          type: item.type,
          id: item.id,
        },
      });
    },
    openInfoWindow() {
      this.infoWindowOptions.openFlg = !this.infoWindowOptions.openFlg;
    },
  }
};
</script>

<style scoped lang="less">
// .login_input {
//     padding: .4rem .64rem;
// }
.login {
  padding: 0 .5rem;
  .login_title {
    text-align: left;
    font-size: 26px;
    margin: 0 0 .7rem 0;
  }

  .login_describe {
    text-align: left;
    display: block;
    font-weight: 500;
    font-size: .28rem;
    margin: 0 0 .6rem 0;
  }
}

/deep/ .van-cell-group--inset {
  margin: 0;
}

/deep/ .van-cell {
  padding: 0;
}

/deep/ .van-field__body {
  height: .8rem;
  border: .04rem solid #E0E0E0;
  border-radius: .1rem;
  padding-left: .24rem;
}

.label {
  text-align: left;
}
.van-button {
  background-color: #000000;
  border: none;
  height: 50px;
  font-size: 18px;
}
.connect p {
  margin: 0;
  display: none;
}
.required {
  text-align: left;
  color: red;
  font-weight: 200;
}
.avatar-uploader {
  width: 80px;
  height: 80px;
}
.avatar-uploader .avatar {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 1px solid #e0e0e0;
}
/deep/ .avatar-uploader .el-upload__input {
  display: none;
}
</style>
