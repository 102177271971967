import Vue from "vue";
import $ajax from "./request";
import {
    Notify,
    Toast
} from "vant";
import { encrypt } from "./jsencrypt";


Vue.use(Toast, Notify);


const BASE_URL = '/client-api/inbound/';
//  const BASE_URL = 'http://192.168.110.241:8080/inbound/'
 export const hostUrl = 'https://gotojapan.media-bank.info';
 export const pcHostUrl = 'https://gotoja.media-bank.info';
 export const hostUrlHK = 'https://hkwechat.media-bank.info';
export const hostUrlGrab = 'https://grab.media-bank.info';
//export const hostUrl = 'https://www.media.bank.mobile.jj2ss.com';
//export const hosWebtUrl = 'https://www.media.bank.pc.jj2ss.com';

export default {

    // 登录
    async login(params, callback) {
        if(params){
            params.pwd = encrypt(params.pwd);
        }
        let {
            data
        } = await $ajax.post(BASE_URL + "login", params);
        if (data.code == 200) {
            localStorage.setItem("token", data.data.token);
            localStorage.setItem("email", data.data.mail);
            localStorage.setItem("name", data.data.userName);
            localStorage.setItem("avatar", data.data.avater);
            localStorage.setItem("userId", data.data.userId);
            callback && callback(data);
        }
            // else if (data.resp_code == 70000) {
            //     await this.$router.replace({
            //         path: "/homePage",
            //     });
            //
        // }
        else {
            Toast(data.msg);
        }
    },

    //微信登录
    async wechatLogin(params, callback) {
        params.code = encrypt(params.code);
        let { data } = await $ajax.post(BASE_URL + "WechatLogin", params);
        if (data.code == 200) {
            localStorage.setItem("token", data.data.token);
            localStorage.setItem("email", data.data.mail);
            localStorage.setItem("name", data.data.userName);
            localStorage.setItem("avatar", data.data.avatar);
            localStorage.setItem("userId", data.data.userId);
            callback && callback(data);
        } else {
            Toast(data.msg);
        }
    },
  


    //注册
    async regist(params, callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "regist", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },

    //邮箱验证
    async verifyMail(params, callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "verifyMail", params)
        if (data.code == 200) {
            if (data.code == 200) {
                callback && callback(data)
            } else {
                Toast(data.msg);
            }
        }
    },

    //邮箱验证码验证
    async verifyCode(params, callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "verify_code", params)

        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }

    },

    //修改密码
    async modifyPwd(params, callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "modifyPwd", params)

        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }

    },
//搜索全部
    SearchAll(params) {
        return $ajax.post(BASE_URL + "search", params)
    },
//订单列表
    async OrderList(params, callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "orderList", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },
//订单详情
    async OrderDetail(params, callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "orderDetail", params)
        if (data.code == 200) {
            if (data.code == 200) {
                callback && callback(data)
            } else {
                Toast(data.msg);
            }
        }
    },
//查询订单状态
    async OrderStatus(params, callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "queryOrderStatus", params)
        if (data.code == 200) {
            if (data.code == 200) {
                callback && callback(data)
            } else {
                Toast(data.msg);
            }
        }
    },
//票务详情
    async TicketDetail(params, callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "ticketDetail", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },
//票券日历价格
    async TicketPirceCalendar(params, callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "queryPriceCalendar", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            // Toast(data.msg);
        }
    },


    //首页-推荐
    indexRecommend(params) {
        return $ajax.post(BASE_URL + "indexRecommend", params)
    },

    //文章详情
    articleDetail(params) {
       return $ajax.post(BASE_URL + "articleDetail", params)
    },

    //店铺详情
    async storeDeatil(params, callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "storeDeatil", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },

    //下单
    async createOrderAndPay(params, callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "createOrderAndPay", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },

    async getOauthCode(params, callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "getOauthCode", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },

    async getWechatPayValue(params, callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "getWechatPayValue", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },

    async indexBanner(params,callback){
        let {
            data
        } = await $ajax.post(BASE_URL + "indexAdvList", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },

    async indexVideo(params,callback){
        let {
            data
        } = await $ajax.post(BASE_URL + "indexVideoList", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },
    // get linelogin page url with param
    async getLineUrl(params,callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "line/web/getLineUrl", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },

    // get line auth tokenID
    async getLineAuthTokenAndLogin(params,callback) {
        let {
            data
        } = await $ajax.post(BASE_URL + "line/web/auth", params)
        if (data.code == 200) {
            localStorage.setItem("token", data.data.token);
            localStorage.setItem("email", data.data.mail);
            localStorage.setItem("name", data.data.userName);
            localStorage.setItem("avatar", data.data.avatar);
            localStorage.setItem("userId", data.data.userId);
            localStorage.setItem("isFirstLogin", data.data.first);
            callback && callback(data)
        } else {
            // if(data.data == '10001' || data.data =='10002' || data.data == '10003'){
                Toast(this.$t('app.auth_failed'));
            // }else{
            //     Toast(data.msg);
            // }
            window.location.href = hostUrl+"/";
        }
    },

    async getMessagetList(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "system/mesage/getList", params)
        if (data.code == 200) {
          callback && callback(data)
        } else {
          Toast(data.msg);
        }
      },
      async deleteMsg(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "system/mesage/deleteMsg", params)
        if (data.code == 200) {
          callback && callback(data)
        } else {
          Toast(data.msg);
        }
      },

      async updateMsg(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "system/mesage/updateMsg", params)
        if (data.code == 200) {
          callback && callback(data)
        } else {
          Toast(data.msg);
        }
      },

      async getAreaList(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "areaList", params)
        if (data.code == 200) {
          callback && callback(data)
        } else {
          Toast(data.msg);
        }
      },

      async updateUser(params, callback) {
        params.userId = encrypt(params.userId);
        params.userName = encrypt(params.userName);
        params.email = encrypt(params.email);
        params.address = encrypt(params.address);
        let { data } = await $ajax.post(BASE_URL + "updateUser", params);
        if (data.code == 200) {
          callback && callback(data);
        } else {
          Toast(data.msg);
        }
      },
    async sendQfpayCallback(params, callback){
        let { data } = await $ajax.post(BASE_URL + "callback/qfpayOrderBackFromClient", params);
        if (data.code == 200) {
            callback && callback(data);
        } else {
            Toast(data.msg);
        }
    },

    async checkToken(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "checkToken");
        if (data.code == 200) {
            callback && callback(data);
        } else {
            Toast(data.msg);
        }
    },

    async storeShopDetail(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "shopInfoList", params);
        if (data.code == 200) {
            callback && callback(data);
        } else {
            Toast(data.msg);
        }
    },

    async getMerchantMarkList(callback) {
        let { data } = await $ajax.get(BASE_URL + "getMerchantMarkList")
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },
    //首页-推荐
    SearchFresh(params) {
        return $ajax.post(BASE_URL + "searchFreshList", params)
    },

    async updProFilePhoto(file, callback) {
        const fd = new FormData();
        fd.append('file', file, file.name);
        let { data } = await $ajax.postFile(BASE_URL + "updProfilephoto",fd)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },

    async updProfileUserInfo(params, callback) {
        params.userId = encrypt(params.userId);
        params.userName = encrypt(params.userName);
        params.profilePhotoPath =  encrypt(params.profilePhotoPath);
        let { data } = await $ajax.post(BASE_URL + "updProfileUserInfo", params);
        if (data.code == 200) {
            callback && callback(data);
        } else {
            Toast(data.msg);
        }
    },

    async getUserInfo(params, callback) {
        params.userId = encrypt(params.userId);
        let { data } = await $ajax.post(BASE_URL + "getUserInfo", params);
        if (data.code == 200) {
            callback && callback(data);
        } else {
            Toast(data.msg);
        }
    },

    // 收藏
    async updCollectFlg(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "updCollectFlg",params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },

    async deleteCommon(params, callback) {
        params.userName = encrypt(params.userName);
        let { data } = await $ajax.post(BASE_URL + "delCommon", params);
        if (data.code == 200) {
          callback && callback(data);
        } else {
          Toast(data.msg);
        }
      },

      async addCommon(params, callback) {
        params.userId = encrypt(params.userId);
        params.userName = encrypt(params.userName);
        let { data } = await $ajax.post(BASE_URL + "addCommon", params);
        if (data.code == 200) {
          callback && callback(data);
        } else {
          Toast(data.msg);
        }
      },

      async queryCommon(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "queryCommon", params);
        if (data.code == 200) {
          callback && callback(data);
        } else {
          Toast(data.msg);
        }
      },

    // 获取计划列表
    async getMerchantPlanList(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "getMerchantPlanList", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },

    // 追加计划
    async addMerchantPlan(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "addMerchantPlan", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },

    // 取消计划
    async cancelMerchantPlan(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "cancelMerchantPlan", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },

    async getColumnDetail(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "system/column/getColumnDetail", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },
    async getFreshBlogList(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "freshBloglist")
        if (data.code == 200) {
          callback && callback(data)
        } else {
          Toast(data.msg);
        }
      },
    async getDataCount(params, callback) {
    params.userId = encrypt(params.userId);
    let { data } = await $ajax.post(BASE_URL + "getDataCount", params);
    if (data.code == 200) {
        callback && callback(data);
    } else {
        Toast(data.msg);
    }
    },
    async addAdvertorialPlan(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "addAdvertorialPlan", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },
    async delAdvertorialPlan(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "delAdvertorialPlan", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },
    async getAdvertorialPlanList(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "getAdvertorialPlanList", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },
    async addTicketPlan(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "addTicketPlan", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },
    async delTicketPlan(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "delTicketPlan", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },
    async getTicketPlan(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "getTicketPlan", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },

    //点击插入
    async addClick(params, callback) {
        let { data } = await $ajax.post(BASE_URL + "addClick", params)
        if (data.code == 200) {
            callback && callback(data)
        } else {
            Toast(data.msg);
        }
    },
}
