import Vue from "vue";
import axios from "axios";
import vueAxios from "vue-axios";
import {Toast} from "vant";
import { hostUrl } from "./apis";

Vue.use(vueAxios, axios);
Vue.use(Toast);
let requestIndex = 0;

Toast.allowMultiple();
axios.interceptors.request.use(
    function (config) {
        if (requestIndex < 0) {
            requestIndex = 0;
        }
        requestIndex++;

        Toast.loading({
            duration: 0,
            forbidClick: true
        });
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        requestIndex--;
        if (requestIndex <= 0) {
            Toast.clear();
        }

        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

const acceptlanguages = {
    zh: 'zh-CN',
    jp: 'ja-JP',
    en: 'en-US',
    hk: 'zh-CN',
};

export default {
    postFile(url, params) {
        let that = this
        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url: url,
                headers: {
                    "Content-Type": "multipart/form-data",
                    language: localStorage.getItem('language') == null ? '2' : localStorage.getItem('language') == 'cn' ? '0'
                        : localStorage.getItem('language') == 'jp' ? '1'
                            : localStorage.getItem('language') == 'hk' ? '3' : '2',
                    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
                    "Accept-Language": acceptlanguages[localStorage.getItem('language') || 'jp']
                    // token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYmYiOjE2NzczMTM2ODksImV4cCI6MTY3NzQwMDA4OSwiaWF0IjoxNjc3MzEzNjg5LCJ1c2VySWQiOjE2MjkzOTMyOTA1NzM0ODQwMzJ9.CsRdeVL9wkwzYjzRF3lV6M4IZgWED4OKSQIZaUffRGo"
                },
                data: params
            })
                .then(res => {
                    if (res.status === 200) {
                        if (res.data.code == 200) {
                            resolve(res);

                        } else if (res.data.code == 1000) {
                            resolve(res);
                            Toast(res.data.msg);
                            setTimeout(() => {
                                window.location.href = hostUrl + '/login';
                                localStorage.removeItem("token");
                                localStorage.removeItem("email");
                                localStorage.removeItem("name");
                                localStorage.removeItem("avatar");
                                localStorage.removeItem("userId");
                                // location.replace("/login");
                            }, 500)
                        } else {
                            resolve(res);
                        }
                        requestIndex--;
                        if (requestIndex <= 0) {
                            Toast.clear();
                        }
                    }
                })
                .catch(error => {

                    reject(error);
                    Toast.clear();

                });
        });
    },
    // post
    post(url, params) {
        let that = this
        // console.log(localStorage.getItem('language'))
        // console.log(acceptlanguages[localStorage.getItem('language') || 'jp'])


        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url: url,
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    language: localStorage.getItem('language') == null ? '2' : localStorage.getItem('language') == 'cn' ? '0'
                        : localStorage.getItem('language') == 'jp' ? '1'
                            : localStorage.getItem('language') == 'hk' ? '3' : '2',
                    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
                    "Accept-Language": acceptlanguages[localStorage.getItem('language') || 'jp']
                    // token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYmYiOjE2NzczMTM2ODksImV4cCI6MTY3NzQwMDA4OSwiaWF0IjoxNjc3MzEzNjg5LCJ1c2VySWQiOjE2MjkzOTMyOTA1NzM0ODQwMzJ9.CsRdeVL9wkwzYjzRF3lV6M4IZgWED4OKSQIZaUffRGo"
                },
                data: params
            })
                .then(res => {
                    if (res.status === 200) {
                        if (res.data.code == 200) {
                            resolve(res);

                        } else if (res.data.code == 1000) {
                            resolve(res);
                            Toast(res.data.msg);
                            setTimeout(() => {
                                // window.localStorage.clear()
                                window.location.href = hostUrl + '/login';
                                localStorage.removeItem("token");
                                localStorage.removeItem("email");
                                localStorage.removeItem("name");
                                localStorage.removeItem("avatar");
                                localStorage.removeItem("userId");
                            }, 500)
                        } else {
                            resolve(res);
                        }
                        requestIndex--;
                        if (requestIndex <= 0) {
                            Toast.clear();
                        }
                    }
                })
                .catch(error => {

                    reject(error);
                    Toast.clear();

                });
        });
    },

    get(url, params) {
        return new Promise((resolve, reject) => {
            axios(url, {
                params,
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    language: localStorage.getItem('language') == null ? 'en' : localStorage.getItem('language'),

                    token: localStorage.getItem('token')

                },
            })
                .then(res => {
                    requestIndex--;
                    if (requestIndex <= 0) {
                        Toast.clear();
                    }
                    if (res.status === 200) {
                        resolve(res);

                    } else if (res.data.code == 1000) {
                        resolve(res);
                        Toast(res.data.msg);
                        setTimeout(() => {
                            // window.localStorage.clear()
                            window.location.href = hostUrl + '/login';
                            localStorage.removeItem("token");
                            localStorage.removeItem("email");
                            localStorage.removeItem("name");
                            localStorage.removeItem("avatar");
                            localStorage.removeItem("userId");
                        }, 500)
                    } else {
                        resolve(res);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};
