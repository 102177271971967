<template>
    <div class="container offset-bottom">
        <iframe :src="src" style="height: 100vh; width:100%; margin:0; border:0;"> </iframe>
    </div>
</template>
<script>
export default {
  data() {
    return {
      src: '',
    }
  },
  created() {
    this.src = this.$route.query.src;
  }
}
</script>