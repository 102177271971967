<template>
    <div>
        <Header @goBack="goBack"></Header>
        <Loginlogo></Loginlogo>
        <div class="login">
            <h1 class="login_title">
                {{
                $t('app.login_code')
            }}
            </h1>
            <div class="login_input">
                <van-form @submit="onSubmit">
                    <p class="label">{{
                $t('app.code')
            }}</p>
                    <van-cell-group inset style="margin-bottom: 2%">
                        <van-field :rules="[{ required: true, message:  $t('app.verification_code') }]" name="code" v-model="inputvalue" placeholder="" />
                    </van-cell-group>
                    <!-- <van-field v-model="username" name="用户名" placeholder="Verification code" /> -->

                    <div style="margin: 35px 0 45px 0;">
                        <van-button round block type="info" native-type="submit">OK
                        </van-button>
                    </div>
                </van-form>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import apis from '../../utils/apis'
import { NavBar, Icon, Form, Field, Button,Toast  } from 'vant';
import Header from '../../components/Login/Header.vue'
import Footer from '../../components/Login/Footer.vue'
import Loginlogo from '../../components/Login/Loginlogo.vue';
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
let {verifyCode} = apis
export default {
    components: {
        Header,
        Footer,
        Loginlogo
    },
    data() {
        return {
            value1: localStorage.getItem('language') == null ? 'en' : localStorage.getItem('language'),
            option1: [
                { text: this.$t('app.en'), value: 'en' },
                { text: this.$t('app.jp'), value: 'jp' },
                { text: this.$t('app.cn'), value: 'cn' },
                { text: this.$t('app.hk'), value: 'hk' },
            ],
            username: '',
            inputvalue: '',
            telRules: [
                {
                    required: true,
                    message: "code is required",
                    trigger: "onBlur",
                }
            ],
        };
    },
    methods: {
        onSubmit() {
            // console.log('submit', values);
            let params={
                "mail": localStorage.getItem('email'), 
                "code": this.inputvalue
            }
            verifyCode({...params},data=>{
                if(data.code==200){
                    if(data.data.code==-1){
                        Toast.fail(data.data.msg);
                        return
                        // this.$router.push('/repwd')
                    } 
                    Toast.success(data.msg);
                    this.$router.push('/repwd')
                }
            })
           
        },
        goBack(){
            this.$router.back()
        }
    },
};
</script>

<style scoped lang="less">
/deep/.van-nav-bar__content{
    height:.92rem;
}
/deep/.van-cell-group--inset{
    margin: 0;
}
/deep/.van-cell{
    padding: 0;
}
/deep/.van-button--normal{
    height: 50px;
    font-size: 18px;
}
/deep/.van-field__body{
    height: 40px;
    border: .04rem solid #E0E0E0;
    border-radius: .08rem;
    padding-left: 12px;
}
.login {
    padding: 0 .5rem;

    .login_title {
        font-size: 23px;
        text-align: left;
        margin: 0 0 .7rem 0;
    }
}
.label {
    text-align: left;
}

.van-button {
    background-color: #333;
    border: none;
}
</style>