<template>
  <div class="districtDiv">
    <div class="flow-row space-between">
      <div class="distTitle1" v-if="tab === 4">{{ collectFlag == '1' ? $t("app.introduction_collect_list") : $t("app.introduction_list") }}</div>
      <div class="distTitle1" v-else-if="tab === 1">{{ collectFlag == '1' ? $t("app.area_collect_list") : $t("app.area_list") }}</div>
      <div class="distTitle1" v-else-if="tab === 2">{{ collectFlag == '1' ? $t("app.scenicSpot_collect_list") : $t("app.scenicSpot_list") }}</div>
      <div class="distTitle1" v-else-if="tab === 5">{{ collectFlag == '1' ? $t("app.shop_collect_list") : $t("app.shop_list") }}</div>
      <div class="distTitle1" v-else-if="tab === 6">{{ collectFlag == '1' ? $t("app.ticket_collect_list") : $t("app.ticket_list") }}</div>
      <div class="fs-018" v-if="showFlg && dataList.length > 3 && collectFlag !='1'" @click="checkMore">{{ $t("app.More") }}</div>
    </div>
    <van-list @load="onLoad" v-if="showFlg && collectFlag !='1'">
      <van-cell>
        <div class="nav_list">
          <div class="list_nav" v-for="(item, index) in dataList" :key="index" v-show="index<3">
            <div class="head">
              <div class="portrait" @click="goDetails(item)">
                <img :src="item?.img ? item.img.split(',')[0] : NoPicture" alt="" srcset="" />
                <span class="icon" v-if="item.type == 1">
                  <img src="../../../assets/img/area2.png" alt="" srcset=""/>
                </span>
                <span class="icon" v-if="item.type == 2">
                  <img src="../../../assets/img/jingdian2.png" alt="" srcset=""/>
                </span>
                <span class="icon" v-if="item.type == 4">
                  <img src="../../../assets/img/gonglue2.png" alt="" srcset=""/>
                </span>
                <span class="icon" v-if="item.type == 5">
                  <img src="../../../assets/img/shop2.png" alt="" srcset=""/>
                </span>
                <span class="icon" v-if="item.type == 6">
                  <img src="../../../assets/img/piao2.png" alt="" srcset=""/>
                </span>
              </div>
              <div class="list_describe">
                <h3 @click="goDetails(item)">{{ item.name }}</h3>
                <div @click="goDetails(item)" class="text_tag" v-if="item.tag && item.tag.length">
                  <van-tag v-for="(item1, index1) in item.tag" :key="index1" plain type="primary">{{ item1 }}</van-tag>
                </div>
                <div class="text-title" @click="goDetails(item)">
                  <div v-html="$options.filters.filtersText(item.desc)"></div>
                </div>
                <div class="viewa-row">
                  <p class="list_view">
                    {{ item.reviews || 0 }} {{ $t("app.reviews") }}
                  </p>
                  <collect :favorite="item.favorite" class="collect_right" @getTabList="getTabList"></collect>
                </div>
                <div @click="goDetails(item)" v-if="item.extra && item.type == '6'">
                  <p class="list_form" v-if="Number(item.extra.price) == 0">
                    {{ $t('app.noInventory')  }}
                  </p>
                  <p class="list_form" v-else>
                    <span class="discount_price">
                      <span style="font-size: 14px">
                        {{ $t("app.discount_price") }}
                      </span>
                      {{ $t("app.money") }}{{ priceFormat(item.extra.price) }}
                    </span>
                    <span class="original_price">{{ $t("app.money") }}{{ priceFormat(item.extra.price * 1.3) }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-cell>
    </van-list>
    <van-list @load="onLoad" v-if="!showFlg || collectFlag =='1'">
      <van-cell>
        <div class="nav_list">
          <div class="list_nav" v-for="(item, index) in dataList" :key="index">
            <div class="head">
              <div class="portrait" @click="goDetails(item)">
                <img :src="item?.img ? item.img.split(',')[0] : NoPicture" alt="" srcset="" />
                <span class="icon" v-if="item.type == 1">
                  <img src="../../../assets/img/area2.png" alt="" srcset=""/>
                </span>
                <span class="icon" v-if="item.type == 2">
                  <img src="../../../assets/img/jingdian2.png" alt="" srcset=""/>
                </span>
                <span class="icon" v-if="item.type == 4">
                  <img src="../../../assets/img/gonglue2.png" alt="" srcset=""/>
                </span>
                <span class="icon" v-if="item.type == 5">
                  <img src="../../../assets/img/shop2.png" alt="" srcset=""/>
                </span>
                <span class="icon" v-if="item.type == 6">
                  <img src="../../../assets/img/piao2.png" alt="" srcset=""/>
                </span>
              </div>
              <div class="list_describe">
                <h3 @click="goDetails(item)">{{ item.name }}</h3>
                <div @click="goDetails(item)" class="text_tag" v-if="item.tag && item.tag.length">
                  <van-tag v-for="(item1, index1) in item.tag" :key="index1" plain type="primary">{{ item1 }}</van-tag>
                </div>
                <div class="text-title" @click="goDetails(item)">
                  <div v-html="$options.filters.filtersText(item.desc)"></div>
                </div>
                <div class="viewa-row">
                  <p class="list_view">
                    {{ item.reviews || 0 }} {{ $t("app.reviews") }}
                  </p>
                  <collect :favorite="item.favorite" class="collect_right" @getTabList="getTabList"></collect>
                </div>
                <div @click="goDetails(item)" v-if="item.extra && item.type == '6'">
                  <p class="list_form" v-if="Number(item.extra.price) == 0">
                    {{ $t('app.noInventory')  }}
                  </p>
                  <p class="list_form" v-else>
                    <span class="discount_price">
                      <span style="font-size: 14px">
                        {{ $t("app.discount_price") }}
                      </span>
                      {{ $t("app.money") }}{{ priceFormat(item.extra.price) }}
                    </span>
                    <span class="original_price">{{ $t("app.money") }}{{ priceFormat(item.extra.price * 1.3) }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showMoreFlg" class="showMore" @click="loadMore">{{ $t('app.readMore') }}<img src="../../../assets/img/down.png" /></div>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import Collect from "../../../components/collect"
import NoPicture from "../../../assets/img/no_picture.png";

  export default {
    props: {
      tab: {
        type: Number,
        default: 0
      },
      showFlg: {
        type: Boolean,
        default: false,
      },
      collectFlag: {
        type: String,
      },
      dataList: {
        type: Array,
      },
      showMoreFlg: {
        type: Boolean,
        default: false,
      }
    },
    components: {
      Collect,
    },
    data() {
      return {
        NoPicture: NoPicture,
        currentPage: 1,
        pageSize: 20,
        pageNum: 1,
        total: 0,
      }
    },
    filters: {
      getFristText(str) {
        let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
        return rText.replace(/<[^>]+>/g, "").trim();
      },
      filtersText(val) {
        if (val != null && val != "") {
          val = val.replaceAll("</p>", "/p/");
          let reg = new RegExp("<.+?>", "g");
          let arrEntities = {
            lt: "<",
            gt: ">",
            nbsp: " ",
            amp: "&",
            quot: '"',
            ldquo: "“",
            mdash: "—",
            rdquo: "”",
          };
          return val
            .replace(reg, "")
            .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          ).replaceAll("/p/", "<br>");
        }
      },
    },
    methods: {
      // 从全部那里跳到详情页面
      goDetails(item) {
        if (item.type <= 5) {
          this.$router.push({
            path: "/detailsPage",
            query: {
              type: item.type,
              id: item.id,
            },
          });
        } else {
          this.$router.push({
            path: "/orderXq",
            query: {
              type: item.type,
              id: item.id,
            },
          });
        }
      },
      onLoad() {
        // 异步更新数据
        // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      },
      checkMore() {
        this.$emit('showChange', '1')
      },
      getTabList() {
        this.$emit('getTabList',1);
      },
      loadMore() {
        this.$emit('loadMore')
      }
    }
  }
</script>

<style scoped lang="less">
@import url("../../../assets/css/searchResult.less");
.districtDiv {
  margin-top: 0.2rem;
  text-align: start;

  .space-between {
    line-height: 1.2rem;
  }

  .distTitle1 {
    color: black;
    font-size: 0.37rem;
    font-weight: 600;
    margin-left: 0.3rem;
  }

  .image-box {
    padding: 0.2rem 0.3rem;
  }
}
.homePage-swipe {
  img {
    width: 100%;
    height: 4rem;
    display: block;
    object-fit: cover;
  }
}
/deep/.gm-ui-hover-effect {
  display: none !important;
}
/deep/ .gm-style .gm-style-iw-c {
  padding: 0px !important;
}
/deep/ .gm-style .gm-style-iw-d {
  overflow: hidden !important;
}
.map-merchant-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: bold;
  height: 22px;
  max-width: 188px;
  padding: 0px 6px;
}
.viewa-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fs-018{
  margin-right: 0.3rem;
  font-size: 0.28rem;
  cursor: pointer;
  text-decoration: underline;
}
.fs-018:hover {
  text-decoration: underline;
  color: blue;
}
.nav_list {
  margin-bottom: 0;
}
.showMore {
  width: 100%;
  margin: 0 auto;
  background: #fff;
  height: 60px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 0.18rem;
  font-weight: bold;
  color: #448CBB;
  img {
    width: 0.2rem;
    margin-left: 0.1rem;
  }
}
.discount_price {
  color: #f1b231;
  font-size: 16px;
}

.original_price {
  color: #989898;
  font-size: 14px;
  text-decoration: line-through;
  margin-left: 12px;
}
</style>
