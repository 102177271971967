<template>
  <div>
    <van-nav-bar :title="$t('app.My_Order')" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <van-tabs :swipeable="false" v-model="active" title-active-color="#000000" @click="onClick">
      <!-- <van-tab :title="$t('app.All')">
        <div v-if="total>0">
          <van-notice-bar left-icon="warning">{{ $t('app.displayed_title') }}
        </van-notice-bar>
        
          <van-cell @click="OrderDetails(item.orderId)" v-for="(item,index) in list" :key="index">
            <div class="order_nav">
              <div class="order_img">
                <img :src="item.img" alt="" srcset="">
              </div>
              <div class="order_cbk">
                <div class="order_title">{{ item.proName }}</div>
                <div class="order_status"><span>
                  <van-icon name="checked" v-if="item.orderStatus==1"/>
                  <van-icon name="clear" v-if="item.orderStatus==2"/>
                  <van-icon name="replay" v-if="item.orderStatus==3"/>
                </span>
                <span v-if="item.orderStatus==1">{{ $t('app.Success') }}</span>
                <span v-if="item.orderStatus==2">{{ $t('app.Failure') }}</span>
                <span v-if="item.orderStatus==3">{{ $t('app.Processing') }}</span>
                </div>
                <div class="order_date">
                  <span><van-icon name="underway-o" size="16"/></span>
                  <span>{{ item.createTime }}</span>
                  <span>|</span>
                  <span>{{item.buyQuantity}}{{$t('app.tickets')}}</span>
                </div>
                <div class="order_price">{{ $t('app.money') }}{{ item.amount }}</div>
              </div>
            </div>
          </van-cell>
          <div class="footer">
            <van-pagination
              v-model="currentPage"
              :total-items="total"
              :page-count="pageNum"
              @change="pagechange"
              :items-per-page="pageSize"
            >
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
            </van-pagination>
          </div>
        </div>
        <div v-else>
          <van-empty :description="$t('app.No_data')" />
        </div> 
      </van-tab> -->
      <van-tab :title="$t('app.Success')">
        <!--Empty pages-->
        <div v-if="total>0">
          <van-notice-bar left-icon="warning">{{ $t('app.displayed_title') }}</van-notice-bar>
          <!-- <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad"> -->
            <van-cell @click="OrderDetails(item.orderId)" v-for="(item,index) in list" :key="index">
              <div class="order_nav">
                <div class="order_img">
                  <img :src="item?.img ? item.img : NoPicture" alt="" srcset="">
                </div>
                <div class="order_cbk">
                  <div class="order_title">{{ item.proName }}</div>
                  <div class="order_status"><span><van-icon name="certificate" size="16"/></span><span>{{ $t('app.Success') }}</span></div>
                  <div>{{ item.orderId }}</div>
                  <div class="order_date">
                    <span><van-icon name="underway-o" size="16"/></span>
                    <span>{{ item.createTime }}</span>
                    <span>|</span>
                    <span>{{item.buyQuantity}}{{getTicket(item.buyQuantity)}}</span>
                  </div>
                  <div v-if="Number(item.amount) == 0" class="order_price">{{ $t('app.noInventory') }}</div>
                  <div v-else class="order_price">{{ $t("app.money") }}{{ priceFormat(item.amount) }}</div>
                </div>
              </div>
            </van-cell>
            <div class="footer">
            <van-pagination
              v-model="currentPage"
              :total-items="total"
              :page-count="pageNum"
              @change="pagechange"
              :items-per-page="pageSize"
            >
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
            </van-pagination>
          </div>
          <!-- </van-list> -->
        </div>
        <div v-else>
          <van-empty :description="$t('app.No_data')" />
        </div> 
      </van-tab>
      <van-tab :title="$t('app.Failure')">
        <!--Empty pages-->
        <!-- <Empty></Empty> -->
        <div v-if="total>0">
          <van-notice-bar left-icon="warning"> {{ $t('app.displayed_title') }}</van-notice-bar>
          <!-- <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad"> -->
            <van-cell @click="OrderDetails(item.orderId)" v-for="(item,index) in list" :key="index">
              <div class="order_nav">
                <div class="order_img">
                  <img :src="item?.img ? item.img : NoPicture" alt="" srcset="">
                </div>
                <div class="order_cbk">
                  <div class="order_title">{{ item.proName }}</div>
                  <div class="order_status"><span><van-icon name="close" size="16"/></span><span>{{ $t('app.Failure') }}</span></div>
                  <div>{{ item.orderId }}</div>
                  <div class="order_date">
                    <span><van-icon name="underway-o" size="16"/></span>
                    <span>{{ item.createTime }}</span>
                    <span>|</span>
                    <span>{{item.buyQuantity}}{{ getTicket(item.buyQuantity) }}</span>
                  </div>
                  <div v-if="Number(item.amount) == 0" class="order_price">{{ $t('app.noInventory') }}</div>
                  <div v-else class="order_price">{{ $t("app.money") }}{{ priceFormat(item.amount) }}</div>
                </div>
              </div>
            </van-cell>
            <div class="footer">
            <van-pagination
              v-model="currentPage"
              :total-items="total"
              :page-count="pageNum"
              @change="pagechange"
              :items-per-page="pageSize"
            >
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
            </van-pagination>
          </div>
          <!-- </van-list> -->
        </div>
        <div v-else>
          <van-empty :description="$t('app.No_data')" />
        </div> 
      </van-tab>
      <van-tab :title="$t('app.Processing')">
        <!--Empty pages-->
        <!-- <Empty></Empty> -->
        <div v-if="total>0">
          <van-notice-bar left-icon="warning"> {{ $t('app.displayed_title') }}
        </van-notice-bar>
        <!-- <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad"> -->
          <van-cell @click="OrderDetails(item.orderId)" v-for="(item,index) in list" :key="index">
            <div class="order_nav">
              <div class="order_img">
                <img :src="item?.img ? item.img : NoPicture" alt="" srcset="">
              </div>
              <div class="order_cbk">
                <div class="order_title">{{ item.proName }}</div>
                <div class="order_status"><span><van-icon name="replay"/>
                </span><span>{{ $t('app.Processing') }}</span></div>
                <div>{{ item.orderId }}</div>
                <div class="order_date">
                  <span><van-icon name="clock"/></span>
                  <span>{{ item.createTime }}</span>
                  <span>|</span>
                  <span>{{item.buyQuantity}}{{getTicket(item.buyQuantity)}}</span>
                </div>
                <div v-if="Number(item.amount) == 0" class="order_price">{{ $t('app.noInventory') }}</div>
                <div v-else class="order_price">{{ $t("app.money") }}{{ priceFormat(item.amount) }}</div>
              </div>
            </div>
          </van-cell>
          <div class="footer">
            <van-pagination
              v-model="currentPage"
              :total-items="total"
              :page-count="pageNum"
              @change="pagechange"
              :items-per-page="pageSize"
            >
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
            </van-pagination>
          </div>
        <!-- </van-list> -->
        </div>
        <div v-else>
          <van-empty :description="$t('app.No_data')" />
        </div> 
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import apis from '../../utils/apis'
import Vue from 'vue';
import {NavBar, Tab, Tabs, NoticeBar, List, Button, Empty, Image as VanImage, Toast} from 'vant';
// import Empty from '../../components/order/empty.vue';
import NoPicture from "../../assets/img/no_picture.png";

Vue.use(NavBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(NoticeBar);
Vue.use(List);
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Empty);
let {OrderList} = apis
export default {
  components: {
    // Empty
  },
  data() {
    return {
      NoPicture: NoPicture,
      currentPage: 1,
      pageSize: 10,
      pageNum: 1,
      total: 0,
      list: [],
      loading: false,
      finished: false,
      active: 0,
      num: 1,
    }
  },
  mounted() {
    this.getOrderList()
  },
  methods: {
    getTicket(num){
      if (!isNaN(num)) {
        return Number(num) > 1 ?  this.$t('app.tickets_s') : this.$t('app.tickets')
      } else {
        return this.$t('app.tickets');
      }
    },
    getOrderList() {
      let params = {
        "mail": localStorage.getItem('email'), //注册用的邮箱，接收系统发送的验证码。
        "searchType": this.num, //搜索类型：0-全部订单；2-失败订单；1-成功订单；3-处理中订单
        "pageNum": this.currentPage,
        "pageSize": this.pageSize,
      }
      OrderList({...params}, data => {
        this.total = data.data.total;
        this.pageNum = Math.ceil(this.total / this.pageSize);
        this.list = data.data.list;
      })
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      for (let i = 0; i < 2; i++) {
        this.list.push(this.list.length + 1);
      }
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.list.length >= 40) {
        this.finished = true;
      }
    },
    onClickLeft() {
      this.$router.back()
    },
    onClick(name, title) {
      this.currentPage = 1;
      if (title == this.$t('app.Success')) {
        this.num = 1
        this.getOrderList()
      } else if (title == this.$t('app.Failure')) {
        this.num = 2
        this.getOrderList()
      } else if (title == this.$t('app.Processing')) {
        this.num = 3
        this.getOrderList()
      } else {
        this.num = 0
        this.getOrderList()
      }
    },
    OrderDetails(index) {
      if(index === '' || index === null ) {
        Toast.fail(this.$t('app.orderExcption'))
        return
      }
      this.$router.push({
        path: '/myOrderDetails',
        query: {
          orderId: index
        }
      })
    },
    pagechange(page) {
      this.currentPage = page;
      this.getOrderList();
    },
  }
}
</script>

<style scoped lang="less">
/deep/ .van-icon {
  color: black;
}
/deep/.van-icon-certificate{
  color: #4AA772;
}
/deep/ .van-tabs__line {
  background-color: #333;
}

/deep/ .van-cell::after {
  border-bottom: .04rem solid #ebebeb;
}

/deep/ .van-tab--active {
  font-weight: bold;
  font-size: .28rem;
}

/deep/ .van-notice-bar__left-icon {
  color: rgba(237, 106, 12);
}

/deep/ .van-nav-bar__title {
  font-weight: bold;
}

/deep/ .van-button__content {
  padding: 0 .28rem;
}

/deep/ .van-cell {
  height: 2.91rem;
  padding: .35rem .36rem;
  line-height: 20px;
}

.van-hairline--bottom:after {
  border-bottom-width: 0;
}

/deep/ .van-icon-checked {
  color: #4AA772;
}

/deep/ .van-icon-clock {
  color: #727171;
}

/deep/ .van-icon-clear {
  color: #FF0000;
}

/deep/ .van-icon-replay {
  color: #FF9000;
}

.order_nav {
  display: flex;
  height: 2.2rem;
  justify-content: space-between;

  .order_img {
    width: 2.2rem;
    height: 100%;

    img {
      border-radius: .12rem;
      object-fit: cover;
      width: 2.2rem;
      height: 100%;
    }
  }

  .order_cbk {
    flex: 1;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: .36rem;

    .order_title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: .36rem;
      width: 4rem;
    }

    .order_status {
      span {
        color: #595757;
        font-size: .28rem;
        padding-right: .1rem;
      }
    }

    .order_date {
      span {
        font-size: .32rem;
        padding-right: .1rem;
      }
    }

    .order_price {
      font-size: .3rem;
      // padding-left: .4rem;
    }
  }
}
/deep/ .footer {
    height: 60px;
    padding: 0  .4rem;
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    margin-top: 0.2rem;
    .van-pagination {
      height: 0.92rem;
      border: 0.02rem solid #dcdddd;
      .van-pagination__item {
        height: 100%;
        .van-icon {
          color: black;
          font-size: .44rem;
        }
      }
      .van-pagination__item--active {
        background-color: #dcdddd;
      }
      .van-pagination__page {
        color: #000000;
      }
   }
}
</style>