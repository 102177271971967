<template>
  <div class="districtDiv">
    <div class="flow-row space-between">
      <div class="distTitle1">{{ dataList[0].columnName }}</div>
    </div>
    <van-row gutter="10" class="image-box">
      <van-col span="16" style="height: 4.2rem;">
        <div class="imgDiv" @click="toDetail(tab, dataList[0].pageType, dataList[0].columnRefId)">
          <img :src="dataList[0].columnImageUrl" style="display: flex; width: 100%; height: 4.2rem; object-fit: cover; border-radius: 0.16rem;">
          <div class="title" v-if="dataList[0].columnRefAdvName !== null">
            <div class="desc">
              <img src="../../../assets/img/area.png" class="desc-icon"/>
              {{ dataList[0].columnRefAdvName }}
            </div>
          </div>
        </div>
      </van-col>
      <van-col span="8">
        <div class="imgDiv" style="height: 2rem;" @click="toDetail(tab, dataList[1].pageType, dataList[1].columnRefId)">
          <img :src="dataList[1].columnImageUrl" style="display: flex; width: 100%; height: 2rem; object-fit: cover; border-radius: 0.16rem;">
          <div class="title" v-if="dataList[1].columnRefAdvName !== null">
            <div class="desc">
              <img src="../../../assets/img/area.png" class="desc-icon"/>
              {{ dataList[1].columnRefAdvName }}
            </div>
          </div>
        </div>
        <div class="imgDiv" style="height: 2rem; margin-top: 0.2rem;" @click="toDetail(tab, dataList[2].pageType, dataList[2].columnRefId)">
          <img :src="dataList[2].columnImageUrl" style="display: flex; width: 100%; height: 2rem; object-fit: cover; border-radius: 0.16rem;">
          <div class="title" v-if="dataList[2].columnRefAdvName !== null">
            <div class="desc">
              <img src="../../../assets/img/area.png" class="desc-icon"/>
              {{ dataList[2].columnRefAdvName }}
            </div>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  props: {
    tab: {
      type: Number,
      default: 0
    },
    dataList: {
      type: Array,
    }
  },
  data(props) {
    return {}
  },
  methods: {
    toDetail(tab, type, id) {
      if (tab <= 5) {
        this.$router.push({
          path: "/detailsPage",
          query: {
            type: type,
            id: id,
          },
        });
      } else {
        this.$router.push({
          path: "/orderXq",
          query: {
            type: type,
            id: id,
          },
        });
      }
    },
  }
}
</script>

<style scoped lang="less">
.districtDiv {
  margin: 0 auto;
  text-align: start;

  .space-between {
    line-height: 1.2rem;
  }

  .distTitle1 {
    color: black;
    font-size: 0.37rem;
    font-weight: 600;
    margin-left: 0.3rem;
  }

  .image-box {
    padding: 0 0.3rem;
    overflow: hidden;
    white-space: nowrap;

    .imgDiv {
      position: relative;
      display: flex;
      flex-direction: column;

      .title {
        position: absolute;
        bottom: 0;
        height: 0.7rem;
        background:linear-gradient(to top,rgba(0, 0, 0,.6) 0,rgba(0, 0, 0,.7) 80%,rgba(0, 0, 0,.0) 100%);
        color: white;
        text-align: start;
        padding: 0.1rem 0.2rem;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        min-width: 0;
        border-bottom-right-radius: 0.16rem;
        border-bottom-left-radius: 0.16rem ;

        .desc {
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          white-space: normal;
          text-overflow: ellipsis;
          overflow: hidden;
          max-height: 0.64rem;

          &:first-child {
            font-size: 0.3rem;
          }

          &:last-child {
            margin-top: 0.13rem;
            font-size: 0.24rem;
            line-height: 0.32rem;
          }

          .desc-icon {
            width: 0.2rem;
            height: 0.2rem;
            position: relative;
          }
        }
      }
    }
  }

  .img {
    display: flex;
    width: 368px;
    height: 200px;
    object-fit: cover;
    border-radius: .16rem;
  }
}
</style>
