import { render, staticRenderFns } from "./collect.vue?vue&type=template&id=1ca7052c&scoped=true&"
import script from "./collect.vue?vue&type=script&lang=js&"
export * from "./collect.vue?vue&type=script&lang=js&"
import style0 from "./collect.vue?vue&type=style&index=0&id=1ca7052c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ca7052c",
  null
  
)

export default component.exports