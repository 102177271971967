<template>
  <div
    class="navBarBody"
    :class="isBlack ? 'blackColor' : ''"
    :style="{
      background: !isBlack ? 'linear-gradient(#00000090, #00000090)' : '',
    }"
  >
    <div class="navBarBodyAll">
      <div
        id="propuBotton"
        class="propuBotton"
        @click="showPopup"
      >
      <div class="menu-line" :class="isBlack ? 'blackColor' : ''"></div>
      <div class="menu-line" :class="isBlack ? 'blackColor' : ''"></div>
      <div class="menu-line" :class="isBlack ? 'blackColor' : ''"></div>
    </div>
      <img :src="logo" class="nav-logo" @click="toHomePage()" />
      <div
        class="selectInputIcon"
        :class="isBlack ? 'blackColor' : ''"
        @click="toSearch"
      ></div>
    </div>

    <van-popup
      v-model="show"
      closeable
      close-icon="cross"
      position="left"
      :style="{ height: '100%', width: '82vw',overflow: 'auto' }"
    >
      <div class="propupWin">
        <div class="propupWinBody">
          <button id="login" class="signinButton" @click="toRegister" v-if="unfold">
            {{ $t("app.signIn") }}
          </button>
          <div class="avatar_username" v-else>
            <van-image class="item"
              width="1rem"
              height="1rem"
              :src="avatar"
              fit="cover"
              :round=true
              :error-icon="user_ic_def">
            </van-image>
            <div class="item">{{ getDisplayName() }}</div>
          </div>
          <div class="body-top">
            <ul>
              <!-- <li @click="toDetail(0)" v-if="!unfold">{{ $t("app.orderList") }}</li>
              <li @click="toDetail(2)" v-if="!unfold">{{ $t("app.msgList") }}</li>
              <li @click="collectionList('a', '0')" v-if="!unfold">{{ $t("app.collectionList") }}</li> -->
              <li @click="toDetail(4)">
                {{ $t("app.personal") }}
              </li>
              <li @click="toDetail(1)" v-if="!unfold">
                {{ $t("app.changePwd") }}
              </li>
              <!-- <li @click="toDetail(3)" v-if="!unfold">
                {{ $t("app.updHead") }}
              </li> -->
              <li id="logout" @click="logout" v-if="!unfold">{{ $t("app.logOut") }}</li>
              <van-divider v-if="!unfold" />
              <!-- ljn -->
              <!-- <li class="li" @click="goSearchResult('b', '4')">
                {{ $t("app.introduction") }}
              </li>
              <li class="li" @click="goSearchResult('c', '1')">{{ $t("app.area") }}</li>
              <li class="li" @click="goSearchResult('d', '2')">
                {{ $t("app.scenicSpot") }}
              </li>
              <li class="li" @click="goSearchResult('e', '5')">{{ $t("app.shop") }}</li>
              <li class="li" @click="goSearchResult('f', '6')">{{ $t("app.ticket") }}</li>
              <li class="li" @click="goSearchResult('a', '0')">{{ $t("app.All") }}</li> -->
              <!-- <van-divider /> -->
              <van-collapse v-model="activeNames" :border="false">
                <van-collapse-item id="language" :title="languageTitle" name="1">
                  <div class="langList">
                    <div
                      v-for="(item, index) in option1"
                      :key="index"
                      @click="changeLang(item.value)"
                      :id="item.value"
                    >
                      {{ item.text }}
                    </div>
                  </div>
                </van-collapse-item>
              </van-collapse>
            </ul>
          </div>
        </div>
      </div>
    </van-popup>
    <van-dialog
      v-model="show1"
      :title="$t('app.changePwd')"
      show-cancel-button
      @confirm="goCode"
    >
    </van-dialog>
    <van-dialog
      v-model="show2"
      :title="$t('app.logOut')"
      show-cancel-button
      @confirm="goLogout"
    >
    </van-dialog>
    <van-dialog
        v-model="show3"
        :title="$t('app.updHead')"
        show-cancel-button
        @confirm="goUpdProfile"
    >
    </van-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { Popup, Collapse, CollapseItem, List, Dialog,Image,Toast} from "vant";
import logo_white from "../../assets/img/logo_white.png";
import logo_black from "../../assets/img/logo_black.png";
import user_ic_def from "../../assets/user_ic.png";
import apis from "../../utils/apis";
import {isNotEmpty} from "@/utils/priceFormat";
let { getDataCount } = apis
Vue.use(Popup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(List);
Vue.use(Image);
Vue.use(Dialog);

export default {
  name: "navBar",
  props: ["ifBlack"],
  data() {
    return {
      show: false,
      show1: false,
      show2: false,
      show3:false,
      isBlack: this.ifBlack,
      logo: this.ifBlack ? logo_black : logo_white,

      languageTitle: this.$t("app.language"),

      activeNames: ["0"],
      option1: [
        { text: this.$t("app.en"), value: "en" },
        { text: this.$t("app.jp"), value: "jp" },
        { text: this.$t("app.cn"), value: "cn" },
        { text: this.$t("app.hk"), value: "hk" },
      ],
      unfold: true,
      mail: "",
      avatar:"",
      name:"",
      user_ic_def:user_ic_def,
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.email = localStorage.getItem("email");
    this.avatar = localStorage.getItem("avatar");
    this.name = localStorage.getItem("name");
    if (this.token) {
      this.unfold = false;
    }
  },

  mounted() {
    this.$forceUpdate();
    let that = this;
    window.onscroll = function () {
      if (!that.ifBlack) {
        if (window.scrollY < 20) {
          that.isBlack = false;
          that.logo = logo_white;
        }
        if (window.scrollY > 20) {
          that.isBlack = true;
          that.logo = logo_black;
        }
      }
    };
  },
  methods: {
    getDisplayName(){
      return this.name ? this.name:this.email
    },
    onSure() {},
    toRegister() {
      let path = window.location.pathname;
      let search = window.location.search;
      let param = path+search
      this.$router.push({
        path:'/login',
        query:{
          path:param
        }
      });
    },
    toSearch() {
      this.$router.push("/search");
    },

    toDetail(type) {
      if(!isNotEmpty(this.name)){
        Toast.fail(this.$t('app.plz_login'))
      } else{
        switch (type) {
          case 0:
            this.$router.push("/order");
            break;
          case 1:
            this.show1 = true;
            // this.$router.push('/code')
            break;
          case 2:
            this.$router.push("/noticeList");
          case 3:
            this.show3 = true;
          case 4:
            this.$router.push("/personal");
        }
      }
      
    },
    goCode() {
      this.$router.push("/forget");
    },

    goUpdProfile() {
      this.$router.push("/resetHead");
    },

    showPopup() {
      this.$forceUpdate();
      this.show = true;
    },

    toHomePage() {
      if (this.$route.path == "/homePage") {
        return;
      }
      this.$router.push({
        path: "homePage",
      });
    },

    changeLang(value) {
      let params = {
        type: -1,
        id: this.$route.query.id,
        language: 0,
      };
      switch (this.$route.path) {
        case '/detailsPage' : this.$route.query.type == '5' ? params.type = 1 : params.type = 2 //店铺、文章
          break;
        case '/blockDetail': params.type = 3 //新鲜事
          break;
        default: params.type = -1
          break;
      };
      if (params.type != -1) {
        switch (value) {
          case 'cn': params.language = 0
            break;
          case 'jp': params.language = 1
            break;
          case 'en': params.language = 2
            break;
          case 'hk': params.language = 3
            break;
          default: params.language = 2
            break;
        }
        getDataCount({ ...params }, (response) => {
          if (response && response.data > 0) {
            localStorage.setItem("language", value);
            this.$i18n.locale = value;
            this.$forceUpdate();
            this.show = false;
            window.location.reload();
            window.scrollTo(0, 0);
          } else {
            this.$dialog.confirm({
              title: "",
              message: this.$t('app.change_language_null_tips'),
              confirmButtonText: this.$t('app.confirm'),
              cancelButtonText: this.$t('app.cancel')
            }).then(() => {
              localStorage.setItem("language", value);
              // this.$router.go(-1);
              const route = localStorage.getItem("beforeRouter")
              this.$router.push(route);
              window.location.reload();
            }).catch(() => {});
          }
        })
      } else {
        localStorage.setItem("language", value);
        this.$i18n.locale = value;
        this.$forceUpdate();
        this.show = false;
        window.location.reload();
        window.scrollTo(0, 0);
      }

    },
    logout() {
      this.show2 = true;
      // 清除状态保持
      // window.localStorage.clear();
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("name");
      localStorage.removeItem("avatar");
      localStorage.removeItem("userId");
      // 状态保持清除后刷新页面
      // this.$router.push('/login')
    },
    goLogout() {
      this.$router.push("/login");
    },
    goSearchResult(e, index) {
      this.$router.push({
        path: "/result",
        query: {
          name: e,
          index: index,
        },
      });
    },
    collectionList(e, index) {
      this.$router.push({
        path: "/result",
        query: {
          name: e,
          index: index,
          collectFlag:1
        },
      });
    },



  },
};
</script>

<style scoped lang="less">
.navBarBody {
  ::v-deep .van-icon-cross:before {
    color: #000000;
  }

  z-index: 1000;

  &.blackColor {
    background-color: #ffffff !important;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
  }

  position: fixed;
  top: 0;
  left: 0;

  .navBarBodyAll {
    padding: 0.25rem 0.52rem;
    box-sizing: border-box;
    width: 100vw;
    height: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .nav-logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 3.7rem;
      object-fit: cover;
    }

    .propuBotton {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 0.35rem;
      height: 18px;

    }
    .menu-line{
        height: 2px;
        background-color: white;

        &.blackColor {
          background-color: black;
        }
      }
    .selectInputIcon {
      position: relative;
      margin-left: 26px;
      width: 22px;

      &::before {
        content: "";
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        display: block;
      }

      &::after {
        content: "";
        width: 5px;
        height: 1px;
        background: #ffffff;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        right: 4px;
        bottom: 0px;
      }

      &.blackColor::before {
        border: 1px solid #000;
      }

      &.blackColor::after {
        background: #000;
      }
    }
  }
}

.propupWin {
  // overflow: scroll;
  .propupWinBody {
    padding: 1.1rem 0.3rem 0.5rem;
    .signinButton {
      width: 80%;
      height: 40px;
      background: black;
      color: #ffffff;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      border: none;
      font-weight: 500;
    }
    .body-top {
      font-size: 0.36rem;
      font-weight: 600;
      margin: 0.7rem 0 0.2rem 0.2rem;
      ul {
        li {
          margin: 0.36rem 0;
          text-align: left;
        }
        .li {
          font-size: .3rem;
          color: #333333;
        }
      }
      ::v-deep {
        .van-divider {
          border-color: #969799;
        }
      }
      ::v-deep .van-collapse {
        .van-cell {
          padding: 0;
          border: none;
          &::after {
            border-bottom: none;
          }
        }
        .van-cell__title {
          text-align: left;
          font-size: 0.3rem;
          font-weight: 600;
          margin-left: 0;
          color: #333333;
        }
        .van-collapse-item__wrapper {
          .van-cell__title {
            text-align: left;
            font-size: 0.3rem;
            padding: 0.1rem 0;
          }
        }

        .van-collapse-item__content {
          text-align: left;
        }

        .van-cell__right-icon {
          color: black;
        }
      }
    }

    //.body-body{
    //  border-top: 0.00001rem solid #ccc;
    //  ul{
    //    li{
    //      text-align: left;
    //      font-size: 0.3rem;
    //      font-weight: 500;
    //      margin: 0.3rem 0;
    //
    //    }
    //  }
    //}
  }
}
.avatar_username{
  display: flex;
  justify-content: flex-start;
  align-self: center;
  .item {
    display: flex;
    align-self: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    margin: 0 10px;
  }
}
.langList {
  display: flex;
  flex-direction: column;
  color: #333333;
}
.langList > div {
  height: 0.7rem;
  line-height: 0.7rem;
}
</style>
