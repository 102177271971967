export const priceFormat = (money) => {
    return Number(money).toLocaleString();
}

export const isNotEmpty = (val) => {
    if(val !='' && val !=null && val !=undefined && val!='undefined' ){
        return true;
    }
    return false;
}
