import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'



const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCWXYXie3wrVbzhEs8i/mu/VQ6Vh2h6EI\n'+
'8Y2k3HyD8SZGsdS5Gei0G5cZSS6XtWw7t+1nNsp4LLaxtxKtk2T1VSc95dWSfJeTwdj84CWt3hIQ65ikSF5oiyRS\n'+
'PTUQu5OAJ+Ir5BJkBNaOLrsqLEnQ3LV7EYHjUMj2mpkMxp+0swlwIDAQAB';

const privateKey = 'MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAJZdheJ7fCtVvOESzyL+a\n'+
'79VDpWHaHoQjxjaTcfIPxJkax1LkZ6LQblxlJLpe1bDu37Wc2yngstrG3Eq2TZPVVJz3l1ZJ8l5PB2PzgJa3eEhD\n'+
'rmKRIXmiLJFI9NRC7k4An4ivkEmQE1o4uuyosSdDctXsRgeNQyPaamQzGn7SzCXAgMBAAECgYAShppIwbMWOb2IM\n'+
'4OOya7nx2iBFspSA7lFtBvSKgNPVkz2MonR8VFbMjm+cgLOuVXf9jcJY4gTXPJoGoHhlZuq2K/wJeFPWMEf+FjzQ\n'+
'oPlqi3OAsV+1UIH69/iSsZQRHpq63MNXszV5GD9npiXYJLQ/eIihy9s2xb/vYSMfbwVTQJBAOB3KSzuEN9WVWIs9\n'+
'3sbBH9d4Lvqf1N/GGn8LLzKnffOk+KhAGK9DVaxtJOn40J38fHmz2cLfUUQOczA4klLK8MCQQCrfV7ig2VPjQRzKP\n'+
'0xzt9K2js/NFtskW8vOehIPSRbTfKoCxeFQP9Cf4aua+SI8ok1wOLqz5SRnEJ9qsxwJZ6dAkEAsR411mcv1xsnmqV\n'+
'Cztf9z4bDK2SY/cie+hprLrlutrhBxyUlycPFf7APfieO0scUmb130KanPVTVVvSoWg8/wwJANEVajqCOWNka6h8s\n'+
'TepIlYA/XtyOoWETCsoTyzEXy9zOgfofiTZul85XNwMb/cIvoHrSr8/l8Rw4CCXvep984QJANyxDNstdMUlzLGrSTV\n'+
'au1s41L6ub9yJHF5l1JxZ2J5PMipXVwxQO32vCgbH9Ov5jmUGadTSQLi6bpgBKobF1mg==';


export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) 
  return encryptor.encrypt(txt) 
}


export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) 
  return encryptor.decrypt(txt) 
}

