<template>
  <div class="shopDiv" v-if="dataList && dataList.length > 0">
    <div class="title">
      <div class="distTitle1">{{ $t(`app.${title}`) }}</div>
      <!-- <div class="mr-04 fs-025" @click="toModule">{{ $t("app.More") }}</div> -->
    </div>
    <div class="distDiv">
      <div
        class="distItem"
        v-for="(item, index) in dataList"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="shopInfo">
          <img :src="item.img" class="img" alt="" style="margin-bottom: .2rem"/>
          <div v-if="item.tag && item.tag.length > 0" class="tagDiv">
            <van-tag v-for="(obj, i) in item.tag" :key="i" plain type="primary">{{ obj }}</van-tag>
          </div>
          <div class="shopName">{{ item.name }}</div>
          <div class="loc">{{ item.desc | filtersText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Tag } from "vant";
Vue.use(Tag);

export default {
  props: ["dataList", "title"],
  data() {
    return {};
  },
  methods: {
    toModule() {
      let name = '', index = 0;
      if(this.title === 'introduction') {
        name = 'b';
        index = '4';
      }
      if(this.title === 'area') {
        name = 'c';
        index = '1';
      }
      if(this.title === 'scenicSpot') {
        name = 'd';
        index = '2';
      }
      if(this.title === 'shop') {
        name = 'e';
        index = '5';
      }
      if(this.title === 'ticket') {
        name = 'f';
        index = '6';
      }
      this.$router.push({
        path: "/result",
        query: {
          name: name,
          index: index,
        },
      });
    },
    toDetail(item) {
      if (item.type <= 5) {
        this.$router.push({
          path: "/detailsPage",
          query: {
            type: item.type,
            id: item.id,
          },
        });
      } else {
        this.$router.push({
          path: "/orderXq",
          query: {
            type: item.type,
            id: item.id,
          },
        });
      }
    },
  },
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
          .replace(reg, "")
          .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          );
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },
};
</script>

<style scoped lang="less">
.shopDiv {
  margin-top: .4rem;
  text-align: start;
  color: black;
  padding-bottom: 0.4rem;

  .title {
    display: flex;
    margin: 0 auto .4rem;
    justify-content: space-between;
    .more {
      align-items: center;
      display: flex;
      margin-right: .4rem;
      span {
        font-size: .3rem;
      }
      img {
        width: .3rem;
      }
    }
  }

  .distTitle1 {
    font-size: .37rem;
    font-weight: 600;
    letter-spacing: -1px;
    margin-left: .4rem;
  }

  .distDiv {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }

  .distItem {
    display: inline-flex;
    background: transparent;
    margin: 0 0.25rem;
    width: 3.25rem;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    &:first-child {
      margin-left: 0.4rem;
    }

    &:last-child {
      margin-right: 0.4rem;
    }

    .shopInfo {
      width: 3.25rem;
      display: flex;
      flex-direction: column;
      .shopName {
        background: #fff;
        line-height: 0.35rem;
        width: 3.25rem;
        font-size: .3rem;
        text-align: start;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
      }

      .tagDiv {
        margin-bottom: .2rem; 
        white-space: nowrap;
        overflow-x: auto;
      }

      .loc {
        margin-top: 0.13rem;
        background: #fff;
        line-height: .32rem;
        width: 3.25rem;
        font-size: .26rem;
        text-align: start;
        box-sizing: border-box;

        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
      }

      .img {
        display: flex;
        width: 3.25rem;
        height: 3.25rem;
        object-fit: cover;
        border-radius: .16rem;
      }
    }
  }
}
/deep/ .van-tag {
  width: fit-content;
  display: inline-block;
  margin-right: .1rem;
  padding: 0.03rem 0.06rem;
  color: #333333;
}
</style>
