<template>
  <div>
    <header class="header_search">
      <nav-bar :ifBlack="true"></nav-bar>
    </header>
    <div class="list">
      <van-list v-if="total > 0">
        <van-cell>
          <div class="line_height"></div>
          <div class="nav_list">
            <div class="list_title">
              {{$t("app.fresh_news_detail_title") }}
            </div>
            <div
                class="list_nav"
                v-for="(item, index) in searchList"
                :key="index"
                @click="goDetails(item)"
            >
              <div class="head">
                <div class="portrait">
                  <img :src="item.tbAdvertorialInfo.banner" alt="" srcset="" v-if="item.blogType == 1" />
                  <div v-else style="height: 100%">
                    <img :src="getVideoCover(item.tbAdvertorialInfo.banner)" alt="" srcset="" />
                    <img src="../../assets/img/play.png" class="videoPlay" >
                  </div>
                  <span class="icon">
                    <img
                        src="../../assets/img/jingdian2.png"
                        alt=""
                        srcset=""
                    />
                  </span>
                </div>
                <div class="list_describe">
                  <h3>{{ item.tbAdvertorialInfo.advertorialTitle }}</h3>
                  <div class="text-title">
                    <div v-html="$options.filters.filtersText(item.tbAdvertorialInfo.content)"></div>
                  </div>
                  <p class="list_view">
                    {{ item.reviews || 0 }} {{ $t("app.reviews") }}
                  </p>

                  <!-- <p class="list_form" v-if="item.type == 5">
                    from {{ item.extra.price }}
                  </p> -->
                </div>
              </div>
            </div>
            <div class="footer">
              <van-pagination
                  v-model="currentPage"
                  :total-items="total"
                  :page-count="pageNum"
                  @change="pagechange"
                  :items-per-page="pageSize"
              >
                <template #prev-text>
                  <van-icon name="arrow-left" />
                </template>
                <template #next-text>
                  <van-icon name="arrow" />
                </template>
              </van-pagination>
            </div>
          </div>
        </van-cell>
      </van-list>
      <div v-else>
        <van-empty :description="$t('app.No_data')" />
      </div>
    </div>

    <van-popup
        v-model="areaShow"
        position="bottom"
        :style="{ height: '40%' }"
        round
    >
      <van-picker
          show-toolbar
          ref="pickerDialog"
          :columns="columns"
          :confirm-button-text="$t('app.search')"
          :cancel-button-text="$t('app.clear')"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import apis from "../../utils/apis";
import Vue from "vue";
import {
  Popup,
  Search,
  Tab,
  Tabs,
  Pagination,
  List,
  Cell,
  CellGroup,
  Collapse,
  CollapseItem,
  Empty,
  Checkbox,
  CheckboxGroup,
  Picker,
  Tag,
} from "vant";
import NavBar from "../../components/basicComponents/navBar.vue";
import NoPicture from "../../assets/img/no_picture.png";

Vue.use(Popup);
Vue.use(Search);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Pagination);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Empty);
Vue.use(Picker);
Vue.use(Tag);
let { SearchFresh } = apis;
export default {
  components: {
    NavBar,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  data() {
    return {
      NoPicture: NoPicture,
      shareArr:[
        "1000+",
        "2000+",
        "3000+",
        "4000+",
        "5000+",
        "6000+",
        "7000+",
        "8000+",
        "9000+",
      ],
      languageTitle: this.$t("app.language"),
      value1:
          localStorage.getItem("language") == null
              ? "en"
              : localStorage.getItem("language"),
      option1: [
        { text: this.$t("app.en"), value: "en" },
        { text: this.$t("app.jp"), value: "jp" },
        { text: this.$t("app.cn"), value: "cn" },
        { text: this.$t("app.hk"), value: "hk" },
      ],
      show: false,
      activeName: "d",
      currentPage: 1,
      pageSize: 20,
      pageNum: 1,
      activeIndex: 0,
      loading: false,
      finished: false,
      key: "",
      searchList: [],
      total: 0,
      size: "",
      pages: 1,
      viewa: "",
      areaShow: false,
      areaList: [],
      columns: [],
      areaId: '',
    };
  },
  watch: {
    $route(to, from) {
      window.location.reload(); //监测到路由发生跳转时刷新一次页面
    },
  },
  created() {
    this.activeName = this.$route.query.name;
    this.activeIndex = this.$route.query.index;
    this.key = this.$route.query.searchKey;
  },
  mounted() {
    this.getTabList();
    this.randomNumber();
  },
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        val = val.replaceAll("</p>", "/p/");
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
            .replace(reg, "")
            .replace(
                /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
                function (all, t) {
                  return arrEntities[t];
                }
            ).replaceAll("/p/", "<br>");
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },
  methods: {
    onCancel() {
      this.areaShow = false;
      this.columns[0].defaultIndex = 0;
      this.areaId = '';
      this.getTabList();
    },
    onConfirm(val, index) {
      this.columns[0].defaultIndex = index[0]
      if(index[0] === 0) {
        this.areaId = '';
        this.getTabList();
      } else if(index[0] === this.columns.values.length - 1 ) {
        this.areaId = '-1';
        this.getTabList();
      } else {
        this.areaId = this.areaList.find(v => v.areaName === val[0]).id;
        this.getTabList();
      }
      this.areaShow = false;
    },
    onShow() {
      this.show = true;
    },
    randomNumber() {
      let shareArr = [
        "1000+",
        "2000+",
        "3000+",
        "4000+",
        "5000+",
        "6000+",
        "7000+",
        "8000+",
        "9000+",
      ];
      var str = ""; //重新调用赋值给空
      for (var i = 0; i < shareArr.length; i++) {
        var rond = Math.round(Math.random());
        str += shareArr[rond];
      }
      this.viewa = str;
    },
    getTabList() {
      let params = {
        // key: this.key || "",
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        // type: "7",
      };

      const _this = this
      SearchFresh({...params}).then(res => {
        _this.total = res.data.data.total;
        _this.searchList = res.data.data.list;
        _this.pageNum =  Math.ceil(_this.total/_this.pageSize)
        _this.searchList.forEach((it) => {
          it.viewa = _this.shareArr[Math.floor(Math.random() * _this.shareArr.length)];
        });
        // console.log( this.searchList)
      });
    },
    onSearch(e) {
      this.key = e;
      this.getTabList();
    },
    onNav(name, title) {
      this.currentPage = 1;
      this.pageNum = 1;
      this.total = 0;
      this.getTabList();
    },
    searchResult(item) {
      // console.log("searchResult")
      // console.log(item)
      this.$router.push({
        path: "/orderXq",
        query: {
          type: item.type,
          id: item.id,
        },
      });
    },
    toRegister() {
      this.$router.push("/register");
    },
    pagechange(page) {
      this.currentPage = page;
      let params = {
        searchType: this.activeIndex,
        key: this.key || "",
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };
      const _this = this
      SearchFresh({...params}).then(res => {
        _this.total = res.data.data.total;
        _this.searchList = res.data.data.list;
        _this.pageNum =  Math.ceil(_this.total/_this.pageSize)
        _this.searchList.forEach((it) => {
          it.viewa = _this.shareArr[Math.floor(Math.random() * _this.shareArr.length)];
        });
        // console.log( this.searchList)
      });
    },
    goSearch() {
      this.$router.push("/search");
    },
    // 从全部那里跳到详情页面
    goDetails(item) {
      this.$router.push({
        path: "/blockDetail",
        query: {
          type: item.type,
          id: item.advertorialId,
        },
      });
    },
    openInfoWindow(index) {
      this.merchantList[index].infoWindowOptions.openFlg = !this.merchantList[index].infoWindowOptions.openFlg;
    },
    closeInfoWindow(index) {
      this.merchantList[index].infoWindowOptions.openFlg = false;
    },
    goMerchantDetail(index) {
      const item = {
        type: 5,
        id: this.merchantList[index].merchantId,
      }
      this.goShop(item);
    },
    getVideoCover(videourl){
      //console.log(videourl,'videourl');
      let result = videourl.substring(0,videourl.lastIndexOf('.'));
      return result+"_cover.jpeg"
    },
  },
};
</script>

<style scoped lang="less">
@import url("../../assets/css/searchResult.less");
/deep/.gm-ui-hover-effect {
  display: none !important;
}
/deep/ .gm-style .gm-style-iw-c {
  padding: 0px !important;
}
/deep/ .gm-style .gm-style-iw-d {
  overflow: hidden !important;
}
.map-merchant-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: bold;
  height: 22px;
  max-width: 188px;
  padding: 0px 6px;
}
.videoPlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  fill: none;
  color: #FFF;
  pointer-events: none;
  opacity: 0.7;
  transition: opacity .3s;
  height: 45px !important;
  width: 48px !important;
}
</style>
