<template>
  <div>
    <!-- 旅行者详细信息 -->
    <van-nav-bar :title="$t('app.Traveler_Details')" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div class="box">
      <!-- <div class="box_people">{{ $t('app.Required') }}{{ buyQuantity }}{{ $t('app.human') }}</div> -->
      <div>
        <van-form @submit="onSubmit">
          <!-- Contact person -->
          <div class="traveler_one">{{ $t('app.liaisonDetail') }}</div>
          <p class="label">{{$t('app.Email')}}</p>
          <a class="label" style="font-size: 0.25rem; color: #ee0a24">{{ $t('app.emailEntryHint') }}</a>
          <van-field v-model="email" :rules="[{ required: true, message: $t('app.Please_email'), trigger: 'onBlur'  }, {
                  validator: (value) => {
                    return /.+@.+\..+/.test(value);
                  }, message: $t('app.Email_required_must'),
                  trigger: 'onBlur',
                }]" />


          <div v-for="(item,index) in orderCustomerDtoinfolist" :key="index">
            <div class="traveler_one">{{ $t('app.Traveler') }}{{ index + 1 }}</div>
            <div v-if="travelInfo.nameRequiredFlag === '1'">
              <p class="label">{{ $t('app.customerName') }}</p>
              <van-field v-model="item.customerName" :rules="[{ required: true, message: $t('app.customerNameRequired') }]" />
            </div>
            <div v-if="travelInfo.telephoneRequiredFlag === '1'">
              <p class="label">{{ $t('app.customerTelephone') }}</p>
              <div style="width: 100%; display: flex">
                <!-- <div style="width: 30%; border: 0.02rem solid #bdbdbd; border-radius: 0.08rem"></div> -->
                  <van-field v-model="item.countryCode" right-icon="arrow-down" disabled class="right_icon" style="width: 30%; margin-right: 2%;" @click="select(index)" />
                  <van-field v-model="item.customerTelephone" type="number" :rules="[{ required: true, message: $t('app.customerTelephoneRequired') }]" />
              </div>
              <!-- <div v-if="telErrMsg" style="color: #ee0a24;font-size: 12px;text-align: left;margin-top: 0.05rem;">请输入手机号</div> -->
            </div>
            <div v-if="travelInfo.emailRequiredFlag === '1'">
              <p class="label">{{ $t('app.customerEmail') }}</p>
              <van-field v-model="item.customerEmail" :rules="[{ required: true, message: $t('app.Please_email'), trigger: 'onBlur'  }, {
                  validator: (value) => {
                    return /.+@.+\..+/.test(value);
                  }, message: $t('app.Email_required_must'),
                  trigger: 'onBlur',
                }]" />
            </div>
            <div v-if="travelInfo.wechatRequiredFlag === '1'">
              <p class="label">{{ $t('app.customerWechat') }}</p>
              <van-field v-model="item.customerWechat" :rules="[{ required: true, message: $t('app.customerWechatRequired') }]" />
            </div>
            <div v-if="travelInfo.passportRequiredFlag === '1'">
              <p class="label">{{ $t('app.passport') }}</p>
              <van-field v-model="item.customerPassport" :rules="[{ required: true, message: $t('app.customerPassportRequired') }]" />
            </div>
            <div v-if="travelInfo.sexRequiredFlag === '1'">
              <p class="label">{{ $t('app.customerSex') }}</p>
              <van-field name="radio" class="sex_field" :rules="[{ required: true, message: $t('app.customerSexRequired') }]">
                <template #input>
                  <van-radio-group v-model="item.customerSex" direction="horizontal">
                    <van-radio name="0">{{ $t('app.man') }}</van-radio>
                    <van-radio name="1">{{ $t('app.woman') }}</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </div>
            <div v-if="travelInfo.useDateFlag === '1'">
              <p class="label">{{ $t('app.customerUseDate') }}</p>
              <van-field v-model="item.customerUseDate" :rules="[{ required: true, message: $t('app.customerUseDateRequired') }]"
                         disabled @click="dateClick(index, 'use')" />
            </div>
            <div v-if="travelInfo.codeReuqiredFlag === '1'">
              <p class="label">{{ $t('app.customerCode') }}</p>
              <van-field v-model="item.customerCode" :rules="[{ required: true, message: $t('app.customerCodeRequired') }]" />
            </div>
            <div v-if="travelInfo.addressRequiredFlag === '1'">
              <p class="label">{{ $t('app.customerAddress') }}</p>
              <van-field v-model="item.customerAddress" :rules="[{ required: true, message: $t('app.customerAddressRequired') }]" />
            </div>
            <div v-if="travelInfo.creditNoRequiredFlag === '1'">
              <p class="label">{{ $t('app.customerCreditType') }}</p>
              <van-field v-model="item.customerCreditType" :rules="[{ required: true, message: $t('app.customerCreditTypeRequired') }]"
                         disabled @click="typeClick(index)" />
            </div>
            <div v-if="travelInfo.creditNoRequiredFlag === '1' && item.customerCreditType !== ''">
              <p class="label">{{ $t('app.customerCreditNo') }}</p>
              <van-field v-model="item.customerCreditNo" :rules="[{ required: true, message: $t('app.customerCreditNoRequired') }]" />
            </div>
            <div v-if="travelInfo.receiveDateFlag === '1'">
              <p class="label">{{ $t('app.customerReceiveDate') }}</p>
              <van-field v-model="item.customerReceiveDate" :rules="[{ required: true, message: $t('app.customerReceiveDateRequired') }]"
                         disabled @click="dateClick(index, 'receive')" />
            </div>
            <div v-if="travelInfo.customItemList.length > 0">
              <div v-for="(obj, i) in travelInfo.customItemList" :key="i">
                <div v-if="obj.itemRequiredFlag === '1'">
                  <p class="label">{{ obj.itemName }}</p>
                  <van-field v-model="item.orderCustomCustomerItemDtolist[i].itemContent"
                             :rules="obj.itemRequiredFlag === '1' ? [{ required: true, message: $t('app.pleaseEnter') + `${obj.itemName}`, trigger: 'onBlur' }] : []" />
                </div>
              </div>
            </div>
          </div>


          <div class="footer">
            <div class="footer_top">
              <div class="money">{{ $t('app.money') }} {{ priceFormat(money) }}<span class="tickets"> / {{ num }}
                  {{ num > 1 ? $t('app.tickets_s') : $t('app.tickets') }}</span><span class="payment">{{ $t('app.paymentProtection') }}</span></div>
              <div>
                <!-- 下一步 -->
                <van-button type="warning" size="large" native-type="submit">{{ $t('app.Next') }}</van-button>
              </div>
            </div>
            <div class="footer_bottom">
              <img src="../../assets/img/lightning.png" alt="" srcset="">
              {{ $t('app.instant_confirmation') }}
            </div>
          </div>
        </van-form>
      </div>
    </div>

    <van-popup
      v-model="creditTypeShow"
      position="bottom"
      :style="{ height: '40%' }"
      round
    >
      <van-picker
        :columns="creditTypeColumns"
        show-toolbar
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>

    <van-calendar v-model="dateShow" :show-confirm="false" :default-date="defaultDate" @confirm="dateConfirm" />

    <van-popup
      v-model="countryCodeShow"
      position="bottom"
      :style="{ height: '75%' }"
      round
      closeable
      close-icon-position="top-left"
      @click-close-icon="countryCodeShow = false"
      style="overflow: hidden;"
    >
      <van-search v-model="searchInput" shape="round" @search="onSearch" class="search_col" />
      <!-- <div class="com_use">常用</div> -->
      <div style="height: 65%; overflow: auto;">
        <van-field v-for="(item, index) in commonColumns" :key="index" class="com_col"
                   v-model="item.name" disabled clickable @click="countryClick(item.code)" />
      </div>
    </van-popup>


  </div>
</template>
<script>
import Vue from 'vue';
import {NavBar, Form, Field, Divider, Button, RadioGroup, Radio, Popup} from 'vant';
import apis, {hostUrlHK} from "../../utils/apis";
import { commonCountries } from "../contacts/countryCode";
let {createOrderAndPay, getOauthCode} = apis
import { encrypt } from "../../utils/jsencrypt";
import { hostUrl } from "../../utils/apis";
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(Divider);
Vue.use(Button);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Popup);

const createInfo = (orderCustomCustomerItemDtolist, orderCustomerSeq) => {
  // let sex = this.travelInfo.sexRequiredFlag == '1' ? '1':'';
  return {
    customerName: '',
    customerEmail: '',
    orderCustomerSeq,
    countryCode: '',
    customerTelephone: '',
    customerWechat: '',
    customerPassport: '',
    customerSex:"",
    customerUseDate: '',
    customerReceiveDate: '',
    customerCode: '',
    customerAddress: '',
    customerCreditType: '',
    customerCreditNo: '',
    orderCustomCustomerItemDtolist,
  };
};

export default {
  data() {
    return {
      email: localStorage.getItem('email') == 'null' || localStorage.getItem('email') == null ? '' : localStorage.getItem('email'),
      userName: localStorage.getItem('name') ? localStorage.getItem('name') : '',
      num: 1,
      buyQuantity: 1,
      orderCustomerDtoinfolist: [],
      travelInfo: {},
      money:"",
      creditTypeShow: false,
      creditTypeColumns: [
        {
          values:
            [
              this.$t('app.identityCard'),
              this.$t('app.studentIDCard'),
              this.$t('app.militaryIDCard'),
              this.$t('app.passport'),
              this.$t('app.residenceBooklet'),
              this.$t('app.hongKongAndMacaoPass'),
              this.$t('app.taiwanSyndrome'),
            ],
          defaultIndex: 0
        }
      ],
      index: '',
      dateShow: false,
      str: '',
      defaultDate: new Date(),
      countryCodeShow: false,
      searchInput: '',
      commonColumns: [],
    }
  },
  created() {
    this.commonColumns = this.getCommonColumns();

    this.travelInfo = JSON.parse(this.$route.query.contact);
    this.money = this.travelInfo.priceTypeList[0].totalAmount;
    this.num = this.travelInfo.priceTypeList[0].buyQuantity;
    this.travelInfo.customItemList = this.travelInfo.customItemList.map(({itemName, itemSeq, itemRequiredFlag}) => (Object.assign({itemName, itemSeq, itemRequiredFlag}, {itemContent: ''})));
    // console.log(this.travelInfo,'----1111------');
    if(this.travelInfo.customerDataType === '1') {
      this.orderCustomerDtoinfolist.push(createInfo(JSON.parse(JSON.stringify(this.travelInfo.customItemList)), 1));
    } else {
      for (let i = 0; i < this.travelInfo.priceTypeList[0].buyQuantity; i++) {
        this.orderCustomerDtoinfolist.push(createInfo(JSON.parse(JSON.stringify(this.travelInfo.customItemList)), (i + 1)));
      }
    }
    // this.buyQuantity = this.orderCustomerDtoinfolist.length;
  },
  methods: {
    getCommonColumns() {
      const lag = localStorage.getItem('language');
      return lag === null ? commonCountries['en'] : commonCountries[lag];
    },
    select(index) {
      this.index = index;
      this.searchInput = '';
      this.onSearch();
      this.countryCodeShow = true;
    },
    onSearch() {
      if(this.searchInput?.length) {
        this.commonColumns = this.commonColumns.filter(el => el.name.includes(this.searchInput));
      } else {
        this.commonColumns = this.getCommonColumns();
      }
    },
    countryClick(code) {
      this.countryCodeShow = false;
      this.orderCustomerDtoinfolist[this.index].countryCode = code;
    },
    typeClick(index) {
      this.index = index;
      this.creditTypeShow = true;
    },
    onConfirm(val, i) {
      this.orderCustomerDtoinfolist[this.index].customerCreditType = val[0];
      this.creditTypeShow = false;
    },
    onCancel() {
      this.creditTypeShow = false;
    },
    dateClick(index, str) {
      this.defaultDate = new Date();
      this.index = index;
      this.str = str;
      const useDate = this.orderCustomerDtoinfolist[this.index].customerUseDate;
      const receiveDate = this.orderCustomerDtoinfolist[this.index].customerReceiveDate;
      if(str === 'use' && useDate !== '') {
        this.defaultDate = new Date(this.orderCustomerDtoinfolist[this.index].customerUseDate);
      }
      if(str === 'receive' && receiveDate !== '') {
        this.defaultDate = new Date(this.orderCustomerDtoinfolist[this.index].customerReceiveDate);
      }
      this.dateShow = true;
    },
    formatDate(date) {
      const padZero = (val) => val.toString().padStart(2, '0')
      return `${date.getFullYear()}/${padZero(date.getMonth() + 1)}/${padZero(date.getDate())}`;
    },
    dateConfirm(date) {
      if(this.str === 'use') {
        this.orderCustomerDtoinfolist[this.index].customerUseDate = this.formatDate(date);
      } else {
        this.orderCustomerDtoinfolist[this.index].customerReceiveDate = this.formatDate(date);
      }
      this.dateShow = false;
    },
    onSubmit(values) {
      this.toPay()
    },
    onClickLeft() {
      this.$router.back()
    },
    // 下一步触发
    toPay() {

      let totalAmount = 0;
      this.travelInfo.priceTypeList.forEach(el => {
        totalAmount = totalAmount + el.totalAmount;
      });
      let orderCustomerList = JSON.parse(JSON.stringify(this.orderCustomerDtoinfolist));
      orderCustomerList.forEach(el => {

        if(el.countryCode && el.customerTelephone){
          el.customerTelephone = el.countryCode + '-' + el.customerTelephone;
        }

        this.creditTypeColumns[0].values.forEach((v, index) => {
          if(v === el.customerCreditType) {
            el.customerCreditType = index;
          }
        });
        el.orderCustomCustomerItemDtolist.forEach(l => delete l.itemRequiredFlag);
        delete el.countryCode;
      });

      orderCustomerList.forEach(el =>{
        Object.keys(createInfo(null,null)).forEach(v => {
          if(el[v] == '' || el[v] == undefined || el[v] == null){
            delete el[v];
          }
        });
        if(el.customerUseDate)
        el.customerUseDate = el.customerUseDate.replaceAll('/', '-');
        if(el.customerReceiveDate)
        el.customerReceiveDate = el.customerReceiveDate.replaceAll('/', '-');
      })

      // const info = encrypt(JSON.stringify({
      //     email: localStorage.getItem('email'),
      //     name: localStorage.getItem('name'),
      //     userId: localStorage.getItem('userId'),
      // }));
      // console.log("加密info:",info);

      let params = {
        productUseDate: this.travelInfo.productUseDate, //使用日期
        mailUseLanguageType: this.travelInfo.productId.length > 11 ? (localStorage.getItem('language') == null ? '2' : localStorage.getItem('language') == 'cn' ? '0'
            : localStorage.getItem('language') == 'jp' ? '1' : localStorage.getItem('language') == 'hk' ? '3' : '2') : '1', //通知邮件语言
        productId: this.travelInfo.productId, //产品ID
        productName: this.travelInfo.productName,
        customerEmail: this.email, //凭证发送邮箱（联系人邮箱）
        totalAmount: totalAmount, //总金额
        orderCustomerDtoinfolist: orderCustomerList,
        priceTypeList: this.travelInfo.priceTypeList,
        productImg: this.travelInfo.productPicturePath,//票图片
        domainUrl: window.location.host,
        // info: info,
        // token: localStorage.getItem('token'),
        // avatar: localStorage.getItem('avatar'),
      }
      localStorage.setItem("payParams", JSON.stringify(params));
      // console.log('创建订单入参', params);
      createOrderAndPay({...params}, data => {
        localStorage.setItem("payUrl", encodeURIComponent(data.data.payUrl));
        localStorage.setItem("ticketDetail", JSON.stringify(this.travelInfo));
        localStorage.setItem("namePay", this.userName);
        localStorage.setItem("emailPay", this.email);
        localStorage.setItem("payValue.orderId", data.data.orderId);
        
        const browser = window.navigator.userAgent.toLowerCase()

        if (browser.indexOf('micromessenger') > -1) {
          const path = window.location.href;
          if (path.indexOf(hostUrlHK) > -1) {
            const redirectUri = hostUrlHK + "/orderPaynow";
            let paramsOauthCode = {
              redirectUri: redirectUri
            }
            getOauthCode({...paramsOauthCode}, response => {
              window.location.href = response.msg;
            })
          } else {
            this.$router.push('/orderPaynow');
          }
        } else {
          this.$router.push('/orderPaynow');
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
/deep/ .van-cell-group--inset {
  margin: 0;
}

/deep/ .van-cell {
  padding: 0;
}

/deep/ .van-field__body {
  height: .8rem;
  border: .02rem solid #bdbdbd;
  border-radius: .08rem;
  padding-left: .24rem;
}

// /deep/ .van-icon {
//   color: black;
//   font-size: .36rem;
// }
/deep/ .search_col {
  margin: .8rem 0 .5rem;
  .van-field__body {
    border: none;
    .van-field__control {
      width: 90%;
    }
  }
  .van-field__left-icon {
    display: flex;
    align-items: center;
  }
  .van-icon-search {
    font-size: 24px;
  }
}
.com_use {
  padding: .5rem .8rem;
  text-align: left;
  font-weight: bold;
}
/deep/ .com_col {
  padding: 0 1rem;
  .van-field__body {
    border: none;
  }
}


/deep/ .van-nav-bar__title {
  font-size: .40rem;
  font-weight: bold;
}


/deep/ .van-cell::after {
  border-bottom: 0;
}

/deep/ .van-button--warning {
  background-color: #F2b203;
  padding: .4rem .64rem;
  color: #333;
  font-size: .36rem;
  font-weight: bold;
  border-radius: 0.2rem;
  border: none;
}

/deep/ .van-divider {
  margin: 0;
  height: 2px;
  background: #ebebeb;
}

/deep/ .van-field__label {
  width: 130px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

/deep/ .van-nav-bar__content {
  box-shadow: 5px 2px 5px #DDDDDD;
}

// /deep/ .van-field__control {
//   color: #000000;
//   -webkit-text-fill-color: #000000;
// }

.van-hairline--bottom:after {
  border-bottom-width: 0;
}

.box {
  padding: .72rem .4rem 1rem .4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .traveler_one {
    text-align: left;
    padding: 12px 0;
    font-size: .4rem;
    font-weight: bold;
    color: #000000;
  }

  .box_people {
    text-align: left;
    color: #9fa0a0;
    padding-bottom: 10px;
  }
}

.footer {
  border-top: 1px solid #ebebeb;
  left: 0;
  right: 0;
  height: 2.6rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 12px;
  background: #ffffff;
  position: fixed;
  z-index: 1000;
  box-sizing: border-box;

  .footer_top {
    display: flex;
    justify-content: space-between;
    height: 60px;

    .money {
      font-weight: 540;
      // color: #ef2851;
      font-size: 24px;

      .tickets {
        color: #000000;
        font-weight: 500;
        font-size: .24rem;
      }

      .payment {
        display: block;
        text-align: left;
        font-weight: 500;
        font-size: .24rem;
        color: #4aa476;
      }
    }
  }


}

.label {
  display: block;
  margin: 10px 0 10px 0;
  text-align: left;
  width: 100%;
}

.footer_bottom {
  height: 1.2rem;
 line-height:1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .32rem;

  img {
    height: 20px;
    margin-right: .2rem;
  }
}
/deep/ .right_icon {
  .van-icon {
    font-size: .13rem;
    margin-right: .15rem;
  }
}
/deep/ .sex_field {
  .van-field__body {
    border: none;
  }
}
</style>
