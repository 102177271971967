<template>
    <div>
        <div class="logo">
            <img src="../../assets/img/logo_icon.png" alt="" srcset="">
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{

        }
    }
}
</script>
<style scoped lang="less">
.logo {
    width: 100%;
    padding: .48rem .5rem;
    height: 2.2rem;
    box-sizing: border-box;
    img {
        float: left;
        width: 100%;
        height: 2rem;
        object-fit: cover;
    }
}
</style>