<template>
    <div class="paySuccess">
        <div class="pay_box">
            <div class="pay_logo">
                <img src="../../assets/img/logo_black.png" alt="">
            </div>
            <div class="pay_title">
                {{getPayTitle()}}
            </div>
            <div class="pay_center">
                <img src="../../assets/img/pay.png" alt="">
            </div>
            <div class="pay_list">
                <div class="pay_list_flex" v-if="outTradeNo">
                    <div class="pay_lf">{{ $t('app.System_Order_ID') }}</div>
                    <div class="pay_rf">{{ outTradeNo }}</div>
                </div>
                <div class="pay_list_flex" v-if="qfpayId">
                    <div class="pay_lf">{{ $t('app.Payment_Order_ID') }}</div>
                    <div class="pay_rf">{{ qfpayId }}</div>
                </div>
                <div class="pay_list_flex" v-if="easygoId">
                    <div class="pay_lf">{{ $t('app.Ticket_Order_ID') }}</div>
                    <div class="pay_rf">{{ easygoId }}</div>
                </div>
                <div class="pay_list_flex" v-if="orderStatus">
                    <div class="pay_lf">{{ $t('app.Order_Status') }}</div>
                    <div class="pay_rf">{{ orderStatus }}</div>
                </div>
                <div class="pay_list_flex" style="border-bottom:0;">
                    <div class="pay_lf">{{ $t('app.Payment_Status') }}</div>
                    <div class="pay_rf">{{ this.result=='0000'?$t('app.Success'):$t('app.Failure') }}</div>
                </div>
            </div>
            <div class="pay_btn">
                <van-button type="default" @click="complete">{{
                        $t('app.Complete')
                    }} </van-button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { Button, Toast } from 'vant';
import apis,{pcHostUrl} from "../../utils/apis";
let { OrderDetail,sendQfpayCallback } = apis;
import { decrypt, encrypt } from "../../utils/jsencrypt";


Vue.use(Button);
Vue.use(Toast);
export default {
    data(){
        return{
            qfpayId: '',
            easygoId: '',
            outTradeNo:'',
            orderStatus: '',
            result:'',
            value1: localStorage.getItem('language') == null ? 'en' : localStorage.getItem('language'),
            option1: [
                { text: this.$t('app.en'), value: 'en' },
                { text: this.$t('app.jp'), value: 'jp' },
                { text: this.$t('app.cn'), value: 'cn' },
                { text: this.$t('app.hk'), value: 'hk' },
            ],
        }
    },
    created(){

        if (window != top)  {
          top.location.href = location.href;
        }

        let userAgentInfo = navigator.userAgent
        let Agents = [  'Android',   'iPhone',   'SymbianOS',   'Windows Phone',   'iPad',   'iPod']
        let isPc = true
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                isPc = false
                break
            }
        }
        if(isPc){
            let param = window.location.search;
            window.location.href = pcHostUrl+'/payResult'+param;
        }

        // console.log('localStorage>>>language：',localStorage.getItem('language'));
        // console.log('localStorage>>>email：',localStorage.getItem('email'));
        // console.log('localStorage>>>avatar：',localStorage.getItem('avatar'));
        // console.log('localStorage>>>name：',localStorage.getItem('name'));
        // console.log('localStorage>>>userId：',localStorage.getItem('userId'));
        // console.log('localStorage>>>token：',localStorage.getItem('token'));

        this.outTradeNo = this.getParams('outTradeNo');
        const isWechat = this.getParams("isWechat");
        if(isWechat != '1' && this.outTradeNo!=null && this.outTradeNo != undefined && this.outTradeNo !=""){
            let sendQfpayCallbackRequest = {
                orderNum: this.outTradeNo,
                status: this.result,
                errorMsg: this.result=="0000" ? "交易成功":"交易失败",
                sign:"1C281E908AB30FBEA7C3FD0157257B50"
            }
            sendQfpayCallback(sendQfpayCallbackRequest,data => {
                console.log("payresult sync called");
            })
        }
        this.result = this.getParams('result');
        if(this.result=='0000'){
            this.orderStatus =  this.$t("app.Success");
        }else{
            this.orderStatus =  this.$t("app.Failure");
        }
        // if(this.getParams('language') == undefined || this.getParams('language') == null || this.getParams('language') == '') {
        //     this.$i18n.locale = navigator.language == 'ja' ? 'ja'
        //                         : (navigator.language.split('-')[1] == 'TW' || navigator.language.split('-')[1] == 'HK') ? 'hk'
        //                         : navigator.language.split('-')[1] == 'CN' ? 'cn' : 'en';
        // } else {
        //     let language = this.getParams('language') == 0 ? 'cn' : this.getParams('language') == 1 ? 'jp' : this.getParams('language') == 2 ? 'en' : 'hk';
        //     this.$i18n.locale = language;
        // }
        // window.scrollTo(0, 0);

        const navLag = navigator.language == 'ja' ? 'jp'
                : (navigator.language.split('-')[1] == 'TW' || navigator.language.split('-')[1] == 'HK') ? 'hk'
                : navigator.language.split('-')[1] == 'CN' ? 'cn' : 'en';
        let language = localStorage.getItem('language');
        this.$i18n.locale = language == null ? navLag : language;

        if(this.outTradeNo != undefined && this.outTradeNo != null && this.outTradeNo != '') {


            if(this.result == '0000') {
                this.getOrderDetrails();

                const timer = setInterval(() => {
                    if(this.orderStatus != this.$t("app.Success")) {
                        OrderDetail({mail: localStorage.getItem('email'), orderId: this.outTradeNo}, data => {
                            this.qfpayId = data.data.productInfo.qfpayId;
                            this.easygoId = data.data.productInfo.easygoId;
                            this.orderStatus = data.data.orderInfo.orderStatus == 1
                                ? this.$t("app.Success")
                                : data.data.orderInfo.orderStatus == 2
                                ? this.$t("app.Failure")
                                : data.data.orderInfo.orderStatus == 3
                                ? this.$t("app.Processing")
                                : this.$t("app.Processing");
                            if(data.data.orderInfo.orderStatus == 1) {
                                clearInterval(timer);
                            }
                        });
                    }
                }, 5000);

                this.$once('hook:beforeDestroy', () => {
                    clearInterval(timer);
                    timer = null;
                });
            }

        } else {
            Toast.fail(this.$t('app.createdOrderFailed'))
        }
    },
  methods:{
    complete(){
      this.$router.replace('/homePage')
    },
    getPayTitle(){
        if(this.result != '0000') {
            return this.$t('app.pay_failed');
        } else if (this.orderStatus == this.$t("app.Success")) {
            return this.$t('app.ticket_issued_successful');
        } else {
            return this.$t('app.pay_successful');
        }
        // return this.result=='0000'?this.$t('app.pay_successful'):this.$t('app.pay_failed');
    },
    getParams(key) {
        let search = window.location.search.replace(/^\?/, "");
        let pairs = search.split("&");
        let paramsMap = pairs.map(pair => {
            let [key, value] = pair.split("=");
            return [decodeURIComponent(key), decodeURIComponent(value)];
        }).reduce((res, [key, value]) => Object.assign(res, { [key]: value }), {});
        return paramsMap[key] || "";
    },
    getOrderDetrails() {
        OrderDetail({mail: localStorage.getItem('email'), orderId: this.outTradeNo}, data => {
            this.qfpayId = data.data.productInfo.qfpayId;
            this.easygoId = data.data.productInfo.easygoId;
            this.orderStatus = data.data.orderInfo.orderStatus == 1
                ? this.$t("app.Success")
                : data.data.orderInfo.orderStatus == 2
                ? this.$t("app.Failure")
                : data.data.orderInfo.orderStatus == 3
                ? this.$t("app.Processing")
                : this.$t("app.Processing");
        });
    },
  }
}



</script>

<style scoped lang="less">
/deep/.van-button--default{
padding: 0 .4rem;
color: #000000;
font-size: .36rem;
border:.02rem solid #c9c9c9;
border-radius: .12rem;
}
.paySuccess {
    position: relative;
    height: 100vh;
    width: 100%;
    // background: url('../../assets/img/paySuccess.png') no-repeat;
    // background-size: contain;
    .pay_box {
        height: 11rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 1.1rem .4rem;
        .pay_logo {
            height: 1.8rem;
            width: 80%;
            object-fit: cover;
            img {
                width: 100%;
                height: 1.5rem;
                object-fit: contain;
            }
        }
        .pay_title{
            font-size: .44rem;
            color: #000000;
            font-weight: bold;
            padding: 0 .8rem;
        }
        .pay_center{
            height: 3.2rem;
            width: 3rem;
            img{
                width: 100%;
            }
        }
        .pay_list{
            display: flex;
            flex-direction: column;
            width: 100%;
            .pay_list_flex{
                display: flex;
                width: 100%;
                padding: .2rem 0;
                justify-content: space-between;
                border-bottom: .04rem solid #fafafa;
                .pay_lf{
                    font-size: 0.23rem;
                    color: #9fa0a0;
                }
                .pay_rf{
                    font-weight: 540;
                    font-size: 0.23rem;
                    color: #000000;
                }
            }
        }
    }
}
</style>
