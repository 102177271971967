<template>
  <div style="padding: 0 0.3rem;">
    <van-swipe class="homePage-swipe" style="border-radius: 0.16rem" :autoplay="4000">
      <van-swipe-item v-for="(item, index) in dataList" :key="index">
        <img :src="item.columnImageUrl" @click="toDetail(tab, item)"/>
      </van-swipe-item >
    </van-swipe>
  </div>
</template>

<script>
import {Swipe, SwipeItem} from 'vant';

export default {
  props: {
    dataList: {
      type: Array,
    },
    tab: {
      type: Number,
      default: 0,
    },
  },
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
    }
  },
  methods: {
    toDetail(tab, item) {
      if (tab <= 5) {
        this.$router.push({
          path: "/detailsPage",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      } else {
        this.$router.push({
          path: "/orderXq",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      }
    },
  }
}
</script>

<style scoped lang="less">
.homePage-swipe {
  img {
    width: 100%;
    height: 4rem;
    display: block;
    object-fit: cover;
  }

  .van-swipe__indicators {
    .van-swipe__indicator {
      background-color: white;
      opacity: 1;
      border: 1px #000 solid;
    }

    .van-swipe__indicator--active {
      width: 18px;
      border-radius: 6px;
    }
  }
}
</style>
