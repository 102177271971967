<template>
  <div>
    <nav-bar :ifBlack="true"></nav-bar>
    <div class="header">
      <div @click="goback"><van-icon name="arrow-left" /></div>
      <span class="headerText">{{ $t('app.thingsToDo') }} {{ title }}</span>
      <!-- <van-button @click="toModule" round>{{ $t('app.exploreAll') }}</van-button> -->
    </div>
    <common-part :dataList="introductList" title="introduction" />
    <common-part :dataList="areaList" title="ref_area" />
    <common-part :dataList="scenicList" title="scenicSpot" />
    <common-part :dataList="shopList" title="shop" />
    <common-part :dataList="ticketList" title="ticket" />
  </div>
</template>
<script>
import apis from "../../utils/apis";
import CommonPart from  "../../components/search/SearchAllCommon.vue";
import NavBar from "../../components/basicComponents/navBar.vue";

export default {
  components: { CommonPart, NavBar },
  data() {
    return {
      title: '',
      searchList: [],
      introductList: [],
      areaList: [],
      scenicList: [],
      shopList: [],
      ticketList: [],
    }
  },
  created() {
    this.title = this.$route.query.title;
    this.getTableList('1')
    this.getTableList('2')
    this.getTableList('4')
    this.getTableList('5')
    this.getTableList('6')
  },
  methods: {
    getTableList(type) {
      let params = {
        searchType: type,
        key: this.title || "",
      };
      apis.SearchAll({...params}).then(res => {
        switch(type) {
            case '1':
              this.areaList = res.data.data.list;
              break;
            case '2':
              this.scenicList = res.data.data.list;
              break;
            case '4':
              this.introductList = res.data.data.list;
              break;
            case '5':
              this.shopList = res.data.data.list;
              break;
            case '6':
              this.ticketList = res.data.data.list;
              break;
          }
      });
    },
    toModule() {
      this.$router.push({
        path: "/result",
        query: {
          inputText: this.headerText,
          name: 'a',
          index: '0',
        },
      });
    },
    goback() {
      this.$router.go(-1);
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .van-icon {
  font-size: .45rem;
}
::v-deep .header {
  display: flex;
  justify-content: space-between;
  margin: 1.4rem .2rem .4rem;
  align-items: center;
  .headerText {
    // font-weight: 800;
    font-size: .4rem;
    // letter-spacing: -1px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    width: 100%;
    text-align: left;
  }
  .van-button {
    padding: 0 .2rem;
    height: .4rem;
    font-weight: 600;
    color: #000000;
    border-color: #000000;
    letter-spacing: -1px;
    .van-button--normal {
      font-size: .18rem;
    }
  }
}
</style>