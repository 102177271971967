var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"header_search"},[_c('a',{staticClass:"left_icon",attrs:{"href":"javascript:;"},on:{"click":_vm.goBack}},[_c('van-icon',{attrs:{"name":"down","size":"18"}})],1),_c('div',{staticClass:"search_input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchKey),expression:"searchKey"}],attrs:{"type":"text"},domProps:{"value":(_vm.searchKey)},on:{"input":function($event){if($event.target.composing)return;_vm.searchKey=$event.target.value}}})]),_c('a',{staticClass:"right_icon",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.goSearchResult('a','0')}}},[_c('van-icon',{attrs:{"name":"search"}})],1)]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"box_list",on:{"click":function($event){return _vm.goSearchResult('b','4')}}},[_vm._m(0),_c('div',{staticClass:"box_list_rf"},[_vm._v(" "+_vm._s(_vm.$t('app.introduction'))+" ")])]),_c('div',{staticClass:"box_list",on:{"click":function($event){return _vm.goSearchResult('c','1')}}},[_vm._m(1),_c('div',{staticClass:"box_list_rf"},[_vm._v(" "+_vm._s(_vm.$t('app.area'))+" ")])]),_c('div',{staticClass:"box_list",on:{"click":function($event){return _vm.goSearchResult('d','2')}}},[_vm._m(2),_c('div',{staticClass:"box_list_rf"},[_vm._v(" "+_vm._s(_vm.$t('app.scenicSpot'))+" ")])]),_c('div',{staticClass:"box_list",on:{"click":function($event){return _vm.goSearchResult('e','5')}}},[_vm._m(3),_c('div',{staticClass:"box_list_rf"},[_vm._v(" "+_vm._s(_vm.$t('app.shop'))+" ")])]),_c('div',{staticClass:"box_list",on:{"click":function($event){return _vm.goSearchResult('f','6')}}},[_vm._m(4),_c('div',{staticClass:"box_list_rf"},[_vm._v(" "+_vm._s(_vm.$t('app.ticket'))+" ")])]),_c('div',{staticClass:"box_list",on:{"click":function($event){return _vm.goSearchResult('a','0')}}},[_vm._m(5),_c('div',{staticClass:"box_list_rf"},[_vm._v(" "+_vm._s(_vm.$t('app.All'))+" ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_list_lf"},[_c('img',{attrs:{"src":require("../../assets/img/gonglue1.png"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_list_lf"},[_c('img',{attrs:{"src":require("../../assets/img/area1.png"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_list_lf"},[_c('img',{attrs:{"src":require("../../assets/img/jingdian1.png"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_list_lf"},[_c('img',{attrs:{"src":require("../../assets/img/shop1.png"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_list_lf"},[_c('img',{attrs:{"src":require("../../assets/img/piao1.png"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_list_lf"},[_c('img',{attrs:{"src":require("../../assets/img/all.png"),"alt":"","srcset":""}})])
}]

export { render, staticRenderFns }