<template>
  <div>
    <nav-bar :ifBlack="true"/>
    <div class="distDiv">
        <div class="header">
            <div @click="goback"><van-icon name="arrow-left" /></div>
            <div class="dictTitle">{{ $t('app.allVideos') }}</div>
          <div class="search">
            <van-search shape="round" v-model="key" @search="onSearch" @clear="onCancel"/>
          </div>

        </div>
        <van-row type="flex" justify="space-between" style="width: 100%">

          <div class="container-water-fall" v-if="videoList.length>0">
            <waterfall :col='col' :data="videoList">
              <template>
                <div class="cell-item" v-for="(item, index) in videoList" :key="index">
                  <VideoPlay  class="video-player vjs-custom-skin" :videoCover="getVideoCover(item.url)" :videoUrl="item.url" :autoplay="false" :loop="false"
                              :muted="true" :id="'video-' + item.id" :idIndex="index" :width="155" :height="100" :type="'video'" :videoId="item.id"/>
                  <div class="videoName">{{ item.title }}</div>
                </div>
              </template>
            </waterfall>
          </div>

          <div v-else class="width_100">
            <van-empty :description="$t('app.No_data')" />
          </div>

        </van-row>
    </div>
  </div>
</template>
<script>
import apis from "../../utils/apis";
import NavBar from "../../components/basicComponents/navBar.vue";
import VideoPlay from "../../components/video/ViedoPlayer.vue";
import {isNotEmpty} from "@/utils/priceFormat";

export default {
  components: { NavBar, VideoPlay },
  data() {
    return {
      videoList: [],
      dataList:[],
      col:1,
      page:'1',
      key:''
    }
  },
  created() {
    this.getVideoList();
  },
  methods: {
    onSearch(e) {
      this.key = e;
      this.getVideoList();
    },
    onCancel(){
      this.key = '';
      this.getVideoList();
    },
    getVideoCover(videourl){
      let result = videourl.substring(0,videourl.lastIndexOf('.'));
      return result+"_cover.jpeg"
    },
    getVideoList() {
      let param = {};
      if(isNotEmpty(this.key)){
        param = {
          title:this.key
        }
      }
      apis.indexVideo(param, data => {
        this.videoList = data.rows;
      })
    },
    goback() {
      this.$router.go(-1);
    }
  }
}
</script>
<style lang="less" scoped>
.distDiv {
    width: 96%;
    margin-left: 2%;
    .header {
      margin-top: 1.4rem;
      margin-bottom: .4rem;
      margin-left: 2%;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .dictTitle {
        display: flex;
        align-items: center;
        font-size: 0.37rem;
        font-weight: 600;
        text-align: left;
      }
    }
    .videoName {
      font-size: 0.28rem;
      text-align: center;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0.1rem 8% 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      white-space: normal;
      font-weight: 600;
      word-wrap: break-word
    }
    .van-col--12 {
      margin-bottom: .2rem;
    }
}
::v-deep .van-icon {
  font-size: .45rem;
  padding-top: .04rem;
}

.container-water-fall {
  padding: 10px 3%;
  width: 100vw;
  box-sizing: border-box;
}

.cell-item {
  background: #ffffff;
  border-radius: 10px;
  -webkit-column-break-inside: avoid;
  box-sizing: border-box;
  margin: 10px 0;
  width: 100%;
}

.item-footer {
  margin-top: 22px;
  position: relative;
  display: flex;
  align-items: center;
}

/deep/ .vue-waterfall-column{
  margin: 0 auto;
  width: 98%;
  column-fill: balance;
  column-count: 2;
  column-gap: 1;
}

/deep/ .vue-waterfall-column .cell-item .c_video{
  height: 220px;
}
/deep/ .vue-waterfall-column .cell-item .m-video{
  height: 220px;
  border-radius: 0.16rem;
}

///deep/ .vue-waterfall-column:nth-child(2) .cell-item:nth-child(3n) .c_video{
//  height: 220px;
//}
///deep/ .vue-waterfall-column:nth-child(2) .cell-item:nth-child(3n) .m-video{
//  height: 220px;
//  border-radius: 0.16rem;
//}
//
///deep/ .vue-waterfall-column:nth-child(2) .cell-item:nth-child(5n) .c_video{
//  height: 220px;
//}
///deep/ .vue-waterfall-column:nth-child(2) .cell-item:nth-child(5n) .m-video{
//  height: 220px;
//  border-radius: 0.16rem;
//}

/deep/ .vue-waterfall-column:nth-child(2) .cell-item:nth-child(odd) .c_video{
  height: 220px;
}
/deep/ .vue-waterfall-column:nth-child(2) .cell-item:nth-child(odd) .m-video{
  height: 220px;
  border-radius: 0.16rem;
}

.search{
  width: 54%;
}
.width_100{
  width: 100%;
}

/deep/ .van-search__content {
  border: 0.02rem solid #cccc;
  border-radius: 0.6rem;
  box-shadow: 0.02rem 0.02rem 0.08rem #e9e9e9;
  background-color: #fff;
}

/deep/ .vue-waterfall-column .cell-item:first-child {
  margin-top: 0px !important;
}

</style>
