<template>
  <div class="homeImg">
    <img class="headerImg" :src="src?.length ? src : NoPicture" alt="" srcset="" >
  </div>
</template>

<script>
import NoPicture from "../../assets/img/no_picture.png";

export default {
  props:['src'],
  data() {
    return {
      NoPicture: NoPicture,
    }
  }
}
</script>

<style scoped lang="less">
.homeImg{
    // min-height:1.67rem;
    width:100%;
     img{
        display: block;
        width: 100%;
        height:100%;
        object-fit: cover;
     }
}
</style>