<template>
    <div class="m-video">
      <video
        ref="veo"
        v-bind:class="[isActive ? 'c_video' : 'n_video',]"
        :src="videoUrl"
        :poster="videoCover"
        :autoplay="autoplay"
        :controls="!originPlay&&controls"
        :loop="loop"
        :muted="autoplay || muted"
        :preload="preload"
        :id = "idIndex"
        @canplay="canplay"
        @play="handleFullScreen"
        @fullscreenchange="fullscreenchange"
        @click.prevent.once="onPlay">
        您的浏览器不支持video标签。
      </video>
      <img src="../../assets/img/play.png" v-show="originPlay || showPlay" :class="[vplay ? 'hidden' : 'u-play']" :style="'width: ${playWidth/30}rem; height: ${playWidth/30}rem;'" >
    </div>
  </template>
  <script>
  import apis from "@/utils/apis";
  
  export default {
    name: 'Video',
    props: {
      videoUrl: { // The video file url, which must be transmitted, supports the network address https and the relative address require ('@/sets/files/Bao. mp4')
        type: String,
        required: true,
        default: ''
      },
      videoCover: { // Video cover url, supporting network address https and relative address require ('@/sets/images/Bao. jpg')
        type: String,
        default: null
      },
      width: { // Video player width
        type: Number,
        default: 220
      },
      height: { // Video player height
        type: Number,
        default: 165
      },
        /*

        Because currently in the latest version of Chrome browser (and all browsers with Chromium as the core),

        Automatic playback of audio and video is no longer allowed. Even if you set autoplay for video or audio tags

        Properties cannot be played automatically!

        Solution of this component: When setting the video autoplay, automatically set the video to mute muted: true to realize self-play

        The user can use the control bar to turn on the voice, which is similar to the logic of the promotional video automatically played by a certain product

        */
      autoplay: { // Whether to play the video immediately after it is ready
        type: Boolean,
        default: false
      },
      controls: { // Whether to display controls to users, such as progress bar, full screen
        type: Boolean,
        default: true
      },
      loop: { // After the video is played, do you want to play it circularly
        type: Boolean,
        default: false
      },
      idIndex: { // muted
        type: Number,
        default: 0
      },
      muted: { // muted
        type: Boolean,
        default: true
      },
      preload: { // Whether to load the video after the page is loaded. If the autoplay attribute is set, preload will be ignored;
        type: String,
        default: 'none' // auto:Once the page is loaded, start to load the video; Metadata: Only the metadata of the video should be loaded after the page is loaded none: The video should not be loaded after the page is loaded
      },
      showPlay: { // Whether to display the pause icon in the middle of the player when playing pause
        type: Boolean,
        default: false
      },
      playWidth: { // Side length of middle play pause button
        type: Number,
        default: 96
      },
      zoom: { // Poster default image and video content scaling rules of video
        type: String,
        default: 'none' // None: (default) Save the original content without zooming; Fill: do not maintain the original proportion, but stretch the content to fill the entire content container; Contain: save the original scale, and the content is scaled by inclusion; Cover: save the original scale, and scale the content in an overlay way
      },
      type: {
        type: String,
      }, 
      videoId: {
        type: String
      }
    },
    data () {
      return {
        isActive:true,
        originPlay: true,
        vplay: false
      }
    },
    mounted () {
      if (this.showPlay) {
        this.$refs.veo.addEventListener('pause', this.onPause)
        this.$refs.veo.addEventListener('playing', this.onPlaying)
      }
      if (this.autoplay) {
        this.vplay = true
        this.originPlay = false
      }
      // Customize the playback speed
      // this.$refs.veo.playbackRate = 2

      document.addEventListener("fullscreenchange", this.videoMove)
      /* Firefox */
      document.addEventListener("mozfullscreenchange", this.videoMove)
      /* Chrome, Safari 和 Opera */
      document.addEventListener("webkitfullscreenchange", this.videoMove)
      /* IE / Edge */
      document.addEventListener("msfullscreenchange", this.videoMove)

    },
    methods: {

      videoMove(e){
        if (!this.checkFull() && this.$refs.veo!=undefined && this.$refs.veo.id == e.srcElement.id) {
          this.isActive = true
          this.$refs.veo.pause()
        } else if(this.$refs.veo!=undefined && this.$refs.veo.id == e.srcElement.id){
          this.$refs.veo.play();
          this.isActive = false
        }
      },
      canplay(e){
        var myVideo = document.getElementsByTagName('video');
        if(myVideo.length>0){
          for(var i=0;i<myVideo.length;i++){
            if(!myVideo[i].paused && e.srcElement.id != myVideo[i].id){
              myVideo[i].pause();
            }
          }
        }
      },
      checkFull(){
        const isFullScreen = document.fullscreen || document.mozFullScreen || document.webkitIsFullScreen;
        return isFullScreen == undefined ? false : isFullScreen;
      },
      handleFullScreen(e) {
        this.canplay(e);
        this.launchFullscreen(e.target);
      },

      click() {
        if(this.type === 'video') {
          let params = {
            foreignId: this.videoId,
            clickType: '3',
            domainUrl: window.location.host,
            clickUserId: localStorage.getItem('userId'),
          };
          apis.addClick(params);
        }
      },
      // 进入全屏
      launchFullscreen(element) {
        if(!this.checkFull()){
          this.isActive = false
          this.click();
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
          } else if (element.oRequestFullscreen) {
            element.oRequestFullscreen();
          } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullScreen();
          } else if(element.webkitEnterFullscreen){
            element.webkitEnterFullscreen();
          } else {
            var docHtml = document.documentElement;
            var docBody = document.body;
            var videobox = element;
            var cssText = "width:100%;height:100%;";
            docHtml.style.cssText = cssText;
            docBody.style.cssText = cssText;
            videobox.style.cssText = cssText + ";" + "margin:0px;padding:0px;";
            document.IsFullScreen = true;
          }
        }
      },

      //退出全屏时暂停
      fullscreenchange(e) {
        if (!this.checkFull()) {
          this.isActive = true
          this.$refs.veo.pause()
        } else {
          this.isActive = false
          this.$refs.veo.play()
        }
      },

      onPlay () {
        // console.log('click')
        if (!this.autoplay) {
          this.vplay = true
          this.originPlay = false
          this.$refs.veo.play()
        } else {
          this.$refs.veo.pause()
        }
      },
      onPause () {
        this.vplay = false
        // console.log('pause')
        this.$once('hook:beforeDestroy', function () {
          removeEventListener('pause', this.onPause)
        })
      },
      onPlaying () {
        this.vplay = true
        // console.log('playing')
        // 自动清理自己，避免内存泄漏
        this.$once('hook:beforeDestroy', function () {
          removeEventListener('playing', this.onPlaying)
        })
      }
    }
  }
  </script>
  <style lang="less" scoped>
  .m-video {
    display: inline-block;
    position: relative;
    background: #000;
    cursor: pointer;
    height: 120px;
    border-radius: 0.16rem;
    &:hover {
      .u-play {
        opacity: 0.9;
      }
    }
    .u-play {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      fill: none;
      color: #FFF;
      pointer-events: none;
      opacity: 0.7;
      transition: opacity .3s;
      width: 1rem;
    }
    .hidden {
      display: none;
    }
    .c_video {
      border-radius: .16rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .n_video{
      border-radius: .16rem;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  </style>
