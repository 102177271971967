export const commonCountries = {
    "en": [
        { name: "Japan (+81)", code: "+81"},
        { name: "China Mainland (+86)", code: "+86" },
        { name: "Hong Kong SAR of China (+852)", code: "+852" },
        { name: "Singapore (+65)", code: "+65" },
        { name: "Malaysia (+60)", code: "+60" },
        { name: "South Korea (+82)", code: "+82" },
        { name: "United States (+1)", code: "+1" },
        { name: "Canada (+1)", code: "+1" },
        { name: "Australia (+61)", code: "+61" },
        { name: "Thailand (+66)", code: "+66" }
    ],
    "jp": [
        { name: "日本 (+81)", code: "+81"},
        { name: "中国本土 (+86)", code: "+86" },
        { name: "香港 (+852)", code: "+852" },
        { name: "シンガポール (+65)", code: "+65" },
        { name: "マレーシア (+60)", code: "+60" },
        { name: "韓国 (+82)", code: "+82" },
        { name: "アメリカ合衆国 (+1)", code: "+1" },
        { name: "カナダ (+1)", code: "+1" },
        { name: "オーストラリア (+61)", code: "+61" },
        { name: "タイ (+66)", code: "+66" }
    ],
    "cn": [
        { name: "日本 (+81)", code: "+81"},
        { name: "中国内地 (+86)", code: "+86" },
        { name: "中国香港 (+852)", code: "+852" },
        { name: "新加坡 (+65)", code: "+65" },
        { name: "马来西亚 (+60)", code: "+60" },
        { name: "韩国 (+82)", code: "+82" },
        { name: "美国 (+1)", code: "+1" },
        { name: "加拿大 (+1)", code: "+1" },
        { name: "澳大利亚 (+61)", code: "+61" },
        { name: "泰国 (+66)", code: "+66" }
    ],
    "hk": [
        { name: "日本 (+81)", code: "+81"},
        { name: "中國內地 (+86)", code: "+86" },
        { name: "中國香港 (+852)", code: "+852" },
        { name: "新加坡 (+65)", code: "+65" },
        { name: "馬來西亞 (+60)", code: "+60" },
        { name: "韓國 (+82)", code: "+82" },
        { name: "美國 (+1)", code: "+1" },
        { name: "加拿大 (+1)", code: "+1" },
        { name: "澳大利亞 (+61)", code: "+61" },
        { name: "泰國 (+66)", code: "+66" }
    ],
}