import Vue from 'vue';
import Router from 'vue-router';
import Login from "./pages/Login";
import Forget from "./pages/Login/forgetPwd";
import Code from "./pages/Login/loginCode";
import ResetPwd from "./pages/Login/resetPwd";
import Register from "./pages/Login/register";
import Search from "./pages/search";
import Result from "./pages/search/searchResult";
import Order from "./pages/order";
import Personal from "./pages/personal";

import OrderNumber from "./pages/order/orderNumber";
import OrderDetails from "./pages/order/orderDetails";
import PayResult from "./pages/order/payResult";
import LineCallBack from "./pages/line/callback";
import Contact from './pages/contacts/index';
import PayPage from "./pages/homePage/payPage"
import SearchClassify from "./pages/search/searchClassify";
import VideoList from "./pages/videoList";
import BaseTerms from "./pages/baseTerms";
import SpecificCommercialTransactionsActInformation from "./pages/specificCommercialTransactionsActInformation";
import NoticeList from "./pages/notice";
import NoticeDetail from "./pages/notice/detail";
import AreaList from "./pages/areaList";
import BasicInformation from "./pages/Login/basicInformation";
import Test from "./pages/test/test";
import searchFresh from "./pages/search/serachFresh";
import ResetHead from "./pages/Login/ResetHead";
import mapPosition from "@/pages/homePage/mapPosition";

Vue.use(Router);

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
    {
        path: '*',
        redirect: '/homePage'
    },
    {
        name: 'homePage',
        component: () => import('./pages/homePage/homePage'),
        meta: {
            title: 'GotoJapan MediaBank'
        }
    },
    {
        name: 'detailsPage',
        path: '/detailsPage',
        component: () => import('./pages/homePage/detailsPage'),
        meta: {
            title: 'Details'
        }
    },
    {
        name: 'myOrderDetails',
        component: () => import('./pages/homePage/myOrderDetails'),
        meta: {
            title: 'Order Details'
        }
    },
    {
        path: '/SpecificCommercialTransactionsActInformation',
        name: '/SpecificCommercialTransactionsActInformation',
        component: SpecificCommercialTransactionsActInformation,
        meta: {
            title: "SpecificCommercialTransactionsActInformation"
        }
    },
    {
        name: 'orderPaynow',
        component: () => import('./pages/homePage/orderPaynow'),
        meta: {
            title: 'Order Details'
        }
    },
    {
        path: '/login',
        name: '/login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/forget',
        name: '/forget',
        component: Forget,
        meta: {
            title: 'Forget'
        }
    },
    {
        path: '/code',
        name: '/code',
        component: Code,
        meta: {
            title: 'Code'
        }
    },
    {
        path: '/repwd',
        name: '/repwd',
        component: ResetPwd,
        meta: {
            title: 'ResetPwd'
        }
    },
    {
        path: '/register',
        name: '/register',
        component: Register,
        meta: {
            title: 'Register'
        }
    },
    {
        path: '/search',
        name: '/search',
        component: Search,
        meta: {
            title: 'Search'
        }
    },
    {
        path: '/result',
        name: '/result',
        component: Result,
        meta: {
            title: 'Search Result'
        }
    },
    {
        path: '/order',
        name: '/order',
        component: Order,
        meta: {
            title: 'Order'
        }
    },
    {
        path: '/Personal',
        name: '/Personal',
        component: Personal,
        meta: {
            title: 'Personal'
        }
    },
    {
        path: '/orderNum',
        name: '/orderNum',
        component: OrderNumber,
        meta: {
            title: 'orderNum'
        }
    },
    {
        path: '/orderXq',
        name: '/orderXq',
        component: OrderDetails,
        meta: {
            title: 'OrderDetails'
        }
    },
    {
        path: '/payResult',
        name: '/payResult',
        component: PayResult,
        meta: {
            title: 'PayResult'
        }
    },
    {
        path: '/linecallback',
        name: '/linecallback',
        component: LineCallBack,
        meta: {
            title: 'Line Login'
        }
    },
    {
        path: '/contacts',
        name: '/contacts',
        component: Contact,
        meta: {
            title: 'Contact',
        }
    },
    {
        path: '/searchClassify',
        name: '/SearchClassify',
        component: SearchClassify,
        meta: {
            title: 'SearchClassify'
        }
    },
    {
        path: '/payPage',
        name: '/PayPage',
        component: PayPage,
        meta: {
            title: 'PayPage'
        }
    },
    {
        path: '/videoList',
        name: '/videoList',
        component: VideoList,
        meta: {
            title: 'VideoList'
        }
    },
    {
        path: '/test',
        name: '/test',
        component: Test,
        meta: {
            // title: 'test',
            title: 'Go To Japan',
        }
    },
    {
        path: '/BaseTerms',
        name: '/BaseTerms',
        component: BaseTerms,
        meta: {
            title: "BaseTerms"
        }
    },
    {
        path: '/noticeList',
        name: '/noticeList',
        component: NoticeList,
        meta: {
            title: "NoticeList"
        }
    },
    {
        path: '/noticeDetail',
        name: '/noticeDetail',
        component: NoticeDetail,
        meta: {
            title: "NoticeDetail"
        }
    },
    {
        path: '/areaList',
        name: '/areaList',
        component: AreaList,
        meta: {
            title: "AreaList"
        }
    },
    {
        path: '/basicInformation',
        name: '/basicInformation',
        component: BasicInformation,
        meta: {
            title: "BasicInformation"
        }
    },
    {
        name: 'blockDetail',
        path: '/blockDetail',
        component: () => import('./pages/homePage/blockDetail'),
        meta: {
            title: 'BlockDetail'
        }
    },
    {
        path: '/searchFresh',
        name: '/searchFresh',
        component: searchFresh,
        meta: {
            title: 'searchFresh'
        }
    },
    {
        path: '/resetHead',
        name: '/resetHead',
        component: ResetHead,
        meta: {
            title: "ResetHead"
        }
    },
    {
        path: '/mapPosition',
        name: '/mapPosition',
        component: mapPosition,
        meta: {
            title: "mapPosition"
        }
    },
];

// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
    routes, mode: 'history',
});

router.beforeEach((to, from, next) => {
    if (from.fullPath !== '/') {
        localStorage.setItem("beforeRouter",from.fullPath);
    }
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export {
    router
};
