<template>
  <div class="districtDiv">
    <div class="flow-row space-between">
      <div class="distTitle1">{{ dataList[0].columnName }}</div>
    </div>
    <van-row gutter="10" class="image-box" v-if="nums == 2">
      <van-col v-for="item in dataList" span="12" style="height: 2.5rem;">
        <div class="imgBox">
          <img :src="item.columnImageUrl" class="img" @click="toDetail(tab, item)">
        </div>
      </van-col>
    </van-row>
    <van-row gutter="10" class="image-box" v-else-if="nums == 3">
      <van-col class="imgDiv" v-for="item in dataList" span="8" style="height: 1.8rem;">
        <div class="imgBox">
          <img :src="item.columnImageUrl" class="img" @click="toDetail(tab, item)">
          <div class="title">
            <div class="subtitle">{{ item.columnRefAdvName }}</div>
          </div>
        </div>
      </van-col>
    </van-row>
    <van-row gutter="10" class="image-box" v-else-if="nums == 4" style="margin-bottom: -0.2rem;">
      <van-col class="imgDiv" v-for="item in dataList" span="12" style="height: 2.8rem; margin-bottom: 0.2rem;">
        <div class="imgBox">
          <img :src="item.columnImageUrl" class="img" @click="toDetail(tab, item)">
          <div class="title">
            <div class="subtitle">{{ item.columnRefAdvName }}</div>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  props: {
    tab: {
      type: Number,
      default: 0
    },
    dataList: {
      type: Array,
    },
    nums: {
      type: Number,
      default: 0
    }
  },
  components: {
  },
  data() {
    return {}
  },
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
          .replace(reg, "")
          .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          );
      }
    },
  },
  methods: {
    toDetail(tab, item) {
      if (tab <= 5) {
        this.$router.push({
          path: "/detailsPage",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      } else {
        this.$router.push({
          path: "/orderXq",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      }
    },
  }
}
</script>

<style scoped lang="less">
.districtDiv {
  margin: 0 auto;
  text-align: start;

  .space-between {
    line-height: 1.2rem;
  }

  .distTitle1 {
    color: black;
    font-size: 0.37rem;
    font-weight: 600;
    margin-left: 0.3rem;
  }

  .image-box {
    padding: 0 0.3rem;
  }
}
.imgDiv {
    position: relative;
    display: flex;
    flex-direction: column;

    .imgBox {
        width: 100%;
        height: 100%;
        position: relative;

        .img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 0.16rem;
        }

        .title {
            position: absolute;
            bottom: 0;
            height: 0.6rem;
            // line-height: 0.3rem;
            // background: linear-gradient(rgba(0, 0, 0,0.3) ,rgba(0, 0, 0,1));
            background: linear-gradient(to top, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .7) 80%, rgba(0, 0, 0, .0) 100%);
            color: white;
            // font-size: 0.3rem;
            text-align: start;
            padding: 0.1rem 0.2rem;
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            min-width: 0;
            border-bottom-right-radius: 0.16rem;
            border-bottom-left-radius: 0.16rem;

            .subtitle {
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: center;
                font-size: 0.24rem;
            }

            .desc {
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
                text-overflow: ellipsis;
                overflow: hidden;
                max-height: 0.64rem;

                &:first-child {
                    font-size: 0.3rem;
                }

                &:last-child {
                    margin-top: 0.13rem;
                    font-size: 0.24rem;
                    line-height: 0.32rem;
                }
            }
        }
    }
}
</style>
