<template>
  <div>
    <Header @goBack="goBack"></Header>
    <Loginlogo></Loginlogo>
    <div class="basic">
      <h1 class="basic_title">{{ $t('app.enterBasicInfo') }}</h1>
      <van-form @submit="onSubmit">

          <p class="label">{{ $t('app.userName') }}</p>
          <van-cell-group inset style="margin-bottom: 2%">
            <van-field :rules="[{ required: true, message: $t('app.UserName_required') }]" name="value"
                       v-model="userName" placeholder="" focus />
          </van-cell-group>
          <p class="label">{{ $t('app.Email_address') }}</p>

          <van-cell-group inset style="margin-bottom: 2%">
            <van-field :rules="[{ required: true, message: $t('app.Email_required'), trigger: 'onBlur' }, {
              validator: (value) => {
              return /.+@.+\..+/.test(value);
              }, message: $t('app.Email_required_must'),
              trigger: 'onBlur',
            }]" name="value" v-model="email" placeholder="" />
          </van-cell-group>

          <p class="label">{{ $t('app.Address') }}</p>
          <van-cell-group inset style="margin-bottom: 2%">
            <van-field :rules="[{ required: true, message: $t('app.Address_required') }]" name="value"
                       v-model="address" placeholder="" focus />
          </van-cell-group>

          <div style="margin-top: 0.5rem;">
            <van-button round block type="info" native-type="submit">{{ $t('app.submit') }}</van-button>
          </div>
    </van-form>
    </div>
  </div>
</template>
<script>
import Header from '../../components/Login/Header.vue'
import Loginlogo from '../../components/Login/Loginlogo.vue';
import apis from "../../utils/apis";

let { updateUser } = apis;

export default {
  components: { Header, Loginlogo },
  data() {
    return {
      userName: localStorage.getItem('name') === 'null' ? '' : localStorage.getItem('name'),
      email: localStorage.getItem('email') === 'null' ? '' : localStorage.getItem('email'),
      address: '',
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    onSubmit() {
        let params = {
          userId: localStorage.getItem('userId'),
          userName: this.userName,
          email: this.email,
          address: this.address,
        }
        updateUser(params, (data) => {
          // console.log(data);
          if(data.code == 200) {
            Toast.success(data.msg);
            localStorage.setItem("isFirstLogin", "b")
            this.$router.back();
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
.basic {
  padding: 0 .5rem;
  .basic_title {
    text-align: left;
    margin: 0 0 .4rem 0;
    color: #606266;
  }
  .label {
    display: block;
    margin: 0.33rem 0 0.25rem 0;
    text-align: left;
    font-size: 0.30rem;
    font-weight: 540;
  }
  .van-button {
    background-color: #333;
    border: none;
    margin-bottom: .3rem;
  }
}
/deep/ .van-cell-group--inset {
  margin: 0;
}
/deep/ .van-cell {
  padding: 0;
}
/deep/.van-field__body {
  height: .8rem;
  border: 1px solid #E0E0E0;
  border-radius: .16rem;
  padding-left: .24rem;
}
</style>