<template>
  <div>
    <nav-bar :ifBlack="true"/>
    <div class="distDiv">
        <div class="header">
            <div @click="goback"><van-icon name="arrow-left" /></div>
            <div class="dictTitle">{{ $t('app.all_area') }}</div>
        </div>
        <van-row type="flex" justify="space-between" style="width: 100%">
          <van-col :span="12" v-for="(item, index) in areaList" :key="index" class="distItem">
            <div class="imgDiv" @click="toDetail(item)">
              <img :src="item.img" class="img" alt="" />
              <div class="title">
                <div class="subtitle">{{ item.areaName }}</div>
              </div>
            </div>
          </van-col>
        </van-row>
    </div>
  </div>
</template>
<script>
import apis from "../../utils/apis";
import NavBar from "../../components/basicComponents/navBar.vue";

export default {
  components: { NavBar },
  data() {
    return {
      areaList: [],
    }
  },
  created() {
    this.getAreaList();
  },
  methods: {
    getAreaList() {
      apis.getAreaList({}, data => {
        this.areaList = data.data;
      })
    },
    goback() {
      this.$router.go(-1);
    },
    toDetail(item) {
      this.$router.push({
        path: "detailsPage",
        query: {
          type: item.type,
          id: item.id,
        },
      });
    },

  }
}
</script>
<style lang="less" scoped>
.distDiv {
    width: 96%;
    margin-left: 2%;
    .header {
      margin-top: 1.4rem; 
      margin-bottom: .4rem;
      margin-left: 2%;
      display: flex;
      .dictTitle {
        display: flex;
        align-items: center;
        font-size: 0.37rem;
        font-weight: 600;
        text-align: left;
      }
    }
    .van-col--12 {
      margin-bottom: .2rem;
    }
}

::v-deep .van-icon {
  font-size: .45rem;
  padding-top: .04rem;
}

.distItem {
  .imgDiv {
    position: relative;
    width: 90%;
    margin-left: 5%;
    .title {
      position: absolute;
      bottom: 0;
      // background:linear-gradient(to top,rgba(0, 0, 0,.6) 0,rgba(0, 0, 0,.7) 80%,rgba(0, 0, 0,.0) 100%);
      background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 34.41%, rgba(0, 0, 0, 0) 100%);
      // background: red;
      width: 96%;
      padding: 10% 2%;
      color: white;
      border-bottom-right-radius: .16rem;
      border-bottom-left-radius: .16rem;
      .subtitle{
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          white-space: normal;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: .3rem;
        }
    }
      .img {
        display: flex;
        width: 100%;
        height: 2rem;
        border-radius: 0.16rem;
        object-fit: cover;
      }
    }
  }
</style>