<template>
    <div>
        <Header @goBack="goBack"></Header>
        <Loginlogo></Loginlogo>
        <div class="login">
            <h1 class="login_title">{{ $t('app.Create_Account') }}</h1>
            <div class="login_input">
                <van-form @submit="onSubmit">
                    <p class="label">{{ $t('app.nike_name') }}</p>
                    <van-cell-group inset style="margin-bottom: 2%">
                        <van-field v-model="username" name="value" placeholder=""
                            :rules="[{ required: true, message: $t('app.Please_name') }]" format-trigger="onBlur" />
                    </van-cell-group>
                    <p class="label">{{ $t('app.Email_address') }}</p>

                    <van-cell-group inset style="margin-bottom: 2%">
                        <van-field :rules="[{ required: true, message: $t('app.Email_required'), trigger: 'onBlur' }, {
                            validator: (value) => {
                                return /.+@.+\..+/.test(value);
                            }, message: $t('app.Email_required_must'),
                            trigger: 'onBlur',
                        }]" name="value" v-model="mail" placeholder="" />
                    </van-cell-group>

                    <p class="label">{{ $t('app.create_pwd') }}</p>
                    <van-cell-group inset style="margin-bottom: 2%">
                        <van-field v-model="password" type="password" name="password" placeholder=""
                            :rules="[{ required: true, message: $t('app.enter_pwd') }]" />
                    </van-cell-group>
                    <div style="margin: 32px 0;">
                        <van-button round block type="info" native-type="submit">{{ $t('app.register') }}
                        </van-button>
                    </div>
                </van-form>
            </div>
            <divider :type="1"></divider>
            <div class="connect">
                <p class="medin_ban" @click="toLogin">{{ $t('app.joininMEDIN_BANK') }}</p>
                <p class="cookie_state">{{ $t('app.cookie_state') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import apis from '../../utils/apis'
import Vue from 'vue';
import { NavBar, Icon, Form, Field, Button, Divider, Toast } from 'vant';
import Header from '../../components/Login/Header.vue'
import divider from '../../components/Login/divider.vue';
import Loginlogo from '../../components/Login/Loginlogo.vue';
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Toast);
let { regist } = apis
export default {
    components: {
        Header,
        divider,
        Loginlogo
    },
    data() {
        return {
            value1: localStorage.getItem('language') == null ? 'en' : localStorage.getItem('language'),
            option1: [
                { text: this.$t('app.en'), value: 'en' },
                { text: this.$t('app.jp'), value: 'jp' },
                { text: this.$t('app.cn'), value: 'cn' },
                { text: this.$t('app.hk'), value: 'hk' },
            ],
            password: '',
            mail: "",
            username: ""
        };
    },
    methods: {

      toLogin(){
        this.$router.replace('/login')
      },
        onSubmit() {
            let params = {
                username: this.username,
                mail: this.mail,
                pwd: this.password
            }
            regist({ ...params }, data => {
              this.$router.replace('/login')
            })
        },
        goBack() {
            this.$router.back()
        }
    },
};
</script>

<style scoped lang="less">
/deep/.van-cell-group--inset {
    margin: 0;
}

/deep/.van-cell {
    padding: 0;
}

/deep/.van-field__body {
    height: .8rem;
    border: .04rem solid #E0E0E0;
    border-radius: .1rem;
    padding-left: .24rem;
}

.label {
    text-align: left;
}

.van-button {
    background-color: #333;
    border: none;
}

.login {
    padding: 0 .5rem;

    .login_title {
        text-align: left;
        margin: 0 0 .7rem 0;
    }

    .login_describe {
        text-align: left;
        display: block;
        font-weight: bold;
        font-size: 12px;
        margin: 0 0 .6rem 0;
    }
}

.connect {
    .medin_ban {
        margin: 0;
        color: #000000;
        font-size: .34rem;
        font-weight: 540;
    }

    .cookie_state {
        font-size: 0.28rem;
        // font-weight: bold;
    }
}
</style>