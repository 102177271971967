<template>
  <div>
    <header class="header_search">
      <nav-bar :ifBlack="true"></nav-bar>
    </header>
    <div class="list">
      <van-tabs :swipeable="false" v-model="queryParams.readStatus" @change="onNav">
        <van-tab :title="$t('app.All')" name="3">
          <van-list v-if="total > 0">
            <van-cell>
              <div class="line_height"></div>
              <div class="nav_list">
                <div class="list_title">
                  <span>{{ $t('app.allNotice', {total: total} ) }}</span>
                </div>
                <div
                  class="list_nav"
                  v-for="(item, index) in searchList"
                  :key="index"
                  @click="goDetails(item)"
                >
                  <van-swipe-cell>
                    <div class="head">
                      <div class="list_describe">
                        <van-badge v-if="item.readStatus === 0" dot />
                        <span v-bind:class="{ 'unread_msg': item.readStatus === 0, 'read_msg':item.readStatus != 0,'_h3':true }">{{ item.title }}</span>
                        <p class="text-title">{{ item.sendTime }}</p>
                        <span v-bind:class="{ 'unread_msg': item.readStatus === 0, 'read_msg': item.readStatus != 0 ,'list_view':true}">{{ item.content | filtersText }} </span>
                      </div>
                    </div>
                    <template #right>
                      <van-button square type="danger" :text="$t('delete')" @click="deleteMsg(item)" />
                    </template>
                  </van-swipe-cell>
                </div>
                <div class="footer">
                  <van-pagination
                    v-model="queryParams.pageNum"
                    :total-items="total"
                    :page-count="pageCount"
                    @change="pagechange"
                    :items-per-page="queryParams.pageSize"
                    :show-page-size="5"
                  >
                    <template #prev-text>
                      <van-icon name="arrow-left" />
                    </template>
                    <template #next-text>
                      <van-icon name="arrow" />
                    </template>
                  </van-pagination>
                </div>
              </div>
            </van-cell>
          </van-list>
          <div v-else>
            <van-empty :description="$t('app.No_data')" />
          </div>
        </van-tab>
        <van-tab :title="$t('app.unRead')" name="0">
          <van-list v-if="total > 0">
            <van-cell>
              <div class="line_height"></div>
              <div class="nav_list">
                <div class="list_title">
                  {{ $t('app.allUnRead', {total: total} ) }}
                </div>
                <div
                  class="list_nav"
                  v-for="(item, index) in searchList"
                  :key="index"
                  @click="goDetails(item)"
                >
                  <van-swipe-cell>
                    <div class="head">
                      <div class="list_describe">
                        <van-badge v-if="item.readStatus === 0" dot />
                        <h3>{{ item.title }}</h3>
                        <p class="text-title">{{ item.sendTime }}</p>
                        <span class="list_view">{{ item.content | filtersText }} </span>
                      </div>
                    </div>
                    <template #right>
                      <van-button square type="danger" :text="$t('delete')" @click="deleteMsg(item)" />
                    </template>
                  </van-swipe-cell>
                </div>
                <div class="footer">
                  <van-pagination
                    v-model="queryParams.pageNum"
                    :total-items="total"
                    :page-count="pageCount"
                    @change="pagechange"
                    :items-per-page="queryParams.pageSize"
                    :show-page-size="5"
                  >
                    <template #prev-text>
                      <van-icon name="arrow-left" />
                    </template>
                    <template #next-text>
                      <van-icon name="arrow" />
                    </template>
                  </van-pagination>
                </div>
              </div>
            </van-cell>
          </van-list>
          <div v-else>
            <van-empty :description="$t('app.No_data')" />
          </div>
        </van-tab>
        <van-tab :title="$t('app.read')" name="1">
          <van-list v-if="total > 0">
            <van-cell>
              <div class="line_height"></div>
              <div class="nav_list">
                <div class="list_title">
                  <span>{{ $t('app.allRead', {total: total} ) }}</span>
                </div>
                <div
                  class="list_nav"
                  v-for="(item, index) in searchList"
                  :key="index"
                  @click="goDetails(item)"
                >
                  <van-swipe-cell>
                    <div class="head">
                      <div class="list_describe">
                        <h3>{{ item.title }}</h3>
                        <p class="text-title">{{ item.sendTime }}</p>
                        <span class="list_view">{{ item.content | filtersText }} </span>
                      </div>
                    </div>
                    <template #right>
                      <van-button square type="danger" :text="$t('delete')" @click="deleteMsg(item)" />
                    </template>
                  </van-swipe-cell>
                </div>
                <div class="footer">
                  <van-pagination
                    v-model="queryParams.pageNum"
                    :total-items="total"
                    :page-count="pageCount"
                    @change="pagechange"
                    :items-per-page="queryParams.pageSize"
                    :show-page-size="5"
                  >
                    <template #prev-text>
                      <van-icon name="arrow-left" />
                    </template>
                    <template #next-text>
                      <van-icon name="arrow" />
                    </template>
                  </van-pagination>
                </div>
              </div>
            </van-cell>
          </van-list>
          <div v-else>
            <van-empty :description="$t('app.No_data')" />
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import NavBar from "../../components/basicComponents/navBar.vue";
import apis from "../../utils/apis";
import Vue from "vue";
import {
  Tab,
  Tabs,
  List,
  Cell,
  Empty,
  Badge,
  SwipeCell
} from "vant";
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(List);
Vue.use(Cell);
Vue.use(Badge);
Vue.use(Empty);
Vue.use(SwipeCell);

export default {
  components: { NavBar },
  data() {
    return {
      total: 0,
      showMoreFlg: false,
      detailVisible: false,
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        readStatus: 3,
        languageType: null,
        userId: null,
      },
      searchList: [],
      pageCount: 0,
    }
  },

  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
          .replace(reg, "")
          .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          );
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },

  created() {
    this.getList()
  },
  
  methods: {
    onNav(name, title) {
      this.queryParams.pageNum = 1;
      this.getList();
    },

    getList() {
      let types = {
      "en": 2,
      "cn": 0,
      "jp": 1,
      "hk": 3
      }
      this.queryParams.languageType = localStorage.getItem("language") == null ? 2 : types[localStorage.getItem("language")];
      this.queryParams.userId = localStorage.getItem("userId");
    //   this.queryParams.userId = '1643875911356440576';
      apis.getMessagetList(this.queryParams, (data) => {
        this.searchList = data.data.list;
        this.total = data.data.total;
        this.pageCount =  Math.ceil(this.total/this.queryParams.pageSize)
      });
    },

    goDetails(item) {
      if(item.readStatus === 0) {
        apis.updateMsg({readStatus: 1, id: item.id}, (data) => {
          //console.log(data);
        });
      }
      this.$router.push({
        path: '/noticeDetail',
        query: item,
      })
    },

    pagechange(page) {
        //console.log(page);
        this.queryParams.pageNum = page;
        this.getList();
    },

    deleteMsg(item) {
      apis.deleteMsg({id: item.id}, (data) => {
        if(data.code === 200) {
            this.detailVisible = false;
            this.getList();
        }
      })
    },

  }
}
</script>
<style scoped lang="less">
@import url("../../assets/css/notice.less");
</style>