import {Locale} from 'vant' 
import enUS from 'vant/lib/locale/lang/en-US'; 
import zhCN from 'vant/lib/locale/lang/zh-CN'; 
import zhHK from 'vant/lib/locale/lang/zh-HK';
import jp from 'vant/lib/locale/lang/ja-JP';

// vantLocal.js
export default function locales(a) {
  if(a == 'en'){
    Locale.use('en',enUS) 
  }
  if(a == 'cn'){
    Locale.use('cn',zhCN) 
  } 
  if(a=='hk'){ 
    Locale.use('hk',zhHK) 
  }
  if(a=='jp'){ 
    Locale.use('jp',jp) 
  }
}