<template>
    <div>
        <div class="connect">
            <p>{{
                        $t('app.cookie_state')
                    }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            value1: localStorage.getItem('language') == null ? 'en' : localStorage.getItem('language'),
            option1: [
                { text: this.$t('app.en'), value: 'en' },
                { text: this.$t('app.jp'), value: 'jp' },
                { text: this.$t('app.cn'), value: 'cn' },
                { text: this.$t('app.hk'), value: 'hk' },
            ],
        }
    }
}
</script>

<style scoped>
.connect {
    padding: 0 24px;
}

.connect p {
    display: block;
    font-size: .28rem;
    margin: 0;
}
</style>