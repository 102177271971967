<template>
  <div id="app">
    <router-view />
    <Footer :is-lang-show="showLang" v-if="showFooter"></Footer>
  </div>
</template>
<script>
import Footer from "../src/components/footer.vue";
import { pcHostUrl,hostUrl } from "./utils/apis";
import apis from "./utils/apis";

let { getUserInfo } = apis;

export default {
  components: { Footer },
  data() {
    return {
      showLang:true,
      showFooter:false,
    };
  },
  watch: {
    '$route' (to, from) { //if current page is homepage show change language select.else hide
        // this.showLang=("homePage".lastIndexOf(to.name)>-1)
        this.showLang = to.path != '/contacts' && to.path != '/orderPaynow';

        if(to.path !== '/payPage' && to.path !== '/payResult') {
          this.showFooter = true;
        } else {
          this.showFooter = false;
        }

        if(!(this.$route.path.indexOf('login') > -1)) {
          localStorage.setItem("lastPath", hostUrl+this.$route.fullPath);
        }
    }
  },
  beforeRouteEnter(to, from, next){
	  // console.log(from);
  },
  beforeCreate() {
    // var userAgentInfo = navigator.userAgent
    // var Agents = new Array(   'Android',   'iPhone',   'SymbianOS',   'Windows Phone',   'iPad',   'iPod'  )
    // var flag = true
    // for (var v = 0; v < Agents.length; v++) {
    //   if (userAgentInfo.indexOf(Agents[v]) > 0) {
    //     flag = false
    //     break
    //   }
    // }
    // if(flag){
    //   // console.log("this is pc")
    //   window.location.href = pcHostUrl;
    // }else{
    //   // console.log("this is not pc")
    // }
  },
  created() {
    if (localStorage.getItem('userId')) {
      let params = {
        userId: localStorage.getItem('userId'),
      };
      getUserInfo({ ...params }, (response) => {
        localStorage.setItem("avatar", response.data.avatar);
        localStorage.setItem("name", response.data.userName);
      });
    }
    this.showLang = this.$route.path != '/contacts' && this.$route.path != '/orderPaynow';
    if(this.isPc()){
      // console.log("this is pc")
      window.location.href = pcHostUrl;
    }else{
      // console.log("this is not pc")
    }
    if(this.$route.path !== '/payPage' && this.$route.path !== '/payResult' && this.$route.path !== '/linecallback') {
        this.showFooter = true;
    } else {
      this.showFooter = false;
    }

    if(localStorage.getItem("isFirstLogin") != null && localStorage.getItem("isFirstLogin") != undefined && localStorage.getItem("isFirstLogin").indexOf('a')>-1) {
      // this.$router.push('/basicInformation')
    }
  },
  methods:{
    isPc() {
      var userAgentInfo = navigator.userAgent
      var Agents = new Array(   'Android',   'iPhone',   'SymbianOS',   'Windows Phone',   'iPad',   'iPod'  )
      var flag = true
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false
          break
        }
      }
      return flag
    },
  }
};

</script>

<style>
body {
  margin: 0;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
html {
  font-size: 0.32rem;
}

#app {
  font-size: medium;
  font-family: "Trip Sans VF", "Trip Sans", Arial, sans-serif;
  /* font-family: 'Open Sans','Segoe UI',Arial,Helvetica,sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  width: 100%;
  font-size: 0.32rem;
  letter-spacing: 0rem;
}
::-webkit-scrollbar {
  display: none;
}
</style>
