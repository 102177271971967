<template>
  <div>
    <header class="header_search">
      <nav-bar :ifBlack="true"></nav-bar>
    </header>
    <div class="search">
      <van-search shape="round" v-model="key" @search="onSearch" />
      <div @click="areaShow = true">
        <span style="color: rgb(82 82 82)">{{ $t('app.filter') }}</span>
        <van-icon name="filter-o" />
      </div>
    </div>
    <div class="list">
      <van-tabs :swipeable="false" v-model="activeName" @change="onNav" ref="tabResize">
        <van-tab :title="$t('app.All')" name="a">
          <van-list @load="onLoad" v-if="total > 0">
            <van-cell>
              <div class="line_height"></div>
              <div class="nav_list">
                <div class="list_title">
                  {{ key ? (collectFlag == 1 ? $t("app.collectResult", { key }) : $t("app.topResult", { key })) : (collectFlag == 1 ? $t("app.collectResult1", { key }) : $t("app.topResult1")) }}
                </div>
                <div
                    class="list_nav"
                    v-for="(item, index) in searchList"
                    :key="index"
                >
                  <div class="head">
                    <div class="portrait" @click="goDetails(item)">
                      <img :src="item?.img ? item.img.split(',')[0] : NoPicture" alt="" srcset="" />
                      <span class="icon" v-if="item.type == 1">
                        <img
                            src="../../assets/img/area2.png"
                            alt=""
                            srcset=""
                        />
                      </span>
                      <span class="icon" v-if="item.type == 2">
                        <img
                            src="../../assets/img/jingdian2.png"
                            alt=""
                            srcset=""
                        />
                      </span>
                      <span class="icon" v-if="item.type == 4">
                        <img
                            src="../../assets/img/gonglue2.png"
                            alt=""
                            srcset=""
                        />
                      </span>
                      <span class="icon" v-if="item.type == 5">
                        <img
                            src="../../assets/img/shop2.png"
                            alt=""
                            srcset=""
                        />
                      </span>
                      <span class="icon" v-if="item.type == 6">
                        <img
                            src="../../assets/img/piao2.png"
                            alt=""
                            srcset=""
                        />
                      </span>
                    </div>
                    <div class="list_describe">
                      <h3 @click="goDetails(item)">{{ item.name }}</h3>
                      <div @click="goDetails(item)" class="text_tag" v-if="item.tag && item.tag.length">
                        <van-tag
                            v-for="(item1, index1) in item.tag"
                            :key="index1"
                            plain
                            type="primary"
                        >{{ item1 }}</van-tag
                        >
                      </div>

                      <div class="text-title" @click="goDetails(item)">
                        <div v-html="$options.filters.filtersText(item.desc)"></div>
                      </div>
                      <div class="viewa-row">
                        <p class="list_view">
                          {{ item.reviews || 0 }} {{ $t("app.reviews") }}
                        </p>
                        <collect :favorite="item.favorite" class="collect_right" @getTabList="getTabList"></collect>
                      </div>

                      <div @click="goDetails(item)" v-if="item.extra && item.type == '6'">
                        <p class="list_form" v-if="Number(item.extra.price) == 0">
                          {{ $t('app.noInventory')  }}
                        </p>
                        <p class="list_form" v-else>
                          {{ $t("app.money") }} {{ priceFormat(item.extra.price) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="footer">
                  <van-pagination
                      v-model="currentPage"
                      :total-items="total"
                      :page-count="pageNum"
                      @change="pagechange"
                      :items-per-page="pageSize"
                      force-ellipses
                      :show-page-size="5"
                  >
                    <template #prev-text>
                      <van-icon name="arrow-left" />
                    </template>
                    <template #next-text>
                      <van-icon name="arrow" />
                    </template>
                  </van-pagination>
                </div>
              </div>
            </van-cell>
          </van-list>
          <div v-else>
            <van-empty :description="$t('app.No_data')" />
          </div>
        </van-tab>
        <van-tab :title="$t('app.introduction')" name="b">
          <div class="line_height"></div>
          <div class="backgroundColor">
            <carousel :tab="activeIndex" v-if="iCShowFlg && this.collectFlag !='1'" :dataList="carouselList"></carousel>
            <vertical-layout :tab="activeIndex" v-if="iVLShowFlg && this.collectFlag !='1'" :dataList="popIntroductionList"></vertical-layout>
            <horizontal-layout :tab="activeIndex" :nums="3" v-if="iCCShowFlg && this.collectFlag !='1'" :dataList="guessLikeList"></horizontal-layout>
            <slide-card :tab="activeIndex" v-if="iHLShowFlg && this.collectFlag !='1'" :dataList="plansGoList"></slide-card>
            <display-list v-if="total > 0" :collectFlag="collectFlag" :tab="activeIndex" :showFlg="displayShowFlg" :dataList="searchList" :show-more-flg="showMoreFlg" @showChange="showChange" @getTabList="getTabList" @loadMore="loadMore"></display-list>
            <div v-else>
              <van-empty :description="$t('app.No_data')" />
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('app.area')" name="c">
          <div class="line_height"></div>
          <div class="backgroundColor">
            <carousel :tab="activeIndex" v-if="aCShowFlg && this.collectFlag !='1'" :dataList="carouselList"></carousel>
            <carousel-card ref="test2" :tab="activeIndex" v-if="aVLShowFlg && this.collectFlag !='1'" :dataList="popAreaList" :ticket-flg="false"></carousel-card>
            <horizontal-layout :tab="activeIndex" :nums="3" v-if="aHL3ShowFlg && this.collectFlag !='1'" :dataList="guessLikeList"></horizontal-layout>
            <slide-card :tab="activeIndex" v-if="aHL4ShowFlg && this.collectFlag !='1'" :dataList="plansGoList"></slide-card>
            <display-list v-if="total > 0" :collectFlag="collectFlag" :tab="activeIndex" :showFlg="displayShowFlg" :dataList="searchList" :show-more-flg="showMoreFlg" @showChange="showChange" @getTabList="getTabList" @loadMore="loadMore"></display-list>
            <div v-else>
              <van-empty :description="$t('app.No_data')" />
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('app.scenicSpot')" name="d">
          <div class="line_height"></div>
          <div class="backgroundColor">
            <carousel :tab="activeIndex" v-if="cCShowFlg && this.collectFlag !='1'" :dataList="carouselList"></carousel>
            <vertical-layout :tab="activeIndex" v-if="cCCShowFlg && this.collectFlag !='1'" :dataList="popScenicSpotList"></vertical-layout>
            <horizontal-layout :tab="activeIndex" :nums="3" v-if="cHL3ShowFlg && this.collectFlag !='1'" :dataList="guessLikeList"></horizontal-layout>
            <horizontal-layout :tab="activeIndex" :nums="4" v-if="cHL4ShowFlg && this.collectFlag !='1'" :dataList="plansGoList"></horizontal-layout>
            <display-list v-if="total > 0" :collectFlag="collectFlag" :tab="activeIndex" :showFlg="displayShowFlg" :dataList="searchList" :show-more-flg="showMoreFlg" @showChange="showChange" @getTabList="getTabList" @loadMore="loadMore"></display-list>
            <div v-else>
              <van-empty :description="$t('app.No_data')" />
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('app.shop')" name="e">
          <div class="line_height"></div>
          <div class="backgroundColor">
            <van-cell>
              <div class="nav_list" style="margin-bottom: 0;">
                <div class="list_map">
                  <GmapMap
                          :center="{lat: addressLat, lng: addressLng}"
                          :zoom="7"
                          map-type-id="terrain"
                          style="width: 100%; height: 100%"
                  >
                    <gmapMarker
                            v-for="(item, index) in merchantList"
                            :key="index"
                            :position="{lat: item.addressLat, lng: item.addressLng}"
                            :clickable="true"
                            :options="item.markerOptions"
                            @click="openInfoWindow(index)">
                      <GmapInfoWindow
                              :options="item.infoWindowOptions"
                              :opened="item.infoWindowOptions.openFlg">
                        <div @click="goMerchantDetail(index)">
                          <img style="width: 230px;height: 115px" :src="item.infoWindowOptions.banner">
                          <div class="map-merchant-name text-left">{{item.infoWindowOptions.merchantName}}</div>
                          <div class="map-merchant-tel text-left" v-if="item.infoWindowOptions.phone">{{$t('app.tel')}}：{{item.infoWindowOptions.phone}}</div>
                          <div class="map-merchant-tel text-left">{{$t('app.Address')}}：{{item.address}}</div>
                          <!-- <div class="map-business-hours" v-for="(week, weekIndex) in item.infoWindowOptions.weekList">
                            <span v-if="week.isOpen == 0">
                              {{week.startDay}}  {{week.endDay ? '~' + week.endDay : ''}}：
                              {{$t('app.merchant_closed')}}
                            </span>
                            <span v-else>
                              {{week.startDay}}  {{week.endDay ? '~' + week.endDay : ''}}：
                              {{week.startTime}}-{{week.closingTime}}
                             </span>
                          </div> -->
                        </div>
                      </GmapInfoWindow>
                    </gmapMarker>
                  </GmapMap>
                </div>
              </div>
            </van-cell>
            <slide-card :tab="activeIndex" v-if="sVLShowFlg && this.collectFlag !='1'" :dataList="popShopList"></slide-card>
            <horizontal-layout :tab="activeIndex" :nums="3" v-if="sHL3ShowFlg && this.collectFlag !='1'" :dataList="guessLikeList"></horizontal-layout>
            <horizontal-layout :tab="activeIndex" :nums="4" v-if="sHL2ShowFlg && this.collectFlag !='1'" :dataList="plansGoList"></horizontal-layout>
            <display-list v-if="total > 0" :collectFlag="collectFlag" :tab="activeIndex" :showFlg="displayShowFlg" :dataList="searchList" :show-more-flg="showMoreFlg" @showChange="showChange" @getTabList="getTabList" @loadMore="loadMore"></display-list>
            <div v-else>
              <van-empty :description="$t('app.No_data')" />
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('app.ticket')" name="f">
          <div class="line_height"></div>
          <div class="backgroundColor">
            <carousel :tab="activeIndex" v-if="tCShowFlg && this.collectFlag !='1'" :dataList="carouselList"></carousel>
            <vertical-layout :tab="activeIndex" v-if="tCCShowFlg && this.collectFlag !='1'" :dataList="popTicketList"></vertical-layout>
            <carousel-card ref="test1" :tab="activeIndex" :nums="3" v-if="tHL3ShowFlg && this.collectFlag !='1'" :dataList="guessLikeList" :ticket-flg="true"></carousel-card>
            <slide-card :tab="activeIndex" v-if="tHL2ShowFlg && this.collectFlag !='1'" :dataList="plansGoList"></slide-card>
            <display-list v-if="total > 0" :collectFlag="collectFlag" :tab="activeIndex" :showFlg="displayShowFlg" :dataList="searchList" :show-more-flg="showMoreFlg" @showChange="showChange" @getTabList="getTabList" @loadMore="loadMore"></display-list>
            <div v-else>
              <van-empty :description="$t('app.No_data')" />
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>

    <van-popup
        v-model="areaShow"
        position="bottom"
        :style="{ height: '40%' }"
        round
    >
      <van-picker
          show-toolbar
          ref="pickerDialog"
          :columns="columns"
          :confirm-button-text="$t('app.search')"
          :cancel-button-text="$t('app.clear')"
          @confirm="onConfirm"
          @cancel="onCancel"
          @change="onChange"
      />
    </van-popup>
  </div>
</template>

<script>
import apis from "../../utils/apis";
import Vue from "vue";
import {
  Popup,
  Search,
  Tab,
  Tabs,
  Pagination,
  List,
  Cell,
  CellGroup,
  Collapse,
  CollapseItem,
  Empty,
  Checkbox,
  CheckboxGroup,
  Picker,
  Tag,
} from "vant";
import NavBar from "../../components/basicComponents/navBar.vue";
import NoPicture from "../../assets/img/no_picture.png";
import * as VueGoogleMaps from "vue2-google-maps";
import Collect from "../../components/collect"
import {isNotEmpty} from "@/utils/priceFormat";
import carousel from "./components/carousel";
import verticalLayout from "./components/verticalLayout";
import horizontalLayout from "./components/horizontalLayout";
import carouselCard from "./components/carouselCard";
import displayList from "./components/displayList";
import SlideCard from "./components/slideCard";

let maplang = window.localStorage.getItem('language') || 'ja';
if (maplang == 'jp') {
  maplang = 'ja';
}
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAMvjJZ_HsByGEybbVbPNhsMHTJmnzaHe4',
    libraries: 'places',
    language: maplang,
  }
});

Vue.use(Popup);
Vue.use(Search);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Pagination);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Empty);
Vue.use(Picker);
Vue.use(Tag);
let { SearchAll, getAreaList, getMerchantMarkList, getColumnDetail } = apis;
export default {
  components: {
    NavBar,
    Collect,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    carousel,
    verticalLayout,
    horizontalLayout,
    carouselCard,
    displayList,
    SlideCard,
  },
  data() {
    return {
      collectFlag:'',
      favorite:[],
      NoPicture: NoPicture,
      shareArr:[
        "1000+",
        "2000+",
        "3000+",
        "4000+",
        "5000+",
        "6000+",
        "7000+",
        "8000+",
        "9000+",
      ],
      languageTitle: this.$t("app.language"),
      languageType:
          localStorage.getItem("language") == null
              ? "en"
              : localStorage.getItem("language"),
      option1: [
        { text: this.$t("app.en"), value: "en" },
        { text: this.$t("app.jp"), value: "jp" },
        { text: this.$t("app.cn"), value: "cn" },
        { text: this.$t("app.hk"), value: "hk" },
      ],
      show: false,
      activeName: "d",
      currentPage: 1,
      pageSize: 20,
      pageNum: 1,
      activeIndex: 0,
      loading: false,
      finished: false,
      key: "",
      searchList: [],
      total: 0,
      size: "",
      pages: 1,
      viewa: "",
      showMoreFlg: true,
      areaShow: false,
      areaList: [],
      columns: [],
      areaId: '',
      addressLat: 35.89374451638285,
      addressLng: 139.76326772587228,
      merchantList: [],
      displayShowFlg: true,
      carouselList: [],
      popIntroductionList: [],
      popAreaList: [],
      popScenicSpotList: [],
      popShopList: [],
      popTicketList: [],
      guessLikeList: [],
      plansGoList: [],
      // 组件显示
      iCShowFlg: false,
      iVLShowFlg: false,
      iCCShowFlg: false,
      iHLShowFlg: false,
      aCShowFlg: false,
      aVLShowFlg: false,
      aHL3ShowFlg: false,
      aHL4ShowFlg: false,
      cCShowFlg: false,
      cCCShowFlg: false,
      cHL3ShowFlg: false,
      cHL4ShowFlg: false,
      sVLShowFlg: false,
      sHL3ShowFlg: false,
      sHL2ShowFlg: false,
      tCShowFlg: false,
      tCCShowFlg: false,
      tHL3ShowFlg: false,
      tHL2ShowFlg: false,
    };
  },
  watch: {
    $route(to, from) {
      window.location.reload(); //监测到路由发生跳转时刷新一次页面
    },
  },
  created() {
    this.activeName = this.$route.query.name;
    this.activeIndex = isNotEmpty(this.$route.query.index)?Number(this.$route.query.index):"";
    this.key = this.$route.query.searchKey;
    this.collectFlag = this.$route.query.collectFlag;
    this.getTabList();
    this.getAreaList();
    this.getMerchantMarkList();
    if(this.activeIndex > 0) {
      this.getColumnDetail();
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.tabResize.resize();
    }, 300)
    this.randomNumber();
  },
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        val = val.replaceAll("</p>", "/p/");
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
            .replace(reg, "")
            .replace(
                /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
                function (all, t) {
                  return arrEntities[t];
                }
            ).replaceAll("/p/", "<br>");
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },
  methods: {
    onCancel() {
      this.areaShow = false;
      this.columns[0].defaultIndex = 0;
      this.areaId = '';
      this.getTabList();
    },
    onConfirm(val, index) {
      this.columns[0].defaultIndex = index[0]
      if(index[0] === 0) {
        this.areaId = '';
        this.getTabList();
      } else if(index[0] === this.columns.values.length - 1 ) {
        this.areaId = '-1';
        this.getTabList();
      } else {
        this.areaId = this.areaList.find(v => v.areaName === val[0]).id;
        this.getTabList();
      }
      this.showChange('1');
      this.areaShow = false;
    },
    onChange() {},
    getAreaList() {
      getAreaList({}, (res) => {
        this.areaList = res.data;
        const values = this.areaList.map(item => item.areaName);
        values.unshift(this.$t('app.all_area'));
        values.push(this.$t('app.other_area'));
        this.columns = [{values: values, defaultIndex: 0}]
      })
    },
    onShow() {
      this.show = true;
    },
    randomNumber() {
      let shareArr = [
        "1000+",
        "2000+",
        "3000+",
        "4000+",
        "5000+",
        "6000+",
        "7000+",
        "8000+",
        "9000+",
      ];
      var str = ""; //重新调用赋值给空
      for (var i = 0; i < shareArr.length; i++) {
        var rond = Math.round(Math.random());
        str += shareArr[rond];
      }
      this.viewa = str;
    },
    getTabList(val) {
      let params = {
        searchType: this.activeIndex,
        key: this.key || "",
        areaId: this.areaId,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        userId: localStorage.getItem('userId'),
        type:this.activeIndex,
        collectFlag:isNotEmpty(this.$route.query.collectFlag)?this.$route.query.collectFlag:""
      };

      const _this = this
      SearchAll({...params}).then(res => {
        _this.total = res.data.data.total;
        _this.searchList = res.data.data.list;
        _this.pageNum =  Math.ceil(_this.total/_this.pageSize)
        _this.searchList.forEach((it) => {
          it.viewa = _this.shareArr[Math.floor(Math.random() * _this.shareArr.length)];
          it.favorite = {
            favorite_id:it.id,
            type:it.type,
            userid: localStorage.getItem('userId'),
            favoriteFlg:it.favoriteFlg
          }
        });

        if(res.data.data.list.length === 20) this.showMoreFlg = true;
        else this.showMoreFlg = false;
        // console.log( this.searchList)
      });
    },
    onSearch(e) {
      this.key = e;
      this.getTabList();
      this.showChange('1');
    },
    onNav(name, title) {
      this.currentPage = 1;
      this.pageNum = 1;
      this.total = 0;
      if (title == this.$t("app.All")) {
        this.activeIndex = 0;
        this.goResult(name, this.activeIndex, this.collectFlag)
      } else if (title == this.$t("app.introduction")) {
        this.activeIndex = 4;
        this.goResult(name, this.activeIndex, this.collectFlag)
      } else if (title == this.$t("app.area")) {
        this.activeIndex = 1;
        this.goResult(name, this.activeIndex, this.collectFlag)
      } else if (title == this.$t("app.scenicSpot")) {
        this.activeIndex = 2;
        this.goResult(name, this.activeIndex, this.collectFlag)
      } else if (title == this.$t("app.shop")) {
        this.activeIndex = 5;
        this.goResult(name, this.activeIndex, this.collectFlag)
      } else {
        this.activeIndex = 6;
        this.goResult(name, this.activeIndex, this.collectFlag)
      }
    },
    searchResult(item) {
      // console.log("searchResult")
      // console.log(item)
      this.$router.push({
        path: "/orderXq",
        query: {
          type: item.type,
          id: item.id,
        },
      });
    },
    goShop(item) {
      // console.log("goShop")
      // console.log(item)
      this.$router.push({
        path: "/detailsPage",
        query: {
          type: item.type,
          id: item.id,
        },
      });
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
    },
    toRegister() {
      this.$router.push("/register");
    },
    loadMore() {
      let _this = this;
      this.currentPage = this.currentPage + 1;
      let params = {
        searchType: this.activeIndex,
        key: this.key || "",
        areaId: this.areaId,
        pageSize: Number(this.pageSize) * Number(this.currentPage),
        pageNum: 1,
        userId: localStorage.getItem('userId'),
        type:this.activeIndex,
        collectFlag:isNotEmpty(this.$route.query.collectFlag)?this.$route.query.collectFlag:""
      };

      let shareArr = ["1000+","2000+","3000+","4000+","5000+","6000+","7000+","8000+","9000+"];

      SearchAll({ ...params }).then(res => {
        this.total = res.data.data.total;

        this.searchList = [...res.data.data.list];
        _this.searchList.forEach(item=>{
          item.favorite = {
            favorite_id:item.id,
            type:item.type,
            userid: localStorage.getItem('userId'),
            favoriteFlg:item.favoriteFlg
          }
        })

        if(this.searchList.length === this.total) this.showMoreFlg = false;
        else this.showMoreFlg = true;

        this.searchList.forEach((it) => {
          it.viewa = shareArr[Math.floor(Math.random() * shareArr.length)];
        });
      });
    },
    pagechange(page) {
      this.currentPage = page;
      let params = {
        searchType: this.activeIndex,
        key: this.key || "",
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        userId: localStorage.getItem('userId'),
        type:this.activeIndex,
        collectFlag:isNotEmpty(this.$route.query.collectFlag)?this.$route.query.collectFlag:""
      };
      const _this = this
      SearchAll({...params}).then(res => {
        _this.total = res.data.data.total;
        _this.searchList = res.data.data.list;
        _this.pageNum =  Math.ceil(_this.total/_this.pageSize)
        _this.searchList.forEach((it) => {
          it.viewa = _this.shareArr[Math.floor(Math.random() * _this.shareArr.length)];
          it.favorite = {
            favorite_id:it.id,
            type:it.type,
            userid: localStorage.getItem('userId'),
            favoriteFlg:it.favoriteFlg
          }
        });
        // console.log( this.searchList)
      });
    },
    goSearch() {
      this.$router.push("/search");
    },
    essay(item) {
      // console.log("essay")
      // console.log(item)
      this.$router.push({
        path: "/detailsPage",
        query: {
          type: item.type,
          id: item.id,
        },
      });
    },
    // 从全部那里跳到详情页面
    goDetails(item) {
      if (item.type <= 5) {
        this.$router.push({
          path: "/detailsPage",
          query: {
            type: item.type,
            id: item.id,
          },
        });
      } else {
        this.$router.push({
          path: "/orderXq",
          query: {
            type: item.type,
            id: item.id,
          },
        });
      }
    },
    getMerchantMarkList() {
      getMerchantMarkList((response) => {
        if (response.code == 200) {
          let marchantList4Map = [];
          for (const merchant of response.data) {
            let merchant4Map = {
              merchantId: merchant.merchantId,
              addressLat: parseFloat(merchant.addressLat),
              addressLng: parseFloat(merchant.addressLng),
              address: merchant.address,
              markerOptions: {
                icon: {
                  url: merchant.icon,
                  scaledSize: {width: 30, height: 30}
                }
              },
              infoWindowOptions: {
                maxWidth: 240,
                // pixelOffset: { width: 0, height: -10 },
                openFlg: false,
                banner: merchant.banner,
                merchantName: merchant.merchantName,
                weekList: merchant.weekList,
                phone: merchant.phone
              }
            };
            marchantList4Map.push(merchant4Map);
          }
          this.merchantList = marchantList4Map;
        }
      });
    },
    openInfoWindow(index) {
      this.merchantList[index].infoWindowOptions.openFlg = !this.merchantList[index].infoWindowOptions.openFlg;
    },
    closeInfoWindow(index) {
      this.merchantList[index].infoWindowOptions.openFlg = false;
    },
    goMerchantDetail(index) {
      const item = {
        type: 5,
        id: this.merchantList[index].merchantId,
      }
      this.goShop(item);
    },
    getColumnDetail() {
      let params = {
        type: this.activeIndex
      }
      getColumnDetail({ ...params }, (data) => {
        if (this.activeIndex === 4) {
          if(this.languageType === 'cn') {
            if(data.data[0][0][0].id !== null) {
              this.iCShowFlg = true;
            }
            this.carouselList = data.data[0][0];
            if(data.data[0][1][0].id !== null) {
              this.iVLShowFlg = true;
            }
            this.popIntroductionList = data.data[0][1];
            if(data.data[0][2][0].id !== null) {
              this.iCCShowFlg = true;
            }
            this.guessLikeList = data.data[0][2];
            if(data.data[0][3][0].id !== null) {
              this.iHLShowFlg = true;
            }
            this.plansGoList = data.data[0][3];
          } else if (this.languageType === 'jp') {
            if(data.data[1][0][0].id !== null) {
              this.iCShowFlg = true;
            }
            this.carouselList = data.data[1][0];
            if(data.data[1][1][0].id !== null) {
              this.iVLShowFlg = true;
            }
            this.popIntroductionList = data.data[1][1];
            if(data.data[1][2][0].id !== null) {
              this.iCCShowFlg = true;
            }
            this.guessLikeList = data.data[1][2];
            if(data.data[1][3][0].id !== null) {
              this.iHLShowFlg = true;
            }
            this.plansGoList = data.data[1][3];
          } else if (this.languageType === 'en') {
            if(data.data[2][0][0].id !== null) {
              this.iCShowFlg = true;
            }
            this.carouselList = data.data[2][0];
            if(data.data[2][1][0].id !== null) {
              this.iVLShowFlg = true;
            }
            this.popIntroductionList = data.data[2][1];
            if(data.data[2][2][0].id !== null) {
              this.iCCShowFlg = true;
            }
            this.guessLikeList = data.data[2][2];
            if(data.data[2][3][0].id !== null) {
              this.iHLShowFlg = true;
            }
            this.plansGoList = data.data[2][3];
          } else if (this.languageType === 'hk') {
            if(data.data[3][0][0].id !== null) {
              this.iCShowFlg = true;
            }
            this.carouselList = data.data[3][0];
            if(data.data[3][1][0].id !== null) {
              this.iVLShowFlg = true;
            }
            this.popIntroductionList = data.data[3][1];
            if(data.data[3][2][0].id !== null) {
              this.iCCShowFlg = true;
            }
            this.guessLikeList = data.data[3][2];
            if(data.data[3][3][0].id !== null) {
              this.iHLShowFlg = true;
            }
            this.plansGoList = data.data[3][3];
          }
        } else if (this.activeIndex === 1) {
          if(this.languageType === 'cn') {
            if(data.data[0][0][0].id !== null) {
              this.aCShowFlg = true;
            }
            this.carouselList = data.data[0][0];
            if(data.data[0][1][0].id !== null) {
              this.aVLShowFlg = true;
            }
            this.popAreaList = data.data[0][1];
            if(data.data[0][2][0].id !== null) {
              this.aHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[0][2];
            if(data.data[0][3][0].id !== null) {
              this.aHL4ShowFlg = true;
            }
            this.plansGoList = data.data[0][3];
          } else if (this.languageType === 'jp') {
            if(data.data[1][0][0].id !== null) {
              this.aCShowFlg = true;
            }
            this.carouselList = data.data[1][0];
            if(data.data[1][1][0].id !== null) {
              this.aVLShowFlg = true;
            }
            this.popAreaList = data.data[1][1];
            if(data.data[1][2][0].id !== null) {
              this.aHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[1][2];
            if(data.data[1][3][0].id !== null) {
              this.aHL4ShowFlg = true;
            }
            this.plansGoList = data.data[1][3];
          } else if (this.languageType === 'en') {
            if(data.data[2][0][0].id !== null) {
              this.aCShowFlg = true;
            }
            this.carouselList = data.data[2][0];
            if(data.data[2][1][0].id !== null) {
              this.aVLShowFlg = true;
            }
            this.popAreaList = data.data[2][1];
            if(data.data[2][2][0].id !== null) {
              this.aHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[2][2];
            if(data.data[2][3][0].id !== null) {
              this.aHL4ShowFlg = true;
            }
            this.plansGoList = data.data[2][3];
          } else if (this.languageType === 'hk') {
            if(data.data[3][0][0].id !== null) {
              this.aCShowFlg = true;
            }
            this.carouselList = data.data[3][0];
            if(data.data[3][1][0].id !== null) {
              this.aVLShowFlg = true;
            }
            this.popAreaList = data.data[3][1];
            if(data.data[3][2][0].id !== null) {
              this.aHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[3][2];
            if(data.data[3][3][0].id !== null) {
              this.aHL4ShowFlg = true;
            }
            this.plansGoList = data.data[3][3];
          }
        } else if (this.activeIndex === 2) {
          if (this.languageType === 'cn') {
            if(data.data[0][0][0].id !== null) {
              this.cCShowFlg = true;
            }
            this.carouselList = data.data[0][0];
            if(data.data[0][1][0].id !== null) {
              this.cCCShowFlg = true;
            }
            this.popScenicSpotList = data.data[0][1];
            if(data.data[0][2][0].id !== null) {
              this.cHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[0][2];
            if(data.data[0][3][0].id !== null) {
              this.cHL4ShowFlg = true;
            }
            this.plansGoList = data.data[0][3];
          } else if (this.languageType === 'jp') {
            if(data.data[1][0][0].id !== null) {
              this.cCShowFlg = true;
            }
            this.carouselList = data.data[1][0];
            if(data.data[1][1][0].id !== null) {
              this.cCCShowFlg = true;
            }
            this.popScenicSpotList = data.data[1][1];
            if(data.data[1][2][0].id !== null) {
              this.cHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[1][2];
            if(data.data[1][3][0].id !== null) {
              this.cHL4ShowFlg = true;
            }
            this.plansGoList = data.data[1][3];
          } else if (this.languageType === 'en') {
            if(data.data[2][0][0].id !== null) {
              this.cCShowFlg = true;
            }
            this.carouselList = data.data[2][0];
            if(data.data[2][1][0].id !== null) {
              this.cCCShowFlg = true;
            }
            this.popScenicSpotList = data.data[2][1];
            if(data.data[2][2][0].id !== null) {
              this.cHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[2][2];
            if(data.data[2][3][0].id !== null) {
              this.cHL4ShowFlg = true;
            }
            this.plansGoList = data.data[2][3];
          } else if (this.languageType === 'hk') {
            if(data.data[3][0][0].id !== null) {
              this.cCShowFlg = true;
            }
            this.carouselList = data.data[3][0];
            if(data.data[3][1][0].id !== null) {
              this.cCCShowFlg = true;
            }
            this.popScenicSpotList = data.data[3][1];
            if(data.data[3][2][0].id !== null) {
              this.cHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[3][2];
            if(data.data[3][3][0].id !== null) {
              this.cHL4ShowFlg = true;
            }
            this.plansGoList = data.data[3][3];
          }
        } else if (this.activeIndex === 5) {
          if (this.languageType === 'cn') {
            if(data.data[0][0][0].id !== null) {
              this.sVLShowFlg = true;
            }
            this.popShopList = data.data[0][0];
            if(data.data[0][1][0].id !== null) {
              this.sHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[0][1];
            if(data.data[0][2][0].id !== null) {
              this.sHL2ShowFlg = true;
            }
            this.plansGoList = data.data[0][2];
          } else if (this.languageType === 'jp') {
            if(data.data[1][0][0].id !== null) {
              this.sVLShowFlg = true;
            }
            this.popShopList = data.data[1][0];
            if(data.data[1][1][0].id !== null) {
              this.sHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[1][1];
            if(data.data[1][2][0].id !== null) {
              this.sHL2ShowFlg = true;
            }
            this.plansGoList = data.data[1][2];
          } else if (this.languageType === 'en') {
            if(data.data[2][0][0].id !== null) {
              this.sVLShowFlg = true;
            }
            this.popShopList = data.data[2][0];
            if(data.data[2][1][0].id !== null) {
              this.sHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[2][1];
            if(data.data[2][2][0].id !== null) {
              this.sHL2ShowFlg = true;
            }
            this.plansGoList = data.data[2][2];
          } else if (this.languageType === 'hk') {
            if(data.data[3][0][0].id !== null) {
              this.sVLShowFlg = true;
            }
            this.popShopList = data.data[3][0];
            if(data.data[3][1][0].id !== null) {
              this.sHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[3][1];
            if(data.data[3][2][0].id !== null) {
              this.sHL2ShowFlg = true;
            }
            this.plansGoList = data.data[3][2];
          }
        } else if (this.activeIndex === 6) {
          if (this.languageType === 'cn') {
            if(data.data[0][0][0].id !== null) {
              this.tCShowFlg = true;
            }
            this.carouselList = data.data[0][0];
            if(data.data[0][1][0].id !== null) {
              this.tCCShowFlg = true;
            }
            this.popTicketList = data.data[0][1];
            if(data.data[0][2][0].id !== null) {
              this.tHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[0][2];
            if(data.data[0][3][0].id !== null) {
              this.tHL2ShowFlg = true;
            }
            this.plansGoList = data.data[0][3];
          } else if (this.languageType === 'jp') {
            if(data.data[1][0][0].id !== null) {
              this.tCShowFlg = true;
            }
            this.carouselList = data.data[1][0];
            if(data.data[1][1][0].id !== null) {
              this.tCCShowFlg = true;
            }
            this.popTicketList = data.data[1][1];
            if(data.data[1][2][0].id !== null) {
              this.tHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[1][2];
            if(data.data[1][3][0].id !== null) {
              this.tHL2ShowFlg = true;
            }
            this.plansGoList = data.data[1][3];
          } else if (this.languageType === 'en') {
            if(data.data[2][0][0].id !== null) {
              this.tCShowFlg = true;
            }
            this.carouselList = data.data[2][0];
            if(data.data[2][1][0].id !== null) {
              this.tCCShowFlg = true;
            }
            this.popTicketList = data.data[2][1];
            if(data.data[2][2][0].id !== null) {
              this.tHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[2][2];
            if(data.data[2][3][0].id !== null) {
              this.tHL2ShowFlg = true;
            }
            this.plansGoList = data.data[2][3];
          } else if (this.languageType === 'hk') {
            if(data.data[3][0][0].id !== null) {
              this.tCShowFlg = true;
            }
            this.carouselList = data.data[3][0];
            if(data.data[3][1][0].id !== null) {
              this.tCCShowFlg = true;
            }
            this.popTicketList = data.data[3][1];
            if(data.data[3][2][0].id !== null) {
              this.tHL3ShowFlg = true;
            }
            this.guessLikeList = data.data[3][2];
            if(data.data[3][3][0].id !== null) {
              this.tHL2ShowFlg = true;
            }
            this.plansGoList = data.data[3][3];
          }
        }
      });
    },
    showChange(val) {
      if (val === '1') {
        this.displayShowFlg = false;
        this.iCShowFlg = false;
        this.iVLShowFlg = false;
        this.iCCShowFlg = false;
        this.iHLShowFlg = false;
        this.aCShowFlg = false;
        this.aVLShowFlg = false;
        this.aHL3ShowFlg = false;
        this.aHL4ShowFlg = false;
        this.cCShowFlg = false;
        this.cCCShowFlg = false;
        this.cHL3ShowFlg = false;
        this.cHL4ShowFlg = false;
        this.sVLShowFlg = false;
        this.sHL3ShowFlg = false;
        this.sHL2ShowFlg = false;
        this.tCShowFlg = false;
        this.tCCShowFlg = false;
        this.tHL3ShowFlg = false;
        this.tHL2ShowFlg = false;
      }
    },
    goResult(name, index, collectFlag) {
      if (collectFlag == 1) {
        this.$router.push({
          path: '/result',
          query: {
            name: name,
            index: index,
            collectFlag: collectFlag
          }
        })
      } else {
        this.$router.push({
          path: '/result',
          query: {
            name: name,
            index: index
          }
        })
      }
    }
  },
};
</script>

<style scoped lang="less">
@import url("../../assets/css/searchResult.less");
/deep/.gm-ui-hover-effect {
  display: none !important;
}
/deep/ .gm-style .gm-style-iw-c {
  padding: 0px !important;
}
/deep/ .gm-style .gm-style-iw-d {
  overflow: hidden !important;
}
.map-merchant-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: bold;
  height: 18px;
  max-width: 218px;
  padding: 0px 6px;
}
.map-merchant-tel {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: bold;
  height: 18px;
  max-width: 218px;
  padding: 0px 6px;
}
.map-business-hours {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: bold;
  height: 18px;
  max-width: 218px;
  padding: 0px 6px;
}
.backgroundColor {
  padding: 0.1rem 0;
  background: rgb(255, 255, 255);
}
.viewa-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-left {
  text-align: left;
}
</style>
