<template>
  <div>
    <Header @goBack="goBack"></Header>
    <Loginlogo></Loginlogo>
    <div class="login">
      <h1 class="login_title">{{
        $t('app.Welcome_back')
      }}</h1>
      <div class="login_input">
        <div class="line" v-if="!isWechat">{{ $t('app.line_login') }}</div>
        <div class="line_hover" @click="loginLine()" v-if="!isWechat"></div>
        <div class="line" style="margin-top:.35rem;" v-if="isHK">{{ $t('app.wechat_login') }}</div>
        <div class="line_hover" @click="loginWechat()" v-if="isHK"></div>
        <van-icon name="envelop-o" />
        <div id="email_login" class="email" @click="activeName === '1' ? activeName = null : activeName = '1'">
          {{ $t('app.email_login') }}
        </div>
        <van-collapse v-model="activeName" accordion>
          <van-collapse-item name="1">
            <van-form @submit="onSubmit">
              <p class="label">{{
                $t('app.Email_address')
              }}</p>
              <van-cell-group inset style="margin-bottom: 2%">
                <van-field id="ipt_email" :rules="[{ required: true, message: $t('app.Email_required'), trigger: 'onBlur' }, {
                  validator: (value) => {
                    return /.+@.+\..+/.test(value);
                  }, message: $t('app.Email_required_must'),
                  trigger: 'onBlur',
                }]" name="value" v-model="inputvalue" placeholder="" />
              </van-cell-group>
              <p class="label">{{
                $t('app.Password')
              }}</p>
              <van-cell-group inset style="margin-bottom: 2%">
                <van-field id="ipt_pwd" type="password" :rules="[{ required: true, message: $t('app.enter_pwd') }]" name="value"
                  v-model="password" placeholder="" focus />
              </van-cell-group>
              <span class="forgot_password" @click="forget">{{
                $t('app.Forgot_password')
              }}</span>
              <div style="margin-top: 0.5rem;">
                <van-button id="submin_login" round block type="info" native-type="submit">{{
                  $t('app.Sign_in')
                }}
                </van-button>
              </div>
            </van-form>
          </van-collapse-item>
        </van-collapse>
      </div>
      <!-- <div class="thrid_login_div">
        <div @click="loginLine()" >
          <img class="item" src="../../assets/LINE_Brand_icon.png"/>
        </div>
      </div> -->
      <divider :type="0"></divider>
      <div class="connect">
        <p class="medin_ban" @click="toJoin">{{ $t('app.medin_bank') }}</p>
        <p class="cookie_state">{{ $t('app.cookie_state') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import apis, {hostUrlHK,hostUrlGrab} from '../../utils/apis'
import Vue from 'vue';
import { NavBar, Icon, Form, Field, Button, Divider, Toast } from 'vant';
import Header from '../../components/Login/Header.vue'
import divider from '../../components/Login/divider.vue';
import Loginlogo from '../../components/Login/Loginlogo.vue';

Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Toast);
let { login } = apis
let { getLineUrl } = apis
export default {
  components: {
    Header,
    divider,
    Loginlogo
  },
  data() {
    return {
      callPage: '',
      activeName: null,
      callbackUrl: '',
      value1: localStorage.getItem('language') == null ? 'en' : localStorage.getItem('language'),
      option1: [
        { text: this.$t('app.en'), value: 'en' },
        { text: this.$t('app.jp'), value: 'jp' },
        { text: this.$t('app.cn'), value: 'cn' },
        { text: this.$t('app.hk'), value: 'hk' },
      ],
      inputvalue: '',
      password: '',
      telRules: [
        {
          required: true,
          message: "E-mail is required",
          trigger: "onBlur",
        },
        {
          // 自定义校验规则
          validator: (value) => {
            return /.+@.+\..+/.test(value);
          },
          message: "E-mail must be valid",
          trigger: "onBlur",
        },
      ],
      isHK: false,
      isWechat: false,
    };
  },
  created() {
    if(this.$route.query.path){
      this.callPage = this.$route.query.path.substring(1);
      this.callbackUrl = this.$route.query.path.substring(1);
    }else{
      this.callPage = 'homePage';
      this.callbackUrl = 'homePage';
    }
    this.callbackUrl = this.callbackUrl.replace('&','||')
    this.urlJudge();
  },
  methods: {
    loginWechat() {
      let APPID = 'wx8bd58fcc3277edea';
      let REDIRECT_URI = 'https://hkwechat.media-bank.info/homePage';
      REDIRECT_URI = encodeURIComponent(REDIRECT_URI);
      let SCOPE = 'snsapi_userinfo';
      let STATE = '';

      let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + APPID +
        '&redirect_uri=' + REDIRECT_URI + 
        '&response_type=code&scope=' + SCOPE + 
        '&state=' + STATE + '#wechat_redirect'

      window.location.href = url;
    },
    loginLine(){
      let domainFlag = "0";
      const path = window.location.href;
      if (path.indexOf('hk') > -1) {
        domainFlag = "1";
      }
      if (path.indexOf('grab') > -1) {
        domainFlag = "2";
      }

      let param = {
        endPath: this.callbackUrl,
        domainFlag: domainFlag
      }
      getLineUrl(param,data=>{
        window.location.href = data.data
      })
    },
    urlJudge() {
      const path = window.location.href;
      if (path.indexOf(hostUrlHK) > -1) {
        this.isHK = true;
      }
      const browser = window.navigator.userAgent.toLowerCase()
      if (browser.indexOf('micromessenger') > -1) {
        this.isWechat = true;
      }
    },
    toJoin() {
      this.$router.replace('/register')
    },
    onSubmit(values) {
      let params = {
        pwd: this.password || localStorage.getItem('email'),
        mail: this.inputvalue
      }
      login({ ...params }, data => {
        if (data.code == 200) {
          Toast.success(data.msg);
          // this.$router.replace({
          //   path: this.callPage,
          // });

          this.$router.back(-1);

        }
      })
    },
    forget() {
      this.$router.push('/forget')
    },
    goBack() {
      this.$router.back()
    }
  },
};
</script>
<style scoped lang="less">
.thrid_login_div{
  margin-top: 0.27rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  .item{
    width: 1.17rem;
    height: 1.17rem;
    padding: 0.27rem;
  }
}
.login {
  padding: 0 .5rem;
  color: #606266;
  .login_title {
    text-align: left;
    margin: 0 0 .4rem 0;
  }
  .line_hover {
    height: .8rem;
    background: #000000;
    opacity: 8%;
    margin-top: -.8rem;
    border-radius: .5rem;
  }
  .line {
    height: .8rem;
    color: #FFFFFF;
    background: #06C755;
    line-height: .8rem;
    border-radius: .5rem;
  }
  .line_hover:hover {
    opacity: 30%;
  }
  .email {
    height: .8rem;
    border: 2px solid #000000;
    line-height: .8rem;
    border-radius: .5rem;
    // margin-top: .2rem;
    color: #000000;
  }
  .van-icon {
    top: .66rem;
    left: -2.7rem;
    font-size: .4rem;
    font-weight: 600;
    color: #000000;
  }
}
/deep/ .van-cell-group--inset {
  margin: 0;
}
/deep/ .van-cell {
  padding: 0;
}
/deep/.van-field__body {
  height: .8rem;
  border: .04rem solid #E0E0E0;
  border-radius: .16rem;
  padding-left: .24rem;
}
.label {
  display: block;
  margin: 0.33rem 0 0.25rem 0;
  text-align: left;
  font-size: 0.30rem;
  font-weight: 540;
}
.forgot_password {
  display: block;
  margin: 0.33rem 0 0.25rem 0;
  text-align: left;
  font-size: 0.30rem;
  font-weight: 540;
  width: 70%;
}
.van-button {
  background-color: #333;
  border: none;
}
.connect {
  .medin_ban {
    margin: 0;
    text-decoration:underline;
    font-size: .32rem;
    font-weight: bold;
  }
  .cookie_state {
    font-size: 0.28rem;
    font-weight: bold;
  }
}
/deep/ .van-cell--clickable {
  display: none;
}
/deep/ .van-collapse-item__content {
  padding: 12px 0;
}
</style>
