<template>
  <div>
    <nav-bar :ifBlack="true"></nav-bar>
    <div class="de_row title">
      <van-icon name="arrow-left" @click="goBack" />
      <p style="display: inline">{{ nocDetail.title }}</p>
    </div>
    <div class="de_row">
      <p class="noc_time">{{ $t('app.sendTime') + nocDetail.sendTime }}</p>
    </div>
    <div class="de_row">
      <p class="noc_content">{{ nocDetail.content }}</p>
    </div>
  </div>
</template>
<script>
import NavBar from "../../components/basicComponents/navBar.vue";

export default {
  components: { NavBar },
  data() {
    return {
      nocDetail: {},
    }
  },
  created() {
    this.nocDetail = this.$route.query;
    //console.log(this.nocDetail);
  },
  methods: {
    goBack() {
        this.$router.go(-1);
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  margin-top: 1.3rem;
  font-size: .37rem;
  font-weight: bold;
  text-align: center;
}
.de_row {
    text-align: left;
    padding: 0 .4rem;
    .noc_content {
        font-size: .26rem;
    }
    .noc_time {
        font-size: .2rem;
        color: #666;
    }
}
</style>
