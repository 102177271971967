<template>
  <div class="districtDiv">
    <div class="flow-row space-between">
      <div class="distTitle1">{{ dataList[0].columnName }}</div>
    </div>
    <div class="swiper" id="swiper1" v-if="!ticketFlg">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in dataList" :key="index">
          <img :src="item.columnImageUrl" class="swiperImg" @click="toDetail(tab, item)"/>
        </div>
      </div>
    </div>
    <div class="swiper" id="swiper2" v-if="ticketFlg">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in dataList" :key="index">
          <img :src="item.columnImageUrl" class="swiperImg" @click="toDetail(tab, item)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min';

import Swiper from 'swiper';

export default {
  props: {
    tab: {
      type: Number,
      default: 0
    },
    dataList: {
      type: Array,
    },
    ticketFlg: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Swiper
  },
  data() {
    return {
      nums: 3,
    }
  },
  mounted() {
    if (!this.ticketFlg) {
      new Swiper("#swiper1", {
        width: window.innerWidth,
        direction: "horizontal",
        speed: 600,
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
        observer: true,
        observeParents: true,
        effect: "coverflow",
        slidesPerView: "auto",
        centeredSlides: true,
        coverflowEffect: {
          rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: -5, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 20, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 5,
          slideShadows: false, //开启slide阴影。默认 true。
        },
      });
    } else {
      new Swiper("#swiper2", {
        width: window.innerWidth,
        direction: "horizontal",
        speed: 600,
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
        observer: true,
        observeParents: true,
        effect: "coverflow",
        slidesPerView: "auto",
        centeredSlides: true,
        coverflowEffect: {
          rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: -5, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 20, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 5,
          slideShadows: false, //开启slide阴影。默认 true。
        },
      });
    }
  },
  methods: {
    toDetail(tab, item) {
      if (tab <= 5) {
        this.$router.push({
          path: "/detailsPage",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      } else {
        this.$router.push({
          path: "/orderXq",
          query: {
            type: item.pageType,
            id: item.columnRefId,
          },
        });
      }
    },
  }
}
</script>

<style scoped lang="less">
.districtDiv {
  margin: 0 auto;
  text-align: start;

  .space-between {
    line-height: 1.2rem;
  }

  .distTitle1 {
    color: black;
    font-size: 0.37rem;
    font-weight: 600;
    margin-left: 0.3rem;
  }

  .distDiv {
    width: 100%;
      height: 240px;
  }

  .swiper {
    overflow: hidden;

    .swiper-slide {
      width: 200px !important;
      height: 120px;
      font-size: 14px;
      text-align: center;
      line-height: 80px;
      border-radius: 8px;
      position: relative;
    }

    .swiperImg {
      width: 200px !important;
      height: 120px;
      border-radius: 8px;
      object-fit: cover;
    }
  }
}

.swiper::before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 0.3rem;
  z-index: 100;
  background-color: #ffffff;
}

.swiper::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 0.3rem;
  z-index: 100;
  background-color: #ffffff;
}
</style>
