<template>
  <div style="position: relative;">
     <van-icon class="back" @click="goBack" name="arrow-left" size=".50rem" />
    <div style="padding-left: 15px;">
      <div class="info">
        <van-row style="height: 100%; width: 100%">
          <van-col :span="8" style="height: 100%" class="colCenter">
            <img v-if="avatar && avatar != 'null' && avatar != ''" :src="avatar" class="avatar">
            <img v-else src="../../assets/user_ic.png" class="avatar">
          </van-col>
          <van-col :span="12" style="height: 100%"  class="colCenter">
            <div class="info-center">
              <div style="text-align: left;">{{ nikeName }}</div>
              <div class="email">{{ email ? email : '' }}</div>
            </div>
          </van-col>
          <van-col :span="1" style="height: 100%" class="colCenter">
            <div class="vertical-line"></div>
          </van-col>
          <van-col :span="3" style="height: 100%" class="colCenter">
            <div class="icon-box">
              <van-icon name="edit" size=".50rem" @click="goUpdProfile"/>
            </div>
          </van-col>
        </van-row>
        
       
      </div>
    </div>
    <!-- <Header @goBack="goBack"></Header> -->
    <div class="personal">
      <div class="header-bg"></div>
      <div class="empty"></div>
      <div class="title">
        <div class="text">{{$t('app.msgList')}}</div>
        <div class="more" v-if="messageList.length > 0" @click="goMessage">{{$t('app.More')}}</div>
      </div>
      <div style="padding: 15px 0;" v-if="messageList.length > 0">
        <div class="mes-item" v-for="(item,index) in messageList" :key="index">
          <van-icon name="comment" size=".50rem" />
          <div class="mess-right">
            <div class="mess-info">{{item.title}}</div>
            <div class="time-box">
              <p>{{item.sendTime}}</p>
              <!-- <p style="margin-left:25px">{{item.time}}</p> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <van-empty :description="$t('app.No_data')" />
      </div>
      <div class="title">
        <div class="text">{{$t('app.orderList')}}</div>
        <div class="more" v-if="list.length > 0" @click="goOrder">{{$t('app.More')}}</div>
      </div>
      <div v-if="list.length > 0" class="order">
        <van-cell  @click="OrderDetails(item.orderId)" v-for="(item,index) in list" :key="index">
          <div class="order_nav">
            <div class="order_img">
              <img :src="item?.img ? item.img : NoPicture" alt="" srcset="">
            </div>
            <div class="order_cbk">
              <div class="order_title">{{ item.proName }}</div>
              <div class="order_status">
                <!-- <span><van-icon name="certificate" size="16"/></span><span>{{ $t('app.Success') }}</span> -->
                <span v-if="item.orderStatus == 1"><van-icon name="certificate" size="16" /></span>
                <span v-if="item.orderStatus == 1">{{$t("app.Success")}}</span>
                <span v-if="item.orderStatus == 2"><van-icon name="close" size="16" /></span>
                <span v-if="item.orderStatus == 2">{{$t("app.Failure")}}</span>
                <span v-if="item.orderStatus == 3"><van-icon name="replay" /> </span>
                <span v-if="item.orderStatus == 3">{{$t("app.Processing")}}</span>
              </div>
              <div>{{ item.orderId }}</div>
              <div class="order_date">
                <span><van-icon name="underway-o" size="16"/></span>
                <span>{{ item.createTime }}</span>
                <span>|</span>
                <span>{{item.buyQuantity}}{{getTicket(item.buyQuantity)}}</span>
              </div>
              <div v-if="Number(item.amount) == 0" class="order_price">{{ $t('app.noInventory') }}</div>
              <div v-else class="order_price">{{ $t("app.money") }}{{ priceFormat(item.amount) }}</div>
            </div>
          </div>
        </van-cell>
      </div>
      
      <div v-else>
        <van-empty :description="$t('app.No_data')" />
      </div>
      <div class="title">
        <div class="text">{{$t('app.collectionList')}}</div>
        <div class="more" v-if="searchList.length > 0" @click="collectionList('a', '0')">{{$t('app.More')}}</div>
      </div>
      <display-list v-if="searchList.length > 0" :collectFlag="collectFlag" :dataList="searchList" @getTabList="getTabList"></display-list>
      <div v-else>
        <van-empty :description="$t('app.No_data')" />
      </div>
      <!-- <Footer></Footer> -->
    </div>
  </div>
</template>

<script>
import apis from "../../utils/apis";
import Vue from "vue";
import { NavBar, Icon, Form, Field, Button, Toast,Uploader } from "vant";
import Header from "../../components/Login/Header.vue";
import Footer from "../../components/Login/Footer.vue";
import user_ic_def from "../../assets/user_ic.png";
import displayList from "../search/components/displayList";
import {isNotEmpty} from "@/utils/priceFormat";

Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Uploader);
let {OrderList,SearchAll} = apis
export default {
  props: ['preview'],
  components: {
    Header,
    Footer,
    displayList
  },
  data() {
    return {
      shareArr:[
        "1000+",
        "2000+",
        "3000+",
        "4000+",
        "5000+",
        "6000+",
        "7000+",
        "8000+",
        "9000+",
      ],
      key: "",
      collectFlag:'1',
      areaId: '',
      avatar:'',
      email: '',
      nikeName: "",
      queryParams: {
        pageNum: 1,
        pageSize: 5,
        readStatus: 3,
        languageType: null,
        userId: null,
      },
      list: [],
      messageList:[],
      searchList: [],
          };
  },
  watch: {
    // preview: {
    //   handler(val) {
    //     if(val) {
    //       this.$nextTick(() => {
    //         this.$refs.vForm.resetValidation();
    //       })
    //     }
    //   },
    //   immediate: true
    // }
  },
  created() {
    this.activeIndex = isNotEmpty(this.$route.query.index)?Number(this.$route.query.index):"";
    this.key = this.$route.query.searchKey;
    this.nikeName = localStorage.getItem("name");
    this.email = localStorage.getItem("email");
    this.avatar = localStorage.getItem("avatar");
    if (!this.avatar || this.avatar == "undefined") {
      this.avatar = user_ic_def;
    }
    this.getList()
    this.getorderListShort()
    this.getTabList();
  },
  mounted() {
    this.randomNumber();
  },
  methods: {
    getTicket(num){
      if (!isNaN(num)) {
        return Number(num) > 1 ?  this.$t('app.tickets_s') : this.$t('app.tickets')
      } else {
        return this.$t('app.tickets');
      }
    },
    goBack() {
      this.$router.back();
    },
    OrderDetails(index) {
      if(index === '' || index === null ) {
        Toast.fail(this.$t('app.orderExcption'))
        return
      }
      this.$router.push({
        path: '/myOrderDetails',
        query: {
          orderId: index
        }
      })
    },
    getList() {
      let types = {
      "en": 2,
      "cn": 0,
      "jp": 1,
      "hk": 3
      }
      this.queryParams.languageType = localStorage.getItem("language") == null ? 2 : types[localStorage.getItem("language")];
      this.queryParams.userId = localStorage.getItem("userId");
    //   this.queryParams.userId = '1643875911356440576';
      apis.getMessagetList(this.queryParams, (data) => {
        this.messageList = data.data.list;
        // this.total = data.data.total;
        // this.pageCount =  Math.ceil(this.total/this.queryParams.pageSize)
      });
    },
    getorderListShort() {
      let params = {
        "mail": localStorage.getItem('email'), //注册用的邮箱，接收系统发送的验证码。
        "searchType": 0, //搜索类型：0-全部订单；2-失败订单；1-成功订单；3-处理中订单
        "pageNum": 1,
        "pageSize": 3,
      }
      OrderList({...params}, data => {
        // this.total = data.data.total;
        // this.pageNum = Math.ceil(this.total / this.pageSize);
        this.list = data.data.list;
      })
    },
    randomNumber() {
      let shareArr = [
        "1000+",
        "2000+",
        "3000+",
        "4000+",
        "5000+",
        "6000+",
        "7000+",
        "8000+",
        "9000+",
      ];
      var str = ""; //重新调用赋值给空
      for (var i = 0; i < shareArr.length; i++) {
        var rond = Math.round(Math.random());
        str += shareArr[rond];
      }
      this.viewa = str;
    },
    getTabList(val) {
      let params = {
        searchType: this.activeIndex,
        key: this.key || "",
        areaId: this.areaId,
        pageNum: 1,
        pageSize: 3,
        userId: localStorage.getItem('userId'),
        type:this.activeIndex,
        collectFlag: this.collectFlag
      };

      const _this = this
      SearchAll({...params}).then(res => {
        // _this.total = res.data.data.total;
        _this.searchList = res.data.data.list;
        // _this.pageNum =  Math.ceil(_this.total/_this.pageSize)
        _this.searchList.forEach((it) => {
          it.viewa = _this.shareArr[Math.floor(Math.random() * _this.shareArr.length)];
          it.favorite = {
            favorite_id:it.id,
            type:it.type,
            userid: localStorage.getItem('userId'),
            favoriteFlg:it.favoriteFlg
          }
        });

        // if(res.data.data.list.length === 20) this.showMoreFlg = true;
        // else this.showMoreFlg = false;
        // console.log( this.searchList)
      });
    },
    goUpdProfile() {
      this.$router.push("/resetHead");
    },
    goMessage(){
      this.$router.push("/noticeList");
    },
    goOrder(){
      this.$router.push("/order");
    },
    collectionList(e, index) {
      this.$router.push({
        path: "/result",
        query: {
          name: e,
          index: index,
          collectFlag: '1'
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
// .login_input {
//     padding: .4rem .64rem;
// }
.back {
  color: #acacac;
  position: absolute;
  top: 35px;
  left: 15px;
}
.info {
  width: calc(100% - 30px);
  height: 110px;
  background: #f8fcfc;
  border-radius: 5px;
  position: absolute;
  top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.colCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-center {
  width: 100%;
}
.email {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  text-align: left;
}
.vertical-line {
  content: '';
  width: 1px;
  height: 64px;
  background: #c0c0c2;
  margin: 20px 0 0 0;
}
.icon-box {
  margin: 20px 15px 0 0;
}
.avatar {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 1px solid #e0e0e0;
}
.personal {
  // padding: 0 .5rem;
  .header-bg {
    background: #000000;
    width: 100%;
    height: 150px;
  }
  .empty {
    width: 100%;
    height: 50px;
    background: #f2f2f2;
  }
}

.title {
  width: 100%;
  height: 70px;
  line-height: 70px;
  background: #f2f2f2;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  .text {
    color: #000000;
    font-weight: 600;
  }
  .more {
    margin-right: 40px;
    color: #999999;
  }
}
.mes-item {
  width: 100%;
  height: 65px;
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
}
.mess-right {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mess-info {
  // font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.time-box {
  display: flex;
  color: #999999;
}

.order_nav {
  display: flex;
  height: 2.2rem;
  justify-content: space-between;

  .order_img {
    width: 2.2rem;
    height: 100%;

    img {
      border-radius: .12rem;
      object-fit: cover;
      width: 2.2rem;
      height: 100%;
    }
  }

  .order_cbk {
    flex: 1;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: .36rem;

    .order_title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: .36rem;
      width: 4rem;
    }

    .order_status {
      span {
        color: #595757;
        font-size: .28rem;
        padding-right: .1rem;
      }
    }

    .order_date {
      span {
        font-size: .32rem;
        padding-right: .1rem;
      }
    }

    .order_price {
      font-size: .3rem;
      // padding-left: .4rem;
    }
  }
}
/deep/.order .van-cell {
  height: 2.91rem;
  padding: .35rem .36rem;
  line-height: 20px;
}

/deep/ .van-cell-group--inset {
  margin: 0;
}


/deep/ .van-field__body {
  height: .8rem;
  border: .04rem solid #E0E0E0;
  border-radius: .1rem;
  padding-left: .24rem;
}

.label {
  text-align: left;
}
.van-button {
  background-color: #000000;
  border: none;
  height: 50px;
  font-size: 18px;
}
.connect p {
  margin: 0;
}
.required {
  text-align: left;
  color: red;
  font-weight: 200;
}
.avatar-uploader {
  width: 80px;
  height: 80px;
}
.avatar-uploader .avatar {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 1px solid #e0e0e0;
}
/deep/ .avatar-uploader .el-upload__input {
  display: none;
}
</style>
